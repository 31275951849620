body, html {
  background: #fff;
  margin: 0;
}

body {
  text-align: center;
  -webkit-font-smoothing: antialiased;
  color: #333;
  max-width: 1120px;
  margin: auto;
  padding: 0;
  font-family: Lato, sans-serif;
  font-size: 18px;
  position: relative;
}

a {
  color: #fff;
  cursor: pointer;
  font-size: 16px;
  font-weight: 800;
  text-decoration: none;
  transition: all .5s;
}

a:hover {
  opacity: 1;
}

h1 {
  width: 100%;
  float: left;
  letter-spacing: -4px;
  margin-top: 0;
  margin-bottom: 5px;
  font-size: 50px;
  font-weight: 300;
}

p {
  max-width: 610px;
  letter-spacing: 0;
  margin: 20px auto auto;
  font-size: 18px;
  font-weight: 500;
}

#frame {
  z-index: 11;
  position: fixed;
}

.frameBorder {
  z-index: 5;
  background-color: #fff;
  position: fixed;
}

.frameTop {
  width: 100%;
  height: 46px;
  max-width: 1120px;
  z-index: 12;
  padding-top: 10px;
  padding-bottom: 10px;
  transition: all 1s;
  top: 0;
  overflow: hidden;
}

.frameLeft {
  height: 100%;
  width: 15px;
  left: 0;
}

.frameRight {
  height: 100%;
  width: 15px;
  right: 0;
}

.frameBottom {
  width: 100%;
  height: 15px;
  bottom: 0;
}

#content {
  min-height: 100%;
}

.sectionPosition {
  margin-top: -40px;
  position: absolute;
}

#navmenureveal {
  height: 35px;
  width: 25px;
  opacity: 0;
  z-index: 13;
  margin-top: 10px;
  transition: all 1s;
  position: absolute;
  top: 5px;
}

#navmenujoshdoghead {
  height: 25px;
  width: 32px;
  opacity: 0;
  z-index: 13;
  background: url("https://d35408cyocioye.cloudfront.net/legacy/new2/josh_logo.png") 0 0 / 72px 25px no-repeat;
  margin-top: 10px;
  transition: all 1s;
  display: none;
  position: absolute;
  top: 10px;
}

.menuLine {
  height: 1px;
  width: 25px;
  background-color: #555;
  transition: all .5s;
  position: absolute;
}

.menuLine1 {
  margin-top: 8px;
}

.menuLine2 {
  margin-top: 17px;
}

.menuLine3 {
  margin-top: 26px;
}

.joshLogoTop {
  height: 25px;
  width: 72px;
  opacity: 0;
  background: url("https://d35408cyocioye.cloudfront.net/legacy/new2/josh_logo.png") 0 0 / 72px 25px no-repeat;
  margin: 10px auto auto;
  transition: all 1.5s;
}

#nav1 {
  width: 100%;
  transition: all 1.5s;
  display: none;
  position: relative;
  top: 0;
  left: 0;
}

#navlist1 {
  text-align: left;
  text-align: center;
  margin: 10px 0 10px 25px;
  padding: 0;
  transition: margin 1s;
}

#navlist1 li {
  color: #bbb;
  padding-right: 30px;
  list-style-type: none;
  display: inline;
}

.nav1home {
  opacity: 0;
  z-index: 13;
  list-style: none;
  transition: all 1s;
  position: absolute;
  top: 20px;
  right: 0;
}

.nav1home a {
  color: #555;
  font-size: 12px;
}

.nav1home:hover a {
  color: #000;
}

.navJoshLogo {
  position: absolute;
  left: 0;
}

.navJoshLogo a {
  opacity: 1;
  border: none;
}

.navJosh1 {
  height: 25px;
  width: 72px;
  background: url("https://d35408cyocioye.cloudfront.net/legacy/new2/nav-josh-logo-whole.png") no-repeat;
  margin-top: 1px;
  transition: background .5s;
  display: none;
  position: fixed;
}

.nav1text a {
  color: #bbb;
  opacity: 1;
  font-size: 12px;
  transition: all 1s;
}

.nav1text a:hover, .nav1textActive a {
  color: #000;
  cursor: pointer;
}

.nav1aboutDropdown {
  width: 100%;
  z-index: 11;
  background-color: #fff;
  display: none;
  position: fixed;
  top: 52px;
  left: 0;
}

.nav1aboutDropdownDivider {
  width: 400px;
  margin-top: 3px;
  margin-left: -200px;
  position: absolute;
}

#aboutList {
  color: #bbb;
  text-transform: uppercase;
  text-align: center;
  margin: 10px 0 10px 25px;
  padding: 0;
  font-size: 12px;
  font-weight: 600;
  transition: margin 1s;
}

#aboutList li, #aboutList li a {
  color: #bbb;
  text-transform: uppercase;
  padding-right: 15px;
  font-size: 12px;
  font-weight: 600;
  line-height: 1.7em;
  list-style-type: none;
  display: inline;
}

#aboutList li:hover, #aboutList li a:hover {
  cursor: pointer;
  color: #000;
}

.getJoshButton {
  height: 25px;
  width: 80px;
  background: url("https://d35408cyocioye.cloudfront.net/legacy/new2/nav-get-josh-button-outline.png") no-repeat;
  padding-top: 4px;
  transition: background 1.5s;
  display: none;
  position: absolute;
  top: 50px;
  right: 0;
}

.getJoshButton:hover {
  background: url("https://d35408cyocioye.cloudfront.net/legacy/new2/nav-get-josh-button-filled.png") no-repeat;
}

.getJoshButtonText {
  color: #008cff;
  transition: all .4s;
}

.getJoshButton:hover > .getJoshButtonText {
  color: #fff;
}

.nav1homeicon {
  width: 20px;
  height: 20px;
  background: url("https://d35408cyocioye.cloudfront.net/new2/home_icon.png") no-repeat;
  margin-top: -19px;
  margin-left: 36px;
  position: absolute;
}

.navhomeHover:hover > .nav1homeicon, .nav1home:hover > .nav1homeicon {
  background-position: 0 -24px;
}

#nav2 {
  margin: 0 112px;
  transition: all 1s;
  display: block;
  position: relative;
  top: 14px;
  left: 0;
}

#navlist2 {
  text-align: left;
  text-align: center;
  margin: 10px;
  padding: 0;
  transition: margin 1s;
}

#navlist2 li {
  padding-right: 40px;
  list-style-type: none;
  display: inline;
}

#navlist2 a {
  font-size: 18px;
}

.nav2icon1 {
  background: url("https://d35408cyocioye.cloudfront.net/legacy/new2/icons/icon1.png") no-repeat;
}

.nav2icon2 {
  background: url("https://d35408cyocioye.cloudfront.net/legacy/new2/icons/icon2.png") no-repeat;
}

.nav2icon3 {
  background: url("https://d35408cyocioye.cloudfront.net/legacy/new2/icons/icon5.png") no-repeat;
}

.nav2icon4 {
  background: url("https://d35408cyocioye.cloudfront.net/legacy/new2/icons/icon4.png") no-repeat;
}

.nav2icon5 {
  background: url("https://d35408cyocioye.cloudfront.net/legacy/new2/icons/icon3.png") no-repeat;
}

.nav2icon6 {
  background: url("https://d35408cyocioye.cloudfront.net/legacy/new2/icons/icon6.png") no-repeat;
}

.nav2icon7 {
  background: url("https://d35408cyocioye.cloudfront.net/legacy/new2/icons/icon7.png") no-repeat;
}

.nav2icon8 {
  background: url("https://d35408cyocioye.cloudfront.net/legacy/new2/icons/icon8.png") no-repeat;
}

.nav2icon9 {
  background: url("https://d35408cyocioye.cloudfront.net/legacy/new2/icons/icon9.png") no-repeat;
}

.nav2icon10 {
  background: url("https://d35408cyocioye.cloudfront.net/legacy/new2/icons/icon10.png") no-repeat;
}

.nav2icon11 {
  background: url("https://d35408cyocioye.cloudfront.net/legacy/new2/icons/icon11.png") no-repeat;
}

.nav2icon12 {
  background: url("https://d35408cyocioye.cloudfront.net/legacy/new2/icons/icon12.png") no-repeat;
}

.nav2icon {
  background-position: 0 0;
}

.nav2icon:hover, #navlist2 a.active > .nav2icon {
  background-position: 0 -22px;
}

#block1, #block2, #block3, #block4, #block5, #block6, #block7, #block8 {
  background-color: #fff;
  border-bottom: 15px solid #fff;
}

#block1 {
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background: url("https://d35408cyocioye.cloudfront.net/legacy/new2/landing-page-hero-image.jpg") center / cover no-repeat;
}

.tileContainer {
  width: 100%;
  height: 359px;
  transition: all 1s;
  overflow: hidden;
}

.tileImage {
  transition: all .5s;
}

.tileImage:hover {
  -webkit-filter: grayscale();
  filter: grayscale();
}

.tile {
  transition: all 1s;
  position: absolute;
}

.tile1 {
  opacity: 0;
  margin-left: -100px;
  top: 55px;
  left: 50%;
}

.tile2 {
  opacity: 0;
  margin-left: 5px;
  top: -49px;
  left: 50%;
}

.tile3 {
  opacity: 0;
  margin-left: -205px;
  top: -49px;
  left: 50%;
}

.tile4 {
  opacity: 0;
  margin-left: 109px;
  top: -153px;
  left: 50%;
}

.tile5 {
  opacity: 0;
  margin-left: -100px;
  top: -153px;
  left: 50%;
}

.tile6 {
  opacity: 0;
  margin-left: -311px;
  top: -153px;
  left: 50%;
}

.text1Container {
  height: 300px;
  width: 100%;
}

.section1text {
  text-align: center;
  opacity: 0;
  margin-top: 50px;
  font-size: 34px;
  font-weight: 300;
  transition: all 2s;
}

.section1textSmall {
  text-align: center;
  opacity: 0;
  margin-top: 10px;
  font-size: 14px;
  font-weight: 300;
  transition: all 2s;
}

.section1textSmall2 {
  text-align: center;
  opacity: 0;
  text-transform: uppercase;
  letter-spacing: .13em;
  margin-top: 85px;
  font-size: 12px;
  font-weight: 300;
  transition: all 2s;
}

.section1GetJoshButton {
  height: 40px;
  width: 112px;
  opacity: 0;
  background: url("https://d35408cyocioye.cloudfront.net/legacy/new2/footer-get-josh-button.png") no-repeat;
  margin-top: 30px;
  margin-left: -56px;
  padding-top: 9px;
  transition: all 1.5s;
  position: absolute;
}

.section1GetJoshButton:hover {
  opacity: 1 !important;
}

.section1GetJoshButtonText {
  color: #fff;
  font-size: 17px;
}

.newTextLarge {
  color: #50504e;
  font-size: 48px;
  font-weight: 300;
}

.newTextSmall {
  color: #50504e;
  font-size: 16px;
  line-height: 1.6em;
}

.navJosh1Animate {
  height: 28px;
  width: 33px;
  opacity: 0;
  background: url("https://d35408cyocioye.cloudfront.net/new2/josh_animate.gif") 0 0 / 33px 28px no-repeat;
  margin-top: -1px;
  margin-left: -4px;
  transition: all 2s;
  position: absolute;
}

.navJosh1:hover > .navJosh1Animate {
  opacity: 1;
}

#newblock1 {
  height: 630px;
  width: 100%;
  position: relative;
  overflow: hidden;
}

#newBlock1Vid {
  min-width: 100%;
  width: auto;
  width: 100%;
  min-height: 100%;
  height: auto;
  height: 100%;
  z-index: -100;
  pointer-events: none;
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  overflow-y: hidden;
  transform: translateX(-50%)translateY(-50%);
}

.newBlock1VidOverlay {
  height: 100%;
  width: 100%;
  z-index: -99;
  opacity: .5;
  background: #000;
  position: absolute;
  top: 0;
  left: 0;
}

.newsection1text {
  text-align: center;
  color: #fff;
  opacity: 0;
  max-width: 700px;
  margin: 50px auto auto;
  padding-top: 150px;
  padding-left: 20px;
  padding-right: 20px;
  transition: all 2s;
}

.newtext1Container {
  height: 630px;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.ignore-css {
  all: unset;
}

.section1PlayButton {
  height: 50px;
  width: 68px;
  opacity: 0;
  background: url("https://d35408cyocioye.cloudfront.net/new2/playbutton.png") no-repeat;
  margin: 50px auto auto;
  transition: all .5s;
}

.section1PlayButton:hover {
  cursor: pointer;
  opacity: 1 !important;
}

.newsection1textSmall {
  text-align: center;
  color: #fff;
  opacity: 0;
  margin-top: 30px;
  font-size: 14px;
  font-weight: 300;
  transition: all 2s;
}

.section1ripple {
  height: 400px;
  width: 400px;
  opacity: 0;
  background: url("https://d35408cyocioye.cloudfront.net/new2/ripple.png") no-repeat;
  margin: 100px auto auto -200px;
  transition: all 2s;
  position: absolute;
  left: 50%;
}

.newsection1line1 {
  width: 10px;
  height: 0%;
  opacity: .3;
  border-right: 1px solid #fff;
  transition: all 2s;
  position: absolute;
  top: 10%;
  left: 100px;
}

.newsection1line2 {
  width: 0%;
  height: 10px;
  opacity: .3;
  border-top: 1px solid #fff;
  transition: all 2s;
  position: absolute;
  top: 20%;
  left: 5%;
}

.newsection1line3 {
  width: 10px;
  height: 0%;
  opacity: .3;
  border-right: 1px solid #fff;
  transition: all 2s;
  position: absolute;
  top: 20%;
  right: 10%;
}

.newsection1line4 {
  width: 0%;
  height: 10px;
  opacity: .3;
  border-top: 1px solid #fff;
  transition: all 2s;
  position: absolute;
  top: 80%;
  right: 5%;
}

.newsection1line5 {
  width: 0%;
  height: 10px;
  opacity: .8;
  border-top: 1px solid #fff;
  transition: all 1s;
  position: absolute;
  top: 330px;
  right: 50%;
}

.newsection1line6 {
  width: 0%;
  height: 10px;
  opacity: .8;
  border-top: 1px solid #fff;
  transition: all 1s;
  position: absolute;
  top: 330px;
  left: 50%;
}

.newdivider0 {
  text-align: center;
  padding: 50px;
}

#newblock5 {
  height: 600px;
  width: 100%;
  z-index: 100;
  background: url("https://d35408cyocioye.cloudfront.net/new2/bedroom.jpg") bottom / cover no-repeat;
  border-bottom: 15px solid #fff;
}

.newblock5TextLarge {
  color: #fff;
  padding-top: 250px;
}

.newblock5TextSmall {
  color: #fff;
  width: 50%;
  width: 60%;
  margin: 10px auto auto;
}

#newblock2 {
  height: 600px;
  width: 100%;
  z-index: 100;
  background: url("https://d35408cyocioye.cloudfront.net/new2/josh_photograph.jpg") bottom / cover no-repeat;
  border-bottom: 15px solid #fff;
}

.newblock2TextLarge {
  padding-top: 30px;
}

.newblock2TextSmall {
  width: 50%;
  width: 60%;
  margin: 10px auto auto;
}

.newblock2Button {
  margin-top: 30px;
}

.newblock2Button a {
  color: #008cff;
  text-decoration: none;
}

.newblock2Button a:hover {
  color: #0069bf;
}

#newblock3 {
  height: 600px;
  width: 100%;
  border-bottom: 15px solid #fff;
}

.newblock3Top, .newblock3Bottom {
  height: 50%;
  width: 100%;
}

.newblock3TopLeft {
  height: 100%;
  width: 50%;
  float: left;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background: url("https://d35408cyocioye.cloudfront.net/new2/micro_talk2.jpg") center / cover no-repeat;
  transition: all 3s;
}

.newblock3TopLeft:hover {
  opacity: .8;
}

.newblock3TopRight {
  width: 50%;
  height: 100%;
  text-align: center;
  margin-left: 50%;
}

.newblock3BottomLeft {
  width: 50%;
  height: 100%;
  float: left;
}

.newblock3BottomRight {
  height: 100%;
  width: 50%;
  float: right;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background: url("https://d35408cyocioye.cloudfront.net/new2/micro_touch.jpg") center / cover no-repeat;
  transition: all 3s;
}

.newblock3BottomRight:hover {
  opacity: .8;
}

.newblock3TextLarge {
  padding-top: 50px;
}

.newblock3TextSmall {
  width: 80%;
  margin: 10px auto auto;
}

.newblock3Line {
  width: 150px;
  height: 3px;
  background-color: #f2c760;
  border-radius: 5px;
  margin: 20px auto auto;
}

#newblock4 {
  height: 600px;
  width: 100%;
  border-bottom: 15px solid #fff;
}

#featuresDiamond {
  height: 300px;
  width: 300px;
  background-color: #fff;
  margin-top: 150px;
  margin-left: -150px;
  position: absolute;
  left: 50%;
  transform: rotate(45deg);
}

#featuresText {
  width: 400px;
  margin-top: 225px;
  margin-left: -200px;
  position: absolute;
  left: 50%;
}

.featuresTextSmall {
  width: 250px;
  margin: 20px auto auto;
}

.featuresLine {
  width: 200px;
  height: 3px;
  background-color: #f2c760;
  border-radius: 5px;
  margin: 20px auto auto;
}

.featuresCornerText {
  color: #fff;
  margin: auto;
  padding-top: 135px;
}

.featuresCornerTextTitle {
  margin-bottom: 10px;
  font-size: 40px;
  font-weight: 300;
}

.featuresCornerTextBody {
  display: none;
}

#featuresCorner1 {
  float: left;
  height: 50%;
  width: 50%;
  background-color: #0000;
  background-image: linear-gradient(#00000080, #00000080), url("https://d35408cyocioye.cloudfront.net/new2/modern1.jpg");
  background-position: 0 0, 0 0;
  background-repeat: repeat, no-repeat;
  background-size: 100% 100%;
  background-attachment: scroll, scroll;
  background-origin: padding-box, padding-box;
  background-clip: border-box, border-box;
  transition: all 1.5s;
}

#featuresCorner1:hover {
  background-color: #0000;
  background-image: linear-gradient(#0009, #0009), url("https://d35408cyocioye.cloudfront.net/new2/modern1.jpg");
  background-position: 0 0, 0 0;
  background-repeat: repeat, no-repeat;
  background-size: 105% 105%;
  background-attachment: scroll, scroll;
  background-origin: padding-box, padding-box;
  background-clip: border-box, border-box;
}

#featuresCorner2 {
  float: right;
  height: 50%;
  width: 50%;
  background-color: #0000;
  background-image: linear-gradient(#00000080, #00000080), url("https://d35408cyocioye.cloudfront.net/new2/modern2.jpg");
  background-position: 0 0, 0 0;
  background-repeat: repeat, no-repeat;
  background-size: 100% 100%;
  background-attachment: scroll, scroll;
  background-origin: padding-box, padding-box;
  background-clip: border-box, border-box;
  transition: all 1.5s;
}

#featuresCorner2:hover {
  background-color: #0000;
  background-image: linear-gradient(#0009, #0009), url("https://d35408cyocioye.cloudfront.net/new2/modern2.jpg");
  background-position: 0 0, 0 0;
  background-repeat: repeat, no-repeat;
  background-size: 105% 105%;
  background-attachment: scroll, scroll;
  background-origin: padding-box, padding-box;
  background-clip: border-box, border-box;
}

#featuresCorner3 {
  float: left;
  height: 50%;
  width: 50%;
  background-color: #0000;
  background-image: linear-gradient(#00000080, #00000080), url("https://d35408cyocioye.cloudfront.net/new2/modern3.jpg");
  background-position: 0 0, 0 0;
  background-repeat: repeat, no-repeat;
  background-size: 100% 100%;
  background-attachment: scroll, scroll;
  background-origin: padding-box, padding-box;
  background-clip: border-box, border-box;
  transition: all 1.5s;
}

#featuresCorner3:hover {
  background-color: #0000;
  background-image: linear-gradient(#0009, #0009), url("https://d35408cyocioye.cloudfront.net/new2/modern3.jpg");
  background-position: 0 0, 0 0;
  background-repeat: repeat, no-repeat;
  background-size: 105% 105%;
  background-attachment: scroll, scroll;
  background-origin: padding-box, padding-box;
  background-clip: border-box, border-box;
}

#featuresCorner4 {
  float: right;
  height: 50%;
  width: 50%;
  background-color: #0000;
  background-image: linear-gradient(#00000080, #00000080), url("https://d35408cyocioye.cloudfront.net/new2/modern4.jpg");
  background-position: 0 0, 0 0;
  background-repeat: repeat, no-repeat;
  background-size: 100% 100%;
  background-attachment: scroll, scroll;
  background-origin: padding-box, padding-box;
  background-clip: border-box, border-box;
  transition: all 1.5s;
}

#featuresCorner4:hover {
  background-color: #0000;
  background-image: linear-gradient(#0009, #0009), url("https://d35408cyocioye.cloudfront.net/new2/modern4.jpg");
  background-position: 0 0, 0 0;
  background-repeat: repeat, no-repeat;
  background-size: 105% 105%;
  background-attachment: scroll, scroll;
  background-origin: padding-box, padding-box;
  background-clip: border-box, border-box;
}

#newblock6 {
  height: 560px;
  width: 100%;
  z-index: 100;
  background: url("https://d35408cyocioye.cloudfront.net/new2/joshapp.jpg") 0 0 / cover no-repeat;
  background-position: middle;
  border-bottom: 15px solid #fff;
}

.newblock6Cont {
  position: inherit;
  width: 550px;
  float: right;
  margin-top: 50px;
  margin-right: 5%;
  padding-right: 15px;
}

.newblock6TextSmall {
  width: 50%;
  width: 60%;
  margin: 15px auto auto;
}

.newblock6Button {
  margin-top: 20px;
}

.newblock6Button a {
  color: #008cff;
  text-decoration: none;
}

.newblock6Button a:hover {
  color: #0069bf;
}

#newblock8 {
  height: 600px;
  width: 100%;
  z-index: 100;
  background: url("https://d35408cyocioye.cloudfront.net/new2/newfooter.jpg") bottom / cover no-repeat;
  border-bottom: 15px solid #fff;
  margin-bottom: 180px;
}

.newblock8cont {
  width: 400px;
  float: left;
  margin-top: 80px;
  margin-left: 50px;
}

.newblock8Text {
  color: #fff;
  margin-top: 30px;
}

.newbottomButton {
  color: #008cff;
  width: 150px;
  background-color: #ffffffe6;
  border-radius: 50px;
  margin: 30px auto auto;
  padding: 10px 10px 12px;
}

.newbottomButton:hover {
  cursor: pointer;
  background-color: #fff;
}

#newblockmobile {
  display: none;
}

#block1 {
  border-bottom: none;
}

.divider0, .divider1 {
  text-align: center;
  color: #303c45;
  background-color: #f9f9f9;
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 3px;
  margin-bottom: 15px;
  padding: 30px 0 40px;
}

.divider0 {
  padding: 26px 20px 20px;
}

.divider0Cedia {
  width: 185px;
  margin-top: 5px;
  margin-left: -197px;
  position: absolute;
}

.divider0Text {
  color: #cdcdc5;
  text-transform: uppercase;
  margin-top: 15px;
  margin-left: 20px;
  font-size: 10px;
  font-weight: 800;
  position: absolute;
}

.worksWithLogo2 {
  height: 40px;
  width: 161px;
  background: url("https://d35408cyocioye.cloudfront.net/legacy/new2/works+with/lutron.png") no-repeat;
  margin-top: -27px;
  margin-left: 40px;
  transition: all 1s;
  position: absolute;
}

.divider1_old {
  width: 100%;
  text-align: center;
  color: #fff;
  background-color: #303c45;
  border-bottom: 15px solid #fff;
  padding: 30px 0 40px;
}

.dividerVideos {
  height: 199px;
  text-align: center;
  color: #fff;
  border-bottom: 15px solid #fff;
  overflow: hidden;
}

#mainVideo {
  width: 100%;
  height: 560px;
  background-color: #0000;
  background-image: linear-gradient(#0000001a, #0000001a), url("https://d35408cyocioye.cloudfront.net/new2/video.jpg");
  background-position: 0 0, 0 0;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: scroll, scroll;
  background-origin: padding-box, padding-box;
  background-clip: border-box, border-box;
  padding-top: 46px;
  position: relative;
}

#mainVideo:hover {
  cursor: pointer;
}

#mainVideo:hover > .integratorTopPlayButton {
  cursor: pointer;
  background-position: 0 51px;
}

.dividerVideo1, .dividerVideo2, .dividerVideo3 {
  float: left;
  width: calc(33% - 3px);
  margin-bottom: 10px;
  margin-right: 10px;
}

.section4rows {
  width: 100%;
  height: 350px;
  background-color: #f9f9f9;
}

.section4boxes {
  height: 350px;
  width: 33%;
  float: left;
}

.section4text {
  color: #fff;
  margin-top: 15px;
  font-size: 11px;
}

.section4boxes:hover > .section4text {
  opacity: 0;
}

.section4quote {
  color: #188efa;
  width: 200px;
  opacity: 0;
  background-color: #fff;
  border-radius: 8px;
  margin: 0 auto;
  padding: 10px;
  font-style: italic;
  transition: all .5s;
  position: relative;
  left: 0;
  right: 0;
}

.section4boxes:hover > .section4quote {
  opacity: 1;
  margin-top: 135px;
}

.section4topLeft {
  background-color: #0000;
  background-image: linear-gradient(#00000080, #00000080), url("https://d35408cyocioye.cloudfront.net/legacy/new2/effortless/sectional1.jpg");
  background-position: 0 0, 0 0;
  background-repeat: repeat, no-repeat;
  background-size: cover;
  background-attachment: scroll, scroll;
  background-origin: padding-box, padding-box;
  background-clip: border-box, border-box;
  border-bottom: 2px solid #fff;
  border-right: 2px solid #fff;
  transition: all .5s;
}

.section4topLeft:hover {
  background: url("https://d35408cyocioye.cloudfront.net/legacy/new2/effortless/sectional1.jpg") 0 0 / cover no-repeat;
}

.section4mic1 {
  height: 50px;
  width: 50px;
  background: url("https://d35408cyocioye.cloudfront.net/legacy/new2/effortless/mic-button.png") no-repeat;
  margin-top: 105px;
  transition: margin-top .5s;
  position: absolute;
  left: calc(16.5% - 20px);
}

.section4topLeft:hover > .section4mic1 {
  background-position: 0 -50px;
  margin-top: 10px;
}

.section4topMiddle {
  height: 240px;
  background-color: #fff;
  border-bottom: 2px solid #fff;
  padding-top: 100px;
}

.section4topRight {
  background-color: #0000;
  background-image: linear-gradient(#00000080, #00000080), url("https://d35408cyocioye.cloudfront.net/legacy/new2/effortless/sectional2.jpg");
  background-position: 0 0, 0 0;
  background-repeat: repeat, no-repeat;
  background-size: cover;
  background-attachment: scroll, scroll;
  background-origin: padding-box, padding-box;
  background-clip: border-box, border-box;
  border-bottom: 2px solid #fff;
  border-left: 2px solid #fff;
  transition: all .5s;
}

.section4topRight:hover {
  background: url("https://d35408cyocioye.cloudfront.net/legacy/new2/effortless/sectional2.jpg") 0 0 / cover no-repeat;
}

.section4mic2 {
  height: 50px;
  width: 50px;
  background: url("https://d35408cyocioye.cloudfront.net/legacy/new2/effortless/mic-button.png") no-repeat;
  margin-top: 105px;
  transition: margin-top .5s;
  position: absolute;
  left: calc(82.5% - 20px);
}

.section4mic1:hover, .section4mic2:hover, .section4mic3:hover, .section4mic4:hover, .section4mic5:hover {
  cursor: pointer;
}

.section4topRight:hover > .section4mic2 {
  background-position: 0 -50px;
  margin-top: 10px;
}

.section4bottomLeft {
  background-color: #0000;
  background-image: linear-gradient(#00000080, #00000080), url("https://d35408cyocioye.cloudfront.net/legacy/new2/effortless/sectional5.jpg");
  background-position: 0 0, 0 0;
  background-repeat: repeat, no-repeat;
  background-size: cover;
  background-attachment: scroll, scroll;
  background-origin: padding-box, padding-box;
  background-clip: border-box, border-box;
  border-right: 2px solid #fff;
  transition: all .5s;
}

.section4bottomLeft:hover {
  background: url("https://d35408cyocioye.cloudfront.net/legacy/new2/effortless/sectional5.jpg") 0 0 / cover no-repeat;
}

.section4mic3 {
  height: 50px;
  width: 50px;
  background: url("https://d35408cyocioye.cloudfront.net/legacy/new2/effortless/mic-button.png") no-repeat;
  margin-top: 105px;
  transition: margin-top .5s;
  position: absolute;
  left: calc(16.5% - 20px);
}

.section4bottomLeft:hover > .section4mic3 {
  background-position: 0 -50px;
  margin-top: 10px;
}

.section4bottomMiddle {
  background-color: #0000;
  background-image: linear-gradient(#00000080, #00000080), url("https://d35408cyocioye.cloudfront.net/legacy/new2/effortless/sectional4.jpg");
  background-position: 0 0, 0 0;
  background-repeat: repeat, no-repeat;
  background-size: cover;
  background-attachment: scroll, scroll;
  background-origin: padding-box, padding-box;
  background-clip: border-box, border-box;
  transition: all .5s;
}

.section4bottomMiddle:hover {
  background: url("https://d35408cyocioye.cloudfront.net/legacy/new2/effortless/sectional4.jpg") 0 0 / cover no-repeat;
}

.section4mic4 {
  height: 50px;
  width: 50px;
  background: url("https://d35408cyocioye.cloudfront.net/legacy/new2/effortless/mic-button.png") no-repeat;
  margin-top: 105px;
  transition: margin-top .5s;
  position: absolute;
  left: calc(49.5% - 20px);
}

.section4bottomMiddle:hover > .section4mic4 {
  background-position: 0 -50px;
  margin-top: 10px;
}

.section4bottomRight {
  background-color: #0000;
  background-image: linear-gradient(#00000080, #00000080), url("https://d35408cyocioye.cloudfront.net/legacy/new2/effortless/sectional3.jpg");
  background-position: 0 0, 0 0;
  background-repeat: repeat, no-repeat;
  background-size: cover;
  background-attachment: scroll, scroll;
  background-origin: padding-box, padding-box;
  background-clip: border-box, border-box;
  border-left: 2px solid #fff;
  transition: all .5s;
}

.section4bottomRight:hover {
  background: url("https://d35408cyocioye.cloudfront.net/legacy/new2/effortless/sectional3.jpg") 0 0 / cover no-repeat;
}

.section4mic5 {
  height: 50px;
  width: 50px;
  background: url("https://d35408cyocioye.cloudfront.net/legacy/new2/effortless/mic-button.png") no-repeat;
  margin-top: 105px;
  transition: margin-top .5s;
  position: absolute;
  left: calc(82.5% - 20px);
}

.section4bottomRight:hover > .section4mic5 {
  background-position: 0 -50px;
  margin-top: 10px;
}

.divider2 {
  height: 120px;
  width: 100%;
  background-color: #f9f9f9;
  padding-top: 30px;
}

.divider2text {
  color: #555;
  margin-bottom: 20px;
  font-size: 11px;
}

.divider2list {
  height: 86px;
}

.divider2icons {
  height: 50px;
  width: 50px;
  padding-bottom: 30px;
  padding-right: 80px;
  list-style-type: none;
  transition: padding .5s;
  display: inline;
}

.divider2icons1 {
  background: url("https://d35408cyocioye.cloudfront.net/legacy/new2/buffericons/josh-works-with-thermostat-stacked.png") 0 -50px no-repeat;
}

.divider2icons2 {
  background: url("https://d35408cyocioye.cloudfront.net/legacy/new2/buffericons/josh-works-with-lights-stacked.png") 0 -50px no-repeat;
}

.divider2icons3 {
  background: url("https://d35408cyocioye.cloudfront.net/legacy/new2/buffericons/josh-works-with-cameras-stacked.png") 0 -50px no-repeat;
}

.divider2icons4 {
  background: url("https://d35408cyocioye.cloudfront.net/legacy/new2/buffericons/josh-works-with-music-stacked.png") 0 -50px no-repeat;
}

.divider2icons5 {
  background: url("https://d35408cyocioye.cloudfront.net/legacy/new2/buffericons/josh-works-with-entertain-stacked.png") 0 -50px no-repeat;
}

.divider2icons6 {
  background: url("https://d35408cyocioye.cloudfront.net/legacy/new2/buffericons/josh-works-with-security-stacked.png") 0 -50px no-repeat;
}

.divider2icons7 {
  background: url("https://d35408cyocioye.cloudfront.net/legacy/new2/buffericons/josh-works-with-sensors-stacked.png") 0 -50px no-repeat;
}

.divider2icons8 {
  background: url("https://d35408cyocioye.cloudfront.net/legacy/new2/buffericons/josh-works-with-outdoors-stacked.png") 0 -50px no-repeat;
}

.divider2icons9 {
  background: url("https://d35408cyocioye.cloudfront.net/legacy/new2/buffericons/josh-works-with-switches-stacked.png") 0 -50px no-repeat;
}

.divider2icons10 {
  background: url("https://d35408cyocioye.cloudfront.net/legacy/new2/buffericons/josh-works-with-shades-stacked.png") 0 -50px no-repeat;
}

.divider2icons11 {
  background: url("https://d35408cyocioye.cloudfront.net/legacy/new2/buffericons/josh-works-with-garage-stacked.png") 0 -50px no-repeat;
}

.divider2icons:hover {
  background-position: 0 0;
}

.divider2listText {
  color: #555;
  text-transform: uppercase;
  opacity: 0;
  margin-top: 62px;
  font-size: 11px;
  transition: all .5s;
  position: absolute;
}

.divider2icons:hover > .divider2listText {
  opacity: 1;
}

.divider3 {
  width: 100%;
  height: 70px;
  text-align: center;
  background-color: #fff;
  padding-top: 20px;
}

#block8 {
  height: 600px;
  width: 100%;
  z-index: 100;
  background-color: #0000;
  background-image: linear-gradient(#0009, #0009), url("https://d35408cyocioye.cloudfront.net/legacy/new2/alex-house-back-outside.jpg");
  background-position: bottom;
  background-repeat: repeat, no-repeat;
  background-size: cover;
  background-attachment: scroll, scroll;
  background-origin: padding-box, padding-box;
  background-clip: border-box, border-box;
  margin-top: 1415px;
  margin-bottom: 180px;
}

.bottomLogo {
  margin-top: 150px;
  margin-left: -80px;
  position: absolute;
  left: 50%;
}

.bottomText {
  color: #fff;
  margin: 350px auto auto;
  font-size: 30px;
  font-weight: 300;
  position: absolute;
  left: 0;
  right: 0;
}

.bottomButton {
  height: 40px;
  width: 112px;
  color: #fff;
  background: url("https://d35408cyocioye.cloudfront.net/legacy/new2/footer/sectional-footer-get-josh-button.png") no-repeat;
  margin: 405px auto auto;
  padding-top: 12px;
  font-size: 14px;
  font-weight: 800;
  position: absolute;
  left: 0;
  right: 0;
}

.bottomButton:hover {
  cursor: pointer;
  opacity: .9;
}

.footer {
  width: 100%;
  max-width: 1120px;
  height: 80px;
  z-index: 1;
  background-color: #303c45;
  position: fixed;
  bottom: 0;
}

#footerLinks {
  width: 100%;
  transition: all 1s;
  position: absolute;
  bottom: 20px;
}

#footerList {
  text-align: center;
  margin: 10px 20px;
  padding: 0;
}

#footerList li {
  color: #fff;
  padding-right: 20px;
  list-style-type: none;
  display: inline;
}

.footerText a {
  color: #fff;
  opacity: 1;
  font-size: 12px;
  transition: all;
}

.footerText a:hover {
  color: #ccc;
}

.submitForm {
  height: 20px;
  width: 11px;
  cursor: pointer;
  background: url("https://d35408cyocioye.cloudfront.net/legacy/new2/footer/sectional-footer-email-container-arrow.png") no-repeat;
  border: none;
  margin-top: 59px;
  margin-left: -41px;
  position: absolute;
}

#emailform:focus > .submitForm, .submitForm:hover, .submitForm:active, .submitForm:focus {
  outline-style: none;
  outline-color: #0000;
  margin-left: -40px;
}

::-webkit-input-placeholder {
  color: #ccc;
}

:-moz-placeholder {
  color: #ccc;
}

::-moz-placeholder {
  color: #ccc;
}

:-ms-input-placeholder {
  color: #ccc;
}

#newbase-content {
  z-index: 10;
  min-height: 100px;
  background-color: #fff;
  position: relative;
}

#newbase-bottom-margin {
  height: 1px;
  position: inherit;
  z-index: -1;
  margin-top: 2014px;
  margin-bottom: 180px;
}

#new-login-form {
  height: 600px;
  width: 100%;
  background: url("https://d35408cyocioye.cloudfront.net/legacy/new2/login-page-body-background2.jpg") 0 0 / cover no-repeat;
  border-bottom: 15px solid #fff;
  margin-bottom: 80px;
  padding-top: 100px;
}

.loginCont {
  width: 300px;
  opacity: 0;
  background-color: #fff;
  border-radius: 2px;
  margin-top: -230px;
  margin-left: -200px;
  padding: 50px;
  transition: all .5s;
  position: absolute;
  top: 50%;
  left: 50%;
  box-shadow: 0 0 9px -4px #00000070;
}

#loginTitle {
  opacity: 0;
  color: #555;
  letter-spacing: .02em;
  margin: auto auto 20px;
  font-size: 35px;
  font-weight: 300;
  transition: opacity 2s;
}

#loginText {
  color: #666;
  margin: auto;
  font-size: 13px;
  line-height: 1.6;
}

.loginTextTop {
  opacity: 0;
  transition: opacity 2s;
}

#loginForm {
  opacity: 0;
  margin-top: 10px;
  transition: opacity 2s;
}

#loginTerms {
  opacity: 0;
  justify-content: space-between;
  margin-top: 20px;
  transition: opacity 2s;
  display: flex;
}

#registerTerms {
  width: 25%;
  justify-content: space-between;
  margin: auto;
  padding-bottom: 20px;
  display: flex;
}

#loginForm input, #forgotPasswordForm input {
  width: 260px;
  height: 40px;
  background-position: 0 0;
  border: 1px solid #008cff;
  border-radius: 50px;
  margin-top: 20px;
  padding-left: 20px;
  padding-right: 20px;
}

#loginForm input:active, #loginForm input:focus, #forgotPasswordForm input:active, #forgotPasswordForm input:focus {
  outline-style: none;
  outline-color: #0000;
}

#loginForm input[type="submit"], #forgotPasswordForm input[type="submit"] {
  color: #fff;
  width: 121px;
  letter-spacing: 2px;
  text-transform: uppercase;
  background-color: #008cff;
  border: none;
  border-radius: 50px;
  padding-top: 2px;
  font-family: Lato, sans-serif;
  font-size: 15px;
  font-weight: 800;
}

#loginForm input[type="submit"]:hover, #forgotPasswordForm input[type="submit"]:hover {
  cursor: pointer;
  background-color: #0069bf;
}

#loginForm label, .text-right {
  display: none;
}

#forgotPasswordTitle {
  color: #555;
  letter-spacing: .02em;
  margin: auto auto 20px;
  font-size: 35px;
  font-weight: 300;
  transition: opacity 2s;
}

#forgotPasswordText {
  color: #666;
  margin: auto;
  font-size: 13px;
  line-height: 1.6;
}

#changePasswordForm input {
  width: 260px;
  height: 40px;
  background: url("https://d35408cyocioye.cloudfront.net/legacy/new2/sectional-footer-email-stack.png") no-repeat;
  border: none;
  outline: none;
  margin-top: 20px;
  padding-left: 20px;
  padding-right: 20px;
}

#changePasswordForm input[type="submit"] {
  color: #fff;
  width: 121px;
  text-transform: uppercase;
  background: url("https://d35408cyocioye.cloudfront.net/legacy/new2/contact/email-button-four-stack.png") 0 -40px no-repeat;
  margin-top: 20px;
  padding-top: 4px;
  font-family: Lato, sans-serif;
  font-size: 12px;
  font-weight: 800;
}

#changePasswordForm input[type="submit"]:hover {
  cursor: pointer;
  background: url("https://d35408cyocioye.cloudfront.net/legacy/new2/contact/email-button-four-stack.png") 0 -80px no-repeat;
}

#aboutTopSection {
  width: 100%;
  background-color: #0000;
  background-image: linear-gradient(#fff6, #fff6), url("https://d35408cyocioye.cloudfront.net/legacy/team/about-header-background.jpg");
  background-position: 0 0, 0 0;
  background-repeat: repeat, no-repeat;
  background-size: cover;
  background-attachment: scroll, scroll;
  background-origin: padding-box, padding-box;
  background-clip: border-box, border-box;
  padding: 110px 0 70px;
  transition: margin 1s;
}

.aboutManifesto {
  width: 300px;
  height: 31px;
  color: #fff;
  opacity: 0;
  background: url("https://d35408cyocioye.cloudfront.net/legacy/team/about-header-manifesto-button-stacked.png") no-repeat;
  margin: 40px auto auto;
  padding-top: 9px;
  transition: opacity 1s;
}

.aboutManifesto:hover {
  cursor: pointer;
  background-position: 0 -40px;
}

#aboutBody {
  width: 100%;
  padding: 40px 0 0;
  transition: margin 1s;
}

#aboutTitle {
  opacity: 0;
  color: #555;
  letter-spacing: .02em;
  margin: auto auto 20px;
  font-size: 35px;
  font-weight: 300;
  transition: opacity 2s;
}

#aboutText {
  color: #888;
  margin: auto;
  font-size: 12px;
  line-height: 1.6;
}

.aboutTextTop {
  opacity: 0;
  transition: opacity 2s;
}

.aboutLocations {
  height: 250px;
  width: 100%;
  text-transform: center;
  margin-bottom: 160px;
}

.aboutLocationsLeft {
  width: 50%;
  float: left;
  padding-top: 25px;
}

.aboutLocationsRight {
  width: 50%;
  margin-left: 50%;
  padding-top: 25px;
}

.aboutLocationCity {
  color: #3ca4fc;
  margin-top: 10px;
  margin-bottom: 20px;
  font-size: 16px;
  font-weight: 800;
}

.aboutLocationAddress {
  color: #777;
  font-size: 13px;
}

.aboutLocationContact a {
  color: #555;
  margin-top: 10px;
  font-size: 11px;
}

.aboutLocationsDivider {
  margin-top: 10px;
  margin-left: 50%;
  position: absolute;
}

#teamBody {
  width: 100%;
  color: #fff;
  margin-top: 20px;
  padding: 0;
}

.teamEmojis {
  margin-top: 5px;
  transition: margin 1s;
}

.teamEmojis a {
  opacity: 1;
}

#navTeam {
  margin: 0;
  padding: 0;
}

#navTeam li {
  padding: 0;
  list-style-type: none;
  display: inline;
}

.teamSection {
  height: 300px;
  width: 100%;
  max-width: 1200px;
  background-color: #f9f9f9;
  margin: auto auto 15px;
}

.aboutLeftPhoto {
  height: 300px;
  width: 300px;
  float: left;
  transition: all .5s;
}

.aboutRightPhoto {
  height: 300px;
  width: 300px;
  float: right;
  transition: all .5s;
}

.aboutPhotoState {
  margin-top: 100px;
  margin-bottom: 20px;
  display: none;
}

.aboutPhotoText {
  margin-bottom: 5px;
  padding-left: 20px;
  padding-right: 20px;
  font-size: 10px;
  display: none;
}

.aboutPhotoText a {
  font-size: 10px;
  font-weight: 800;
}

.aboutLeftPhoto:hover > .aboutPhotoState, .aboutLeftPhoto:hover > .aboutPhotoText, .aboutRightPhoto:hover > .aboutPhotoState, .aboutRightPhoto:hover > .aboutPhotoText {
  margin-left: auto;
  margin-right: auto;
  display: block;
}

.aboutLeftText {
  margin-left: 15px;
  margin-right: 300px;
  padding: 50px;
}

.aboutRightText {
  max-width: 900px;
  margin-left: 300px;
  margin-right: 15px;
  padding: 50px;
}

.aboutTeamName {
  color: #333;
  margin-top: 20px;
  margin-bottom: 25px;
  font-size: 39px;
  font-weight: 100;
}

.aboutTeamText {
  color: #777;
  text-align: justify;
  font-size: 13px;
  line-height: 1.4;
}

.aboutTeamText a {
  color: #333;
  font-size: 13px;
}

.teamPhotoAlex {
  background: url("https://d35408cyocioye.cloudfront.net/legacy/team/alex.jpg") 0 0 / cover no-repeat;
}

.teamPhotoAlex:hover {
  background: url("https://d35408cyocioye.cloudfront.net/legacy/team/about-page-alex-hover.jpg") 0 0 / cover no-repeat;
}

.teamPhotoTim {
  background: url("https://d35408cyocioye.cloudfront.net/legacy/team/tim.jpg") 0 0 / cover no-repeat;
}

.teamPhotoTim:hover {
  background: url("https://d35408cyocioye.cloudfront.net/legacy/team/about-page-tim-hover.jpg") 0 0 / cover no-repeat;
}

.teamPhotoJason {
  background: url("https://d35408cyocioye.cloudfront.net/legacy/team/jason.jpg") 0 0 / cover no-repeat;
}

.teamPhotoJason:hover {
  background: url("https://d35408cyocioye.cloudfront.net/legacy/team/about-page-jason-hover.jpg.jpg") 0 0 / cover no-repeat;
}

.teamPhotoNader {
  background: url("https://d35408cyocioye.cloudfront.net/legacy/team/about-page-nader.jpg") 0 / cover no-repeat;
}

.teamPhotoNader:hover {
  background: url("https://d35408cyocioye.cloudfront.net/legacy/team/about-page-nader-hover.jpg") 0 0 / cover no-repeat;
}

.teamPhotoNate {
  background: url("https://d35408cyocioye.cloudfront.net/legacy/team/about-page-nate.jpg") 0 0 / cover no-repeat;
}

.teamPhotoNate:hover {
  background: url("https://d35408cyocioye.cloudfront.net/legacy/team/about-page-nate-hover.jpg") 0 0 / cover no-repeat;
}

.teamPhotoMyers {
  background: url("https://d35408cyocioye.cloudfront.net/legacy/team/about-page-tmyers.jpg") 0 / cover no-repeat;
}

.teamPhotoMyers:hover {
  background: url("https://d35408cyocioye.cloudfront.net/legacy/team/about-page-tmyers-hover.jpg") 0 0 / cover no-repeat;
}

.teamPhotoAtticus {
  background: url("https://d35408cyocioye.cloudfront.net/legacy/team/atticus.jpg") 0 0 / cover no-repeat;
}

.teamPhotoAtticus:hover {
  background: url("https://d35408cyocioye.cloudfront.net/legacy/team/about-page-atticus-hover.jpg") 0 0 / cover no-repeat;
}

.teamPhotoBenji {
  background: url("https://d35408cyocioye.cloudfront.net/legacy/team/about-page-benji.jpg") 0 0 / cover no-repeat;
}

.teamPhotoBenji:hover {
  background: url("https://d35408cyocioye.cloudfront.net/legacy/team/about-page-benji-hover.jpg") 0 0 / cover no-repeat;
}

.teamPhotoSonny {
  background: url("https://d35408cyocioye.cloudfront.net/legacy/team/about-page-sonny.jpg") 0 0 / cover no-repeat;
}

.teamPhotoSonny:hover {
  background: url("https://d35408cyocioye.cloudfront.net/legacy/team/about-page-sonny-hover.jpg") 0 0 / cover no-repeat;
}

.teamPhotoPaul {
  background: url("https://d35408cyocioye.cloudfront.net/legacy/team/about-page-paul.jpg") 0 0 / cover no-repeat;
}

.teamPhotoPaul:hover {
  background: url("https://d35408cyocioye.cloudfront.net/legacy/team/about-page-paul-hover.jpg") 0 0 / cover no-repeat;
}

.teamPhotoBridget {
  background: url("https://d35408cyocioye.cloudfront.net/legacy/team/team-page-bridget.jpg") 0 0 / cover no-repeat;
}

.teamPhotoBridget:hover {
  background: url("https://d35408cyocioye.cloudfront.net/legacy/team/team-page-bridget-hover.jpg") 0 0 / cover no-repeat;
}

.teamPhotoStephen {
  background: url("https://d35408cyocioye.cloudfront.net/legacy/new2/team-page-stephen.jpg") 0 0 / cover no-repeat;
}

.teamPhotoStephen:hover {
  background: url("https://d35408cyocioye.cloudfront.net/legacy/new2/team-page-stephen-hover.jpg") 0 0 / cover no-repeat;
}

.teamPhotoSchuyler {
  background: url("https://d35408cyocioye.cloudfront.net/legacy/new2/team-page-schuyler.jpg") 0 0 / cover no-repeat;
}

.teamPhotoSchuyler:hover {
  background: url("https://d35408cyocioye.cloudfront.net/legacy/new2/team-page-schuyler-hover.jpg") 0 0 / cover no-repeat;
}

.teamPhotoScotty {
  background: url("https://d35408cyocioye.cloudfront.net/legacy/new2/team-page-scotty.jpg") 0 0 / cover no-repeat;
}

.teamPhotoScotty:hover {
  background: url("https://d35408cyocioye.cloudfront.net/legacy/new2/team-page-scotty-hover.jpg") 0 0 / cover no-repeat;
}

.teamPhotoEd {
  background: url("https://d35408cyocioye.cloudfront.net/legacy/new2/team-page-ed.jpg") 0 0 / cover no-repeat;
}

.teamPhotoEd:hover {
  background: url("https://d35408cyocioye.cloudfront.net/legacy/new2/team-page-ed-hover.jpg") 0 0 / cover no-repeat;
}

.teamPhotoCasey {
  background: url("https://d35408cyocioye.cloudfront.net/legacy/new2/team-page-casey.jpg") 0 0 / cover no-repeat;
}

.teamPhotoCasey:hover {
  background: url("https://d35408cyocioye.cloudfront.net/legacy/new2/team-page-casey-hover.jpg") 0 0 / cover no-repeat;
}

.teamPhotoMichaelG {
  background: url("https://d35408cyocioye.cloudfront.net/legacy/new2/team-page-michael-g.jpg") 0 0 / cover no-repeat;
}

.teamPhotoMichaelG:hover {
  background: url("https://d35408cyocioye.cloudfront.net/legacy/new2/team-page-michael-g-hover.jpg") 0 0 / cover no-repeat;
}

.teamPhotoBrian {
  background: url("https://d35408cyocioye.cloudfront.net/legacy/new2/team-page-brianh.jpg") 0 0 / cover no-repeat;
}

.teamPhotoBrian:hover {
  background: url("https://d35408cyocioye.cloudfront.net/legacy/new2/team-page-brianh-hover.jpg") 0 0 / cover no-repeat;
}

.teamPhotoDerek {
  background: url("https://d35408cyocioye.cloudfront.net/legacy/new2/team-page-derekm.jpg") 0 0 / cover no-repeat;
}

.teamPhotoDerek:hover {
  background: url("https://d35408cyocioye.cloudfront.net/legacy/new2/team-page-derekm-hover.jpg") 0 0 / cover no-repeat;
}

.teamPhotoDylan {
  background: url("https://d35408cyocioye.cloudfront.net/legacy/team/team-page-dylan.jpg") 0 0 / cover no-repeat;
}

.teamPhotoDylan:hover {
  background: url("https://d35408cyocioye.cloudfront.net/legacy/team/team-page-dylan-hover.jpg") 0 0 / cover no-repeat;
}

.teamPhotoKevin {
  background: url("https://d35408cyocioye.cloudfront.net/legacy/team/team-page-kevin.png") 0 0 / cover no-repeat;
}

.teamPhotoKevin:hover {
  background: url("https://d35408cyocioye.cloudfront.net/legacy/team/team-page-kevin-hover.png") 0 0 / cover no-repeat;
}

.teamPhotoMukesh {
  background: url("https://d35408cyocioye.cloudfront.net/legacy/team/team-page-mukesh.jpg") 0 0 / cover no-repeat;
}

.teamPhotoMukesh:hover {
  background: url("https://d35408cyocioye.cloudfront.net/legacy/team/team-page-mukesh-hover.jpg") 0 0 / cover no-repeat;
}

.teamPhotoAaron {
  background: url("https://d35408cyocioye.cloudfront.net/legacy/team/team-page-aarona.png") 0 0 / cover no-repeat;
}

.teamPhotoAaron:hover {
  background: url("https://d35408cyocioye.cloudfront.net/legacy/team/team-page-aarona-hover.png") 0 0 / cover no-repeat;
}

.teamPhotoSaina {
  background: url("https://d35408cyocioye.cloudfront.net/legacy/team/team-page-saina.jpg") 0 0 / cover no-repeat;
}

.teamPhotoSaina:hover {
  background: url("https://d35408cyocioye.cloudfront.net/legacy/team/team-page-saina-hover.jpg") 0 0 / cover no-repeat;
}

.teamPhotoJack {
  background: url("https://d35408cyocioye.cloudfront.net/legacy/team/about-page-jack.jpg") 0 0 / cover no-repeat;
}

.teamPhotoJack:hover {
  background: url("https://d35408cyocioye.cloudfront.net/legacy/team/about-page-jack-hover.jpg") 0 0 / cover no-repeat;
}

.teamPhotoJonathan {
  background: url("https://d35408cyocioye.cloudfront.net/legacy/team/team-page-jonathan.png") 0 0 / cover no-repeat;
}

.teamPhotoJonathan:hover {
  background: url("https://d35408cyocioye.cloudfront.net/legacy/team/team-page-jonathan-hover.png") 0 0 / cover no-repeat;
}

.teamPhotoBryan {
  background: url("https://d35408cyocioye.cloudfront.net/legacy/team/team-page-bryan.png") 0 0 / cover no-repeat;
}

.teamPhotoBryan:hover {
  background: url("https://d35408cyocioye.cloudfront.net/legacy/team/team-page-bryan-hover.png") 0 0 / cover no-repeat;
}

#teamEmojis {
  padding: 0;
  transition: margin 1s;
}

ul#teamEmojis li {
  display: inline;
}

#teamEmojis a {
  opacity: 1;
  margin-right: 5px;
  transition: none;
}

#teamEmojis a:hover {
  opacity: 1;
  display: inline-table;
  transform: rotate(14deg);
}

#aboutFooter {
  color: #fff;
  width: 80%;
  height: 140px;
  max-width: 1200px;
  background-color: #3ca4fc;
  border-bottom: 15px solid #fff;
  margin: auto;
  padding: 50px 0 0;
  font-size: 14px;
}

.aboutFooterButton {
  height: 32px;
  width: 120px;
  background: url("https://d35408cyocioye.cloudfront.net/legacy/team/other-sections-footer-email-button-stacked.png") no-repeat;
  margin: 20px auto auto;
  padding-top: 8px;
}

.aboutFooterButton:hover {
  cursor: pointer;
  background-position: 0 -40px;
}

.recsFooterLink {
  color: #fff;
  margin-top: 11px;
  font-size: 12px;
}

.aboutFooterButton:hover .recsFooterLink {
  color: #3ca4fc;
  cursor: pointer;
}

.aboutTopLeft {
  float: left;
  width: calc(50% - 7.5px);
  background: url("https://d35408cyocioye.cloudfront.net/new2/about/team_hover.jpg") 0 0 / 100% 100% no-repeat;
  transition: all .5s;
}

.aboutTopLeft:hover {
  background: url("https://d35408cyocioye.cloudfront.net/new2/about/team.jpg") 0 0 / 105% 105% no-repeat;
}

.aboutTopRight {
  float: right;
  width: calc(50% - 7.5px);
  background: url("https://d35408cyocioye.cloudfront.net/new2/about/press_hover.jpg") 0 0 / 100% 100% no-repeat;
  transition: all .5s;
}

.aboutTopRight:hover {
  background: url("https://d35408cyocioye.cloudfront.net/new2/about/press.jpg") 0 0 / 105% 105% no-repeat;
}

.aboutBottomLeft {
  float: left;
  width: calc(50% - 7.5px);
  background: url("https://d35408cyocioye.cloudfront.net/new2/about/faqs_hover.jpg") 0 0 / 100% 100% no-repeat;
  margin-top: 15px;
  transition: all .5s;
}

.aboutBottomLeft:hover {
  background: url("https://d35408cyocioye.cloudfront.net/new2/about/faqs.jpg") 0 0 / 105% 105% no-repeat;
}

.aboutBottomRight {
  float: right;
  width: calc(50% - 7.5px);
  background: url("https://d35408cyocioye.cloudfront.net/new2/about/contact_hover.jpg") 0 0 / 100% 100% no-repeat;
  margin-top: 15px;
  transition: all .5s;
}

.aboutBottomRight:hover {
  background: url("https://d35408cyocioye.cloudfront.net/new2/about/contact.jpg") 0 0 / 105% 105% no-repeat;
}

.aboutSquareText {
  height: 160px;
  color: #fff;
  padding-top: 110px;
  font-size: 40px;
  font-weight: 300;
}

#pressTopSection {
  width: 100%;
  background-color: #0000;
  background-image: linear-gradient(#fff6, #fff6), url("https://d35408cyocioye.cloudfront.net/legacy/press-header-background.jpg");
  background-position: 0 0, 0 0;
  background-repeat: repeat, no-repeat;
  background-size: cover;
  background-attachment: scroll, scroll;
  background-origin: padding-box, padding-box;
  background-clip: border-box, border-box;
  padding: 80px 0 50px;
  transition: margin 1s;
}

#navPress {
  color: #333;
  opacity: 0;
  margin: 0;
  padding: 0;
  transition: opacity 1s;
}

#pressBody {
  width: 100%;
  padding-top: 20px;
  transition: margin 1s;
}

#pressText {
  color: #888;
  margin: 20px auto 40px;
  padding-left: 13%;
  padding-right: 13%;
  font-size: 15px;
  line-height: 1.6;
}

.pressGridObject {
  width: calc(25% - 38px);
  height: 130px;
  float: left;
  color: #333;
  background-color: #f9f9f9;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 10px;
  margin-right: 10px;
  padding: 10px 10px 10px 20px;
  font-size: 12px;
  display: flex;
}

.pressGridObject a {
  color: #88aac4;
}

.pressGridObject a:hover {
  color: #3ca4fc;
}

.pressGrid2, .pressGrid3 {
  margin-right: 10px;
}

.pressGrid4 {
  margin-right: 0;
}

.pressGridTitles {
  opacity: .8;
  margin-top: 5px;
  font-weight: 500;
}

.pressGridDate {
  color: #fff;
  text-transform: uppercase;
  margin-top: 10px;
  font-size: 9px;
  font-weight: 800;
}

.pressImage {
  width: 60%;
  opacity: 0;
  border-radius: 4px;
  margin: auto auto 20px;
  transition: opacity 1s;
  display: block;
  box-shadow: 2px 3px 8px -4px #000000bd;
}

.pressapplepodcast {
  background: url("https://joshai.s3.amazonaws.com/legacy/press-bg/applepodcasts.png") no-repeat;
}

.pressstereoplanet {
  background: url("https://joshai.s3.amazonaws.com/legacy/press-bg/stereoplanet.png") no-repeat;
}

.pressrelativehomesystems {
  background: url("https://joshai.s3.amazonaws.com/legacy/press-bg/relative.png") no-repeat;
}

.presspcmag {
  background: url("https://d35408cyocioye.cloudfront.net/legacy/press-bg/pressbg_pcmag.jpg") no-repeat;
}

.presssmashd {
  background: url("https://d35408cyocioye.cloudfront.net/legacy/press-bg/pressbg_smashd.jpg") no-repeat;
}

.pressconnectedlifestyle {
  background: url("https://d35408cyocioye.cloudfront.net/legacy/press-bg/pressbg_connectedlifestyle.jpg") no-repeat;
}

.presssmarthomeshow {
  background: url("https://d35408cyocioye.cloudfront.net/legacy/press-bg/pressbg_smarthomeshow.jpg") no-repeat;
}

.presstwice {
  background: url("https://d35408cyocioye.cloudfront.net/legacy/press-bg/pressbg_twice.jpg") no-repeat;
}

.presscb {
  background: url("https://joshai.s3.amazonaws.com/legacy/press-bg/pressbg_crunchbase.png") no-repeat;
}

.pressvoicebotai {
  background: url("https://joshai.s3.amazonaws.com/legacy/press-bg/pressbg_voicebotai.jpg") no-repeat;
}

.presslatechwatch {
  background: url("https://d35408cyocioye.cloudfront.net/legacy/press-bg/pressbg_latechwatch.jpg") no-repeat;
}

.pressbuiltinco {
  background: url("https://d35408cyocioye.cloudfront.net/legacy/press-bg/pressbg_builtinco.jpg") no-repeat;
}

.pressproducthunt {
  background: url("https://d35408cyocioye.cloudfront.net/legacy/press-bg/pressbg_producthunt.jpg") no-repeat;
}

.presshtacertified {
  background: url("https://d35408cyocioye.cloudfront.net/legacy/press-bg/pressbg_htacertified.jpg") no-repeat;
}

.pressresidentialsystems {
  background: url("https://d35408cyocioye.cloudfront.net/legacy/press-bg/pressbg_residentialsystems.jpg") no-repeat;
}

.press5280magazines {
  background: url("https://d35408cyocioye.cloudfront.net/legacy/press-bg/pressbg_5280magazine.jpg") no-repeat;
}

.presscepro {
  background: url("https://d35408cyocioye.cloudfront.net/legacy/press-bg/pressbg_cepro.jpg") no-repeat;
}

.pressHospitalityNet {
  background: url("https://d35408cyocioye.cloudfront.net/legacy/press-bg/hospitality_net.svg") no-repeat;
}

.pressAVNirvana {
  background: url("https://d35408cyocioye.cloudfront.net/legacy/press-bg/av_nirvana.png") no-repeat;
}

.pressVoyageDenver {
  background: url("https://d35408cyocioye.cloudfront.net/legacy/press-bg/voyage_denver.jpeg") no-repeat;
}

.pressHotelManagement {
  background: url("https://d35408cyocioye.cloudfront.net/legacy/press-bg/hotel_management.svg") no-repeat;
}

.presspsfk {
  background: url("https://d35408cyocioye.cloudfront.net/legacy/press-bg/pressbg_psfk.jpg") no-repeat;
}

.pressfashnerd {
  background: url("https://d35408cyocioye.cloudfront.net/legacy/press-bg/pressbg_fashnerd.jpg") no-repeat;
}

.press4rfv {
  background: url("https://d35408cyocioye.cloudfront.net/legacy/press-bg/pressbg_4rfv.png") no-repeat;
}

.presswidescreen {
  background: url("https://d35408cyocioye.cloudfront.net/legacy/press-bg/pressbg_widescreen_review.png") no-repeat;
}

.pressnahb {
  background: url("https://d35408cyocioye.cloudfront.net/legacy/press-bg/pressbg_nahb_now.png") no-repeat;
}

.pressrestech {
  background: url("https://d35408cyocioye.cloudfront.net/legacy/press-bg/pressbg_res_tech_today.png") no-repeat;
}

.pressindiethink {
  background: url("https://d35408cyocioye.cloudfront.net/legacy/press-bg/pressbg_indie-think.png") no-repeat;
}

.pressessential {
  background: url("https://d35408cyocioye.cloudfront.net/legacy/press-bg/pressbg_essential_install.png") no-repeat;
}

.presssiw {
  background: url("https://d35408cyocioye.cloudfront.net/legacy/press-bg/pressbg_siw.png") no-repeat;
}

.pressopenpr {
  background: url("https://d35408cyocioye.cloudfront.net/legacy/press-bg/pressbg_open_pr.png") no-repeat;
}

.presspac {
  background: url("https://d35408cyocioye.cloudfront.net/legacy/press-bg/pressbg_pacific_audio_comms.png") no-repeat;
}

.pressght {
  background: url("https://d35408cyocioye.cloudfront.net/legacy/press-bg/pressbg_georgia_home_theater.png") no-repeat;
}

.pressscenester {
  background: url("https://d35408cyocioye.cloudfront.net/legacy/press-bg/pressbg_scenester.jpg") no-repeat;
}

.pressselectronichouse {
  background: url("https://d35408cyocioye.cloudfront.net/legacy/press-bg/pressbg_electronichouse.jpg") no-repeat;
}

.presssiotpodcast {
  background: url("https://d35408cyocioye.cloudfront.net/legacy/press-bg/pressbg_iotpodcast.jpg") no-repeat;
}

.pressyahoonews {
  background: url("https://d35408cyocioye.cloudfront.net/legacy/press-bg/pressbg_yahoonews.jpg") no-repeat;
}

.presssdigitaltrends {
  background: url("https://d35408cyocioye.cloudfront.net/legacy/press-bg/pressbg_digitaltrends.jpg") no-repeat;
}

.pressbgsmarthomewelt {
  background: url("https://d35408cyocioye.cloudfront.net/legacy/press-bg/pressbg_smarthomewelt.jpg") no-repeat;
}

.pressbgtechomebuilder {
  background: url("https://d35408cyocioye.cloudfront.net/legacy/press-bg/pressbg_techomebuilder.jpg") no-repeat;
}

.pressbgsdtimes {
  background: url("https://d35408cyocioye.cloudfront.net/legacy/press-bg/pressbg_sdtimes.jpg") no-repeat;
}

.pressivanestrada {
  background: url("https://d35408cyocioye.cloudfront.net/legacy/press-bg/pressbg_ivanestrada.jpg") no-repeat;
}

.pressphilharmonic {
  background: url("https://d35408cyocioye.cloudfront.net/legacy/press-bg/pressbg_philharmonic.png") no-repeat;
}

.presshomealarmreport {
  background: url("https://d35408cyocioye.cloudfront.net/legacy/press-bg/pressbg_homealarmreport.jpg") no-repeat;
}

.presshiddenwires {
  background: url("https://d35408cyocioye.cloudfront.net/legacy/press-bg/pressbg_hiddenwires.jpg") no-repeat;
}

.pressgizmag {
  background: url("https://d35408cyocioye.cloudfront.net/legacy/press-bg/pressbg_gizmag.jpg") no-repeat;
}

.pressyourconnectedfamily {
  background: url("https://d35408cyocioye.cloudfront.net/legacy/press-bg/pressbg_yourconnectedfamily.jpg") no-repeat;
}

.presseverythingaboutdesing {
  background: url("https://d35408cyocioye.cloudfront.net/legacy/press-bg/pressbg_everythingaboutdesign.jpg") no-repeat;
}

.pressworldiot {
  background: url("https://d35408cyocioye.cloudfront.net/legacy/press-bg/pressbg_worldiot.jpg") no-repeat;
}

.presstechnologyintegrator {
  background: url("https://d35408cyocioye.cloudfront.net/legacy/press-bg/pressbg_technologyintegrator.jpg") no-repeat;
}

.presstechdesigner {
  background: url("https://d35408cyocioye.cloudfront.net/legacy/press-bg/pressbg_technology_designer.png") no-repeat;
}

.presslinkedin {
  background: url("https://d35408cyocioye.cloudfront.net/legacy/press-bg/pressbg_linkedin.png") no-repeat;
}

.presscommunitec {
  background: url("https://d35408cyocioye.cloudfront.net/legacy/press-bg/pressbg_communitec.jpg") no-repeat;
}

.pressmobilegeeks {
  background: url("https://d35408cyocioye.cloudfront.net/legacy/press-bg/pressbg_mobilegeeks.jpg") no-repeat;
}

.presstechsmarthome {
  background: url("https://d35408cyocioye.cloudfront.net/legacy/press-bg/pressbg_techsmarthome.jpg") no-repeat;
}

.presssuperyachtnews {
  background: url("https://d35408cyocioye.cloudfront.net/legacy/press-bg/pressbg_superyachtnews.jpg") no-repeat;
}

.pressblogdaengenharia {
  background: url("https://d35408cyocioye.cloudfront.net/legacy/press-bg/pressbg_blogdaengenharia.jpg") no-repeat;
}

.presswdrbnews {
  background: url("https://d35408cyocioye.cloudfront.net/legacy/press-bg/pressbg_wdrbnews.jpg") no-repeat;
}

.pressrave {
  background: url("https://d35408cyocioye.cloudfront.net/legacy/press-bg/pressbg_rave.jpg") no-repeat;
}

.pressdealerscope {
  background: url("https://d35408cyocioye.cloudfront.net/legacy/press-bg/pressbg_dealerscope.jpg") no-repeat;
}

.presstechinsider {
  background: url("https://d35408cyocioye.cloudfront.net/legacy/press-bg/pressbg_technology_insider_group.jpg") no-repeat;
}

.pressssound_vision {
  background: url("https://d35408cyocioye.cloudfront.net/legacy/press-bg/pressbg_sound_vision.jpg") no-repeat;
}

.pressavinsider {
  background: url("https://d35408cyocioye.cloudfront.net/legacy/press-bg/pressbg_avinsider.jpg") no-repeat;
}

.presscedia {
  background: url("https://d35408cyocioye.cloudfront.net/legacy/press-bg/pressbg_cedia_360.jpg") no-repeat;
}

.pressyoutube {
  background: url("https://d35408cyocioye.cloudfront.net/legacy/press-bg/pressbg_youtube_360.jpg") no-repeat;
}

.presshometech {
  background: url("https://d35408cyocioye.cloudfront.net/legacy/press-bg/pressbg_hometech_360.jpg") no-repeat;
}

.presshuffingtonpost {
  background: url("https://d35408cyocioye.cloudfront.net/legacy/press-bg/pressbg_huffingtonpost_360.jpg") no-repeat;
}

.presscommercialintegrator {
  background: url("https://d35408cyocioye.cloudfront.net/legacy/press-bg/pressbg_commercialintegrator.jpg") no-repeat;
}

.pressavshoptalk {
  background: url("https://d35408cyocioye.cloudfront.net/legacy/press-bg/pressbg_avshoptalk.jpg") no-repeat;
}

.pressdenverite {
  background: url("https://d35408cyocioye.cloudfront.net/legacy/press-bg/pressbg_denverite.jpg") no-repeat;
}

.pressprotheticknowledge {
  background: url("https://d35408cyocioye.cloudfront.net/legacy/press-bg/pressbg_pk.jpg") no-repeat;
}

.pressinnovationtech {
  background: url("https://d35408cyocioye.cloudfront.net/legacy/press-bg/pressbg_innovation.jpg") no-repeat;
}

.pressihummingbird {
  background: url("https://d35408cyocioye.cloudfront.net/legacy/press-bg/pressbg_ihummingbird.jpg") no-repeat;
}

.presslatech {
  background: url("https://d35408cyocioye.cloudfront.net/legacy/press-bg/pressbg_latech.jpg") no-repeat;
}

.pressdandy {
  background: url("https://d35408cyocioye.cloudfront.net/legacy/press-bg/pressbg_dandy.jpg") no-repeat;
}

.pressavnation {
  background: url("https://d35408cyocioye.cloudfront.net/new2/press/pressbg_avnation.jpg") no-repeat;
}

.pressconvergent {
  background: url("https://d35408cyocioye.cloudfront.net/new2/press/pressbg_convergent.jpg") no-repeat;
}

.pressentrepreneur {
  background: url("https://d35408cyocioye.cloudfront.net/new2/press/pressbg_entrepreneur.jpg") no-repeat;
}

.pressinavate {
  background: url("https://d35408cyocioye.cloudfront.net/new2/press/pressbg_inavate.jpg") no-repeat;
}

.presscedia2 {
  background: url("https://d35408cyocioye.cloudfront.net/new2/press/pressbg_cedia.jpg") no-repeat;
}

.pressraveorig {
  background: url("https://d35408cyocioye.cloudfront.net/new2/press/pressbg_rave.jpg") no-repeat;
}

.pressforbes {
  background: url("https://d35408cyocioye.cloudfront.net/new2/press/pressbg_forbes.jpg") no-repeat;
}

.pressshowhomestyle {
  background: url("https://d35408cyocioye.cloudfront.net/new2/press/pressbg_slowhomestyle.jpg") no-repeat;
}

.pressbesttechie {
  background: url("https://d35408cyocioye.cloudfront.net/new2/press/pressbg_besttechie.jpg") no-repeat;
}

.pressprnewswire {
  background: url("https://d35408cyocioye.cloudfront.net/new2/press/pressbg_prnewswire.jpg") no-repeat;
}

.pressdenverbizjournal {
  background: url("https://d35408cyocioye.cloudfront.net/new2/press/pressbg_bizjournals.jpg") no-repeat;
}

.presstechcast {
  background: url("https://d35408cyocioye.cloudfront.net/new2/press/pressbg_cotechcast.jpg") no-repeat;
}

.presssdut {
  background: url("https://d35408cyocioye.cloudfront.net/new2/press/pressbg_sdut.jpg") no-repeat;
}

.pressabc7 {
  background: url("https://d35408cyocioye.cloudfront.net/new2/press/pressbg_abc7.jpg") no-repeat;
}

.pressSynergyEnv {
  background: url("https://d35408cyocioye.cloudfront.net/new2/press/pressbg_synergyenv.jpg") no-repeat;
}

.pressCoBiz {
  background: url("https://d35408cyocioye.cloudfront.net/new2/press/pressbg_cobiz.jpg") no-repeat;
}

.presscoloradoinno {
  background: url("https://joshai.s3.amazonaws.com/new2/press/presscoloradoinno.png") no-repeat;
}

.pressconnecteddesign {
  background: url("https://joshai.s3.amazonaws.com/new2/press/pressconnecteddesign.png") no-repeat;
}

.pressinteractions {
  background: url("https://joshai.s3.amazonaws.com/new2/press/pressinteractions.png") no-repeat;
}

.pressTechnoBuffalo {
  background: url("https://d35408cyocioye.cloudfront.net/new2/press/pressbg_technobuffalo.jpg") no-repeat;
}

.pressinnovatorspeak {
  background: url("https://d35408cyocioye.cloudfront.net/new2/press/pressbg_innovatorspeak.jpg") no-repeat;
}

.presshomeadvisor {
  background: url("https://d35408cyocioye.cloudfront.net/new2/press/pressbg_homeadvisor.jpg") no-repeat;
}

.presslanacion {
  background: url("https://d35408cyocioye.cloudfront.net/new2/press/pressbg_lanacion.jpg") no-repeat;
}

.presstechcrunch {
  background: url("https://d35408cyocioye.cloudfront.net/new2/press/pressbg_techcrunch.jpg") no-repeat;
}

.pressventurebeat {
  background: url("https://d35408cyocioye.cloudfront.net/new2/press/pressbg_venturebeat.jpg") no-repeat;
}

.press9news {
  background: url("https://d35408cyocioye.cloudfront.net/new2/press/pressbg_9news.jpg") no-repeat;
}

.presscw39 {
  background: url("https://d35408cyocioye.cloudfront.net/new2/press/pressbg_cw39.jpg") no-repeat;
}

.pressbuilder {
  background: url("https://d35408cyocioye.cloudfront.net/new2/press/pressbg_builder.jpg") no-repeat;
}

.pressdenverpost {
  background: url("https://d35408cyocioye.cloudfront.net/new2/press/pressbg_denverpost.jpg") no-repeat;
}

.pressnewscenter {
  background: url("https://d35408cyocioye.cloudfront.net/new2/press/pressbg_newscenter.jpg") no-repeat;
}

.pressstrata {
  background: url("https://d35408cyocioye.cloudfront.net/new2/press/pressbg_strata.jpg") no-repeat;
}

.pressuncgenius {
  background: url("https://d35408cyocioye.cloudfront.net/new2/press/pressbg_uncgenius.jpg") no-repeat;
}

.presssmhome {
  background: url("https://d35408cyocioye.cloudfront.net/new2/press/pressbg_smhome.jpg") no-repeat;
}

.pressconnectedhome {
  background: url("https://d35408cyocioye.cloudfront.net/new2/press/pressbg_connectedhome.jpg") no-repeat;
}

.presscmi {
  background: url("https://d35408cyocioye.cloudfront.net/new2/press/pressbg_cmi.jpg") no-repeat;
}

.presslatimes {
  background: url("https://d35408cyocioye.cloudfront.net/new2/press/pressbg_latimes.jpg") no-repeat;
}

.pressonefirefly {
  background: url("https://d35408cyocioye.cloudfront.net/new2/press/pressbg_onefirefly_dup.jpg") no-repeat;
}

.presslodging {
  background: url("https://d35408cyocioye.cloudfront.net/new2/press/pressbg_lodging.jpg") no-repeat;
}

.pressnbcpalmsprings {
  background: url("https://d35408cyocioye.cloudfront.net/new2/press/pressbg_nbcpalmsprings.jpg") no-repeat;
}

.pressmannreport {
  background: url("https://d35408cyocioye.cloudfront.net/new2/press/pressbg_mannreport.jpg") no-repeat;
}

.pressusae {
  background: url("https://d35408cyocioye.cloudfront.net/new2/press/pressbg_usae.jpg") no-repeat;
}

.pressapnews {
  background: url("https://d35408cyocioye.cloudfront.net/new2/press/pressbg_apnews.jpg") no-repeat;
}

.pressyahoofinance {
  background: url("https://d35408cyocioye.cloudfront.net/new2/press/pressbg_yahoofinance.jpg") no-repeat;
}

.pressglobenewswire {
  background: url("https://d35408cyocioye.cloudfront.net/new2/press/pressbg_globenewswire.svg");
}

.pressLinkPhotos {
  background-size: 100% 100%;
  transition: background-size .6s;
}

.pressLinkPhotos:hover {
  background-size: 103% 103%;
}

.pressLinkPhotos a, .pressLinkPhotos a:hover {
  color: #fff;
}

#recsTopSection {
  width: 100%;
  background: url("https://d35408cyocioye.cloudfront.net/legacy/new2/works+with/works-with-page-header-background.jpg") 0 0 / cover no-repeat;
  margin-bottom: 15px;
  padding: 120px 0 80px;
  transition: margin 1s;
}

#recsTitle {
  opacity: 0;
  color: #555;
  letter-spacing: .02em;
  margin: auto auto 20px;
  font-size: 35px;
  font-weight: 300;
  transition: opacity 2s;
}

#recsText {
  color: #666;
  margin: auto;
  font-size: 13px;
  line-height: 1.6;
}

.recsTextTop {
  opacity: 0;
  transition: opacity 2s;
}

.recsButton {
  height: 29px;
  width: 120px;
  color: #fff;
  opacity: 0;
  background: url("https://d35408cyocioye.cloudfront.net/legacy/new2/contact/email-button-four-stack.png") 0 -40px no-repeat;
  margin: 40px auto auto;
  padding-top: 11px;
  font-size: 11px;
  font-weight: 800;
  transition: opacity 1s;
}

.recsButton:hover {
  cursor: pointer;
  background-position: 0 -80px;
}

.recsContent {
  background-color: #f9f9f9;
  border-bottom: 15px solid #fff;
  margin-bottom: 180px;
  display: table;
}

.recsRevealTitle {
  color: #002340;
  font-size: 40px;
  font-weight: 300;
}

.recsRevealBodyText {
  color: #888;
  margin-top: 30px;
  font-size: 14px;
}

.recsDevicesRow {
  height: 250px;
  width: 100%;
  margin-top: 20px;
}

.recsDevicesRowSecond, .recsDevicesRowThird {
  height: 500px;
}

.recsDevicesRow1, .recsDevicesRow2, .recsDevicesRow3, .recsDevicesRow4, .recsDevicesRow5, .recsDevicesRow6, .recsDevicesRow7, .recsDevicesRow8, .recsDevicesRow9 {
  width: 33.3333%;
  height: 50%;
  float: left;
  text-align: center;
}

.recsDeviceRowPhoto {
  width: 130px;
  margin-top: 10px;
}

.recsDeviceRowPhotoSoon {
  opacity: .2;
}

.recsDeviceRowTitle {
  color: #444;
  margin-bottom: 10px;
  font-size: 12px;
}

.recsDeviceRowText {
  color: #888;
  font-size: 14px;
}

#recsThermostats, #recsLights, #recsCameras, #recsMusic, #recsEntertain, #recsSensors, #recsSecurity, #recsOutdoors, #recsSwitches, #recsShades, #recsGarage, #recsMics, #recsStb, #recsMatrix, #recsFans {
  margin-top: 40px;
  margin-bottom: 40px;
  display: none;
}

#recsThermostats a, #recsLights a, #recsCameras a, #recsMusic a, #recsEntertain a, #recsSensors a, #recsSecurity a, #recsOutdoors a, #recsSwitches a, #recsShades a, #recsGarage a, #recsMics a, #recsStb a, #recsMatrix a, #recsFans a {
  color: #333;
  font-size: 13px;
  font-weight: 400;
}

.recsGrid {
  margin-top: 0;
  padding: 0;
  list-style: none;
}

.recsGrid li {
  float: left;
  width: calc(25% - 12px);
  margin-right: 15px;
}

.recsGridLiRight {
  margin-right: 0 !important;
}

.recsGridLiRightSmall {
  margin-right: 15px;
}

.recsContentSquareTop {
  margin-top: 0;
}

.recsContentSquare {
  height: 268px;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 15px;
  margin-right: 15px;
  transition: background-size .5s;
  display: flex;
  overflow: hidden;
}

.recsContentSquareThermostat {
  background: url("https://d35408cyocioye.cloudfront.net/legacy/new2/works+with/works-with-page-thermostat-thumbnail.jpg") 0 0 / 100% 100% no-repeat;
}

.recsContentSquareLights {
  background: url("https://d35408cyocioye.cloudfront.net/legacy/new2/works+with/works-with-page-lights-thumbnail.jpg") 0 0 / 100% 100% no-repeat;
}

.recsContentSquareCameras {
  background: url("https://d35408cyocioye.cloudfront.net/legacy/new2/works+with/works-with-page-cameras-thumbnail.jpg") 0 0 / 100% 100% no-repeat;
}

.recsContentSquareMusic {
  background: url("https://d35408cyocioye.cloudfront.net/legacy/new2/works+with/works-with-page-music-thumbnail.jpg") 0 0 / 100% 100% no-repeat;
}

.recsContentSquareEntertain {
  background: url("https://d35408cyocioye.cloudfront.net/legacy/new2/works+with/works-with-page-entertain-thumbnail.jpg") 0 0 / 100% 100% no-repeat;
}

.recsContentSquareSensors {
  background: url("https://d35408cyocioye.cloudfront.net/legacy/new2/works+with/works-with-page-sensors-thumbnail.jpg") 0 0 / 100% 100% no-repeat;
}

.recsContentSquareSecurity {
  background: url("https://d35408cyocioye.cloudfront.net/legacy/new2/works+with/works-with-page-security-thumbnail.jpg") 0 0 / 100% 100% no-repeat;
}

.recsContentSquareOutdoors {
  background: url("https://d35408cyocioye.cloudfront.net/legacy/new2/works+with/works-with-page-outdoor-thumbnail.jpg") 0 0 / 100% 100% no-repeat;
}

.recsContentSquareSwitches {
  background: url("https://d35408cyocioye.cloudfront.net/legacy/new2/works+with/works-with-page-switches-thumbnail.jpg") 0 0 / 100% 100% no-repeat;
}

.recsContentSquareShades {
  background: url("https://d35408cyocioye.cloudfront.net/legacy/new2/works+with/works-with-page-shades-thumbnail.jpg") 0 0 / 100% 100% no-repeat;
}

.recsContentSquareMics {
  background: url("https://d35408cyocioye.cloudfront.net/new2/devices/mics.jpg") 0 0 / 100% 100% no-repeat;
}

.recsContentSquareStb {
  background: url("https://d35408cyocioye.cloudfront.net/new2/devices/stb.jpg") 0 0 / 100% 100% no-repeat;
}

.recsContentSquareMatrix {
  background: url("https://d35408cyocioye.cloudfront.net/new2/devices/matrix.jpg") 0 0 / 100% 100% no-repeat;
}

.recsContentSquareFans {
  background: url("https://d35408cyocioye.cloudfront.net/new2/devices/fans.jpg") 0 0 / 100% 100% no-repeat;
}

.recsContentSquareGarage {
  background: url("https://d35408cyocioye.cloudfront.net/legacy/new2/works+with/works-with-page-garage-thumbnail.jpg") 0 0 / 100% 100% no-repeat;
}

.recsContentSquareMore {
  height: 267px;
  width: calc(100% - 2px);
  border: 1px solid #ccc;
}

.recsContentSquareThermostat:hover, .recsContentSquareLights:hover, .recsContentSquareCameras:hover, .recsContentSquareMusic:hover, .recsContentSquareEntertain:hover, .recsContentSquareSensors:hover, .recsContentSquareSecurity:hover, .recsContentSquareOutdoors:hover, .recsContentSquareSwitches:hover, .recsContentSquareShades:hover, .recsContentSquareGarage:hover, .recsContentSquareMatrix:hover, .recsContentSquareFans:hover, .recsContentSquareStb:hover, .recsContentSquareMics:hover {
  cursor: pointer;
  background-size: 105% 105%;
}

.recsContentSquareRight {
  margin-right: 0;
}

.recsContentIcon {
  height: 50px;
  width: 50px;
  background-position: 0 0;
}

.recsContentIcon2 {
  width: 45px;
}

.recsContentIconThermostat {
  background: url("https://d35408cyocioye.cloudfront.net/legacy/new2/works+with/works-with-page-thermostat-icon-stacked.png");
}

.recsContentIconLights {
  background: url("https://d35408cyocioye.cloudfront.net/legacy/new2/works+with/works-with-page-lights-icon-stacked.png");
}

.recsContentIconCameras {
  background: url("https://d35408cyocioye.cloudfront.net/legacy/new2/works+with/works-with-page-cameras-icon-stacked.png");
}

.recsContentIconMusic {
  background: url("https://d35408cyocioye.cloudfront.net/legacy/new2/works+with/works-with-page-music-icon-stacked.png");
}

.recsContentIconEntertain {
  background: url("https://d35408cyocioye.cloudfront.net/legacy/new2/works+with/works-with-page-entertain-icon-stacked.png");
}

.recsContentIconSensors {
  background: url("https://d35408cyocioye.cloudfront.net/legacy/new2/works+with/works-with-page-sensors-icon-stacked.png");
}

.recsContentIconSecurity {
  background: url("https://d35408cyocioye.cloudfront.net/legacy/new2/works+with/works-with-page-security-icon-stacked.png");
}

.recsContentIconOutdoors {
  background: url("https://d35408cyocioye.cloudfront.net/legacy/new2/works+with/works-with-page-outdoors-icon-stacked.png");
}

.recsContentIconSwitches {
  background: url("https://d35408cyocioye.cloudfront.net/legacy/new2/works+with/works-with-page-switches-icon-stacked.png");
}

.recsContentIconMics {
  background: url("https://d35408cyocioye.cloudfront.net/new2/devices/mics-icon-stacked.png");
}

.recsContentIconStb {
  background: url("https://d35408cyocioye.cloudfront.net/new2/devices/stb-icon-stacked.png");
}

.recsContentIconMatrix {
  background: url("https://d35408cyocioye.cloudfront.net/new2/devices/matrix-switchers-icon-stacked.png");
}

.recsContentIconFans {
  background: url("https://d35408cyocioye.cloudfront.net/new2/devices/fans-icon-stacked.png");
}

.recsContentIconShades {
  background: url("https://d35408cyocioye.cloudfront.net/legacy/new2/works+with/works-with-page-shades-icon-stacked.png");
}

.recsContentIconGarage {
  background: url("https://d35408cyocioye.cloudfront.net/legacy/new2/works+with/works-with-page-garage-icon-stacked.png");
}

.recsContentSquare:hover > .recsContentIcon {
  background-position: 0 -50px;
}

.recsContentName {
  color: #fff;
  text-transform: uppercase;
  margin-top: 15px;
  font-size: 15px;
  font-weight: 800;
}

.recsContentMore {
  color: #ccc;
  font-size: 15px;
  font-style: italic;
  font-weight: 800;
  line-height: 1.5em;
}

.recsImage {
  width: calc(100% - 100px);
  cursor: pointer;
  padding: 100px 50px 500px;
  font-size: 2em;
  box-shadow: inset 0 0 0 1000px #0003;
}

.recsThermostat {
  background: url("https://d35408cyocioye.cloudfront.net/legacy/products/nest.png") 0 100% / cover no-repeat;
  margin-bottom: 120px;
}

.recsMusic {
  background: url("https://d35408cyocioye.cloudfront.net/legacy/products/sonos.png") 0 100% / cover no-repeat;
  margin-top: 120px;
  margin-bottom: 120px;
}

.recsLights {
  background: url("https://d35408cyocioye.cloudfront.net/legacy/products/lights.png") 0 100% / cover no-repeat;
  margin-top: 120px;
  margin-bottom: 120px;
}

.recsCameras {
  background: url("https://d35408cyocioye.cloudfront.net/legacy/products/nestcam.png") 0 100% / cover no-repeat;
  margin-top: 120px;
  margin-bottom: 120px;
}

.recsDesc {
  color: #333;
}

.recsDivider {
  width: 100%;
  border-top: 1px solid #eee;
  margin-top: 20px;
  margin-bottom: 20px;
}

.recsRevealText {
  color: #3581e6;
  cursor: pointer;
  font-weight: 700;
}

#recsThermostatCollapse, #recsMusicCollapse, #recsLightsCollapse, #recsCamerasCollapse {
  border-top: 1px solid #eee;
  margin-top: 100px;
  display: none;
  position: static;
}

.recsThermHide, .recsMusicHide, .recsLightsHide, .recsCamerasHide {
  display: none;
}

#blogBody {
  width: 100%;
  border-bottom: 15px solid #fff;
  padding: 20px 0 1px;
  font-size: 14px;
  transition: margin 1s;
}

#blogTopSection {
  width: 100%;
  background: url("https://d35408cyocioye.cloudfront.net/new2/blog.jpg") 0 0 / cover no-repeat;
  margin-bottom: 15px;
  padding: 80px 0 60px;
  transition: margin 1s;
}

#blogTitle {
  opacity: 0;
  color: #555;
  letter-spacing: .02em;
  margin: auto auto 20px;
  font-size: 35px;
  font-weight: 300;
  transition: opacity 2s;
}

#blogText {
  color: #666;
  margin: auto;
  font-size: 13px;
  line-height: 1.6;
}

.blogTextTop {
  opacity: 0;
  transition: opacity 2s;
}

.blogButton {
  height: 29px;
  width: 120px;
  color: #fff;
  opacity: 0;
  background: url("https://d35408cyocioye.cloudfront.net/legacy/new2/contact/email-button-four-stack.png") 0 -40px no-repeat;
  margin: 40px auto auto;
  padding-top: 11px;
  font-size: 11px;
  font-weight: 800;
  transition: opacity 1s;
}

.blogButton:hover {
  cursor: pointer;
  background-position: 0 -80px;
}

#blog-loading {
  letter-spacing: .01em;
  margin-top: 150px;
  margin-left: -40px;
  font-size: 20px;
  font-weight: 300;
  position: absolute;
  left: 50%;
}

#buyCont {
  width: 100%;
}

#buyBlock2Title, #buyBlock4Title, #buyBlock7Title, #buyBlock1Title, #buyBlock3Title, #buyBlock8Title, #buyBlock9Title, #buyBlock12Title {
  color: #575755;
  font-size: 40px;
  font-weight: 300;
}

#buyBlock1Title {
  color: #fff;
  padding-bottom: 20px;
  padding-top: 80px !important;
}

#buyBlock3Title {
  margin-top: 100px;
}

.stripe-result-message {
  color: green;
  margin-top: 30px;
  margin-bottom: 45px;
  padding: 0;
  font-size: 22px;
}

.stripe-result-message.stripe-fail {
  color: red;
  margin-top: 30px;
  margin-bottom: 45px;
  padding: 0;
  font-size: 22px;
}

#buyBlock1, #buyBlock1demo {
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -ms-background-size: cover;
  -o-background-size: cover;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  z-index: 1;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-bottom: 15px solid #fff;
  padding-top: 46px;
  position: relative;
  overflow: hidden;
}

#buyBlock1Vid {
  min-width: 100%;
  min-height: 100%;
  width: auto;
  height: auto;
  z-index: -100;
  pointer-events: none;
  vertical-align: top;
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  overflow-y: hidden;
  transform: translateX(-50%)translateY(-50%);
}

#buyBlock1Left {
  height: 100%;
  width: 50%;
  color: #fff;
  background-color: #0006;
  padding-bottom: 40px;
}

.buyList {
  width: 300px;
  text-align: left;
  margin: 30px auto auto;
  font-size: 16px;
  font-style: italic;
  line-height: 2.4;
}

.buyList a {
  color: #3ca4fc;
}

#buyBlock2 {
  width: 100%;
  background-color: #f9f9f9;
  border-bottom: 15px solid #fff;
  padding-top: 40px;
  padding-bottom: 80px;
}

#buyBlock2Cont {
  width: 100%;
  height: 250px;
  margin-top: 40px;
}

.buyBlock2Photos {
  height: 250px;
  width: 22.3%;
  transition: opacity 1s;
  position: absolute;
}

.buyBlock2Photos:hover {
  opacity: .8;
}

.buyBlock2PhotoText {
  width: 100%;
  color: #666;
  margin-top: 260px;
  font-size: 16px;
  font-style: italic;
  position: absolute;
}

.buyBlock2Arrows {
  height: 150px;
  width: 16.5%;
  padding-top: 100px;
  position: absolute;
}

#buyBlockPhoto1 {
  background: url("https://d35408cyocioye.cloudfront.net/legacy/new2/getjosh/get-josh-page-setup-1.jpg") 0 0 / cover no-repeat;
  margin-left: 50px;
}

#buyBlockPhoto2 {
  background: url("https://d35408cyocioye.cloudfront.net/legacy/new2/getjosh/get-josh-page-setup-3.jpg") 0 0 / cover no-repeat;
  margin-left: 38.8%;
}

#buyBlockPhoto3 {
  background: url("https://d35408cyocioye.cloudfront.net/legacy/new2/getjosh/get-josh-page-setup-2.jpg") 0 0 / cover no-repeat;
  margin-left: 73%;
}

#buyBlockArrow1 {
  margin-left: 25%;
}

#buyBlockArrow2 {
  margin-left: 59.2%;
}

#buyBlock3 {
  width: 100%;
  height: 240px;
  background-color: #f9f9f9;
  border-bottom: 15px solid #fff;
}

.buyBlocks {
  height: 240px;
  transition: all 1s;
  position: absolute;
}

.buyBlocks:hover {
  opacity: .9;
}

.buyBlock3BlocksText {
  color: #fff;
  text-transform: uppercase;
  margin-top: 115px;
  font-size: 15px;
  font-weight: 600;
  transition: margin .5s;
}

.buyBlock3BlocksTextRight, .buyBlock3BlocksTextLeft {
  margin-top: 122px;
}

.buyBlock3Icon {
  margin-top: -46px;
  margin-left: -23px;
  position: absolute;
}

.buyBlock3Icon2 {
  margin-top: -95px;
  margin-left: -89px;
  position: absolute;
}

#buyBlock3BottomLeft {
  width: 33%;
  background-color: #5c7385;
  overflow: hidden;
}

#buyBlock3BottomMiddle {
  width: 34%;
  background-color: #42525e;
  margin-left: 33%;
  overflow: hidden;
}

#buyBlock3BottomRight {
  width: 33%;
  background-color: #303c45;
  margin-left: 67%;
  overflow: hidden;
}

.buyBlocks3Left, .buyBlocks3Middle, .buyBlocks3Right {
  text-align: center;
  color: #fff;
  text-transform: initial;
  width: 80%;
  margin-top: 179px;
  margin-left: auto;
  margin-right: auto;
  font-size: 15px;
  line-height: 1.3;
}

.buyBlocks3Middle {
  margin-top: 198px;
}

#buyBlock3BottomLeft:hover > .buyBlock3BlocksText, #buyBlock3BottomMiddle:hover > .buyBlock3BlocksText, #buyBlock3BottomRight:hover > .buyBlock3BlocksText {
  cursor: pointer;
  margin-top: -110px;
}

#buyBlock4 {
  width: 100%;
  padding-top: 25px;
  padding-bottom: 40px;
}

#buyBlock4Text {
  text-align: center;
  color: #9a9a95;
  margin-top: 10px;
  font-size: 15px;
  line-height: 1.3;
}

.buyBlock4BottomStyle a {
  color: #000;
  font-size: 15px;
  font-weight: 800;
}

.buyBlock4BottomStyle a:hover {
  color: #555;
}

#buyBlock4Bottom {
  width: 100%;
  height: 200px;
  margin-top: 20px;
}

.buyBlock4BottomStyle {
  color: #666;
  width: 33%;
  margin-top: 60px;
  font-size: 16px;
  font-style: italic;
  position: absolute;
}

#buyBlock4BottomMiddle {
  width: 34%;
  margin-left: 33%;
}

#buyBlock4BottomRight {
  margin-left: 67%;
}

#buyBlock5 {
  width: 100%;
  height: 600px;
  background: url("https://d35408cyocioye.cloudfront.net/legacy/new2/getjosh/get-josh-footer-background-image2.jpg") 0 0 / cover no-repeat;
  border-bottom: 10px solid #fff;
}

#buyBlock6 {
  width: 100%;
  height: 540px;
  background: url("https://d35408cyocioye.cloudfront.net/new2/devices.jpg") bottom / cover no-repeat;
  border-bottom: 15px solid #fff;
  padding-bottom: 25px;
}

#buyBlock6 ul {
  color: #575755;
  text-align: left;
  margin-left: 75%;
  padding-top: 100px;
  font-size: 15px;
  line-height: 2;
}

#buyBlock7 {
  width: 100%;
  padding-top: 20px;
  padding-bottom: 20px;
}

#buyBlock8 {
  width: 100%;
  height: 540px;
  background: url("https://d35408cyocioye.cloudfront.net/legacy/new2/getjosh/josh-pro-background-image.jpg") 0 / cover no-repeat;
  border-bottom: 10px solid #fff;
  padding-bottom: 25px;
}

#buyBlock8Title {
  color: #fff;
  text-align: left;
  padding-top: 120px;
  padding-left: 200px;
}

#buyBlock8 ul {
  color: #fff;
  text-align: left;
  margin-left: 120px;
  font-size: 15px;
  line-height: 2.7;
}

#buyBlock9 {
  width: 100%;
  height: 200px;
  background: url("https://d35408cyocioye.cloudfront.net/legacy/new2/getjosh/josh-micro-background-image.jpg") 0 / cover no-repeat;
  border-bottom: 10px solid #fff;
  padding-bottom: 25px;
}

#buyBlock9Title {
  text-align: left;
  padding-top: 95px;
  padding-left: 150px;
  position: absolute;
  right: 175px;
}

#buyBlock9Circle {
  width: 175px;
  height: 105px;
  text-align: center;
  color: #fff;
  text-transform: uppercase;
  background-color: #00000080;
  border-radius: 200px;
  margin-top: 25px;
  margin-left: 180px;
  padding-top: 70px;
  font-size: 16px;
  font-weight: 700;
  transition: all 1s;
  position: absolute;
}

#buyBlock9Circle:hover {
  background-color: #0000004d;
}

.buyBlock9CircleLink {
  text-transform: initial;
  letter-spacing: .07em;
  font-size: 10px;
}

#block10 {
  height: 40px;
  width: 400px;
  text-transform: uppercase;
  margin: 10px auto 30px;
  font-size: 15px;
  font-weight: bold;
}

#block10left {
  float: left;
  width: 200px;
  color: #fff;
  height: 30px;
  background: url("https://d35408cyocioye.cloudfront.net/legacy/new2/getjosh/hex-button-toggle-left-stacked.png") no-repeat;
  padding-top: 10px;
  transition: color .5s;
}

#block10right {
  float: right;
  width: 200px;
  color: #fff;
  height: 30px;
  background: url("https://d35408cyocioye.cloudfront.net/legacy/new2/getjosh/hex-button-toggle-right-stacked.png") no-repeat;
  padding-top: 10px;
  transition: color 1s;
}

.block10active {
  color: #aaaaa6 !important;
  background-position: 0 -40px !important;
}

.block10active:hover {
  color: #008cff !important;
  cursor: pointer !important;
}

#buyBlock11 {
  height: 640px;
  width: 100%;
  background-color: #f9f9f9;
  margin-bottom: 15px;
  display: none;
}

#buyBlock11Top, #buyBlock11Bottom {
  height: 320px;
  width: 100%;
  margin: 0;
  padding: 0;
}

.buyBlock11TopIcon {
  height: 50px;
  width: 50px;
  margin: 75px auto 20px;
}

.buyBlock11TopIconTitle {
  text-transform: uppercase;
  text-align: center;
  color: #575755;
  font-size: 15px;
  font-weight: 500;
  line-height: 1;
}

.buyBlock11TopIconText {
  text-align: center;
  color: #575755;
  padding: 20px;
  font-size: 15px;
  line-height: 1.3;
}

#buyBlock11TopLeft, #buyBlock11TopMiddle, #buyBlock11TopRight, #buyBlock11BottomLeft, #buyBlock11BottomMiddle, #buyBlock11BottomRight {
  height: 100%;
  width: 33.3%;
  float: left;
}

.buyBlock11Horizontal {
  width: 100%;
  position: absolute;
  left: 0;
}

.buyBlock11Vertical1 {
  height: 640px;
  position: absolute;
  left: 33.3%;
}

.buyBlock11Vertical2 {
  height: 640px;
  position: absolute;
  left: 66.6%;
}

#buyBlock12 {
  width: 100%;
  padding-top: 25px;
  padding-bottom: 40px;
}

#buyBlock12Text {
  text-align: center;
  color: #9a9a95;
  margin-top: 10px;
  font-size: 15px;
  line-height: 1.3;
}

#buyBlock13 {
  height: 240px;
  width: 100%;
}

.buyBlock13cont {
  height: 100%;
  width: calc(50% - 2px);
  float: left;
  overflow: hidden;
}

.buyBlock13contLeft {
  margin-right: 4px;
}

#buyBlock13left {
  width: 100%;
  height: 100%;
  background-color: #0000;
  background-image: linear-gradient(#0003, #0003), url("https://d35408cyocioye.cloudfront.net/legacy/new2/getjosh/tap-to-control-graphic.jpg");
  background-position: 0 0, 0 0;
  background-repeat: repeat, no-repeat;
  background-size: cover;
  background-attachment: scroll, scroll;
  background-origin: padding-box, padding-box;
  background-clip: border-box, border-box;
  transition: all .5s;
}

#buyBlock13left:hover {
  transform: scale(1.05);
}

#buyBlock13right {
  width: 100%;
  height: 100%;
  background: url("https://d35408cyocioye.cloudfront.net/legacy/new2/getjosh/speak-to-control-graphic.jpg") 0 0 / cover no-repeat;
  transition: all .5s;
}

#buyBlock13right:hover {
  transform: scale(1.05);
}

#buyBlock14 {
  width: 100%;
  height: 70px;
  text-align: center;
  margin-top: 40px;
  margin-bottom: 40px;
}

#buyBlock14left, #buyBlock14right {
  width: 50%;
  float: left;
}

.buyBlock14leftTitle {
  text-align: center;
  color: #575755;
  text-transform: uppercase;
  margin-bottom: 20px;
  font-size: 15px;
  font-weight: 600;
  line-height: 1;
}

.buyBlock14leftText {
  text-align: center;
  color: #575755;
  font-size: 15px;
  line-height: 1.3;
}

video#bgvid {
  min-width: 100%;
  height: 100%;
  width: auto;
  height: auto;
  z-index: -100;
  background-size: cover;
  border-bottom: 15px solid #fff;
  margin-bottom: 180px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%)translateY(-50%);
}

#buyBody {
  width: 100%;
  color: #303131;
  padding: 40px 0 0;
  font-size: 14px;
  transition: margin 1s;
}

.buyHeader {
  width: calc(100% - 200px);
  height: 200px;
  color: #fff;
  text-align: center;
  background: url("https://d35408cyocioye.cloudfront.net/legacy/preorderhouse.jpg") 0 / cover no-repeat;
  padding: 100px;
  font-size: 30px;
  box-shadow: inset 0 0 0 1000px #0006;
}

.buyJoshHead {
  opacity: 0;
  width: 200px;
  margin-top: 60px;
  transition: all 4.5s;
}

.buyCont {
  width: 100%;
  text-align: left;
  min-height: 350px;
  margin-bottom: 100px;
}

.buyLeft {
  width: 50%;
  color: #f9f9f9;
  text-align: left;
  padding: 15px;
}

.buyLeftTransBlack {
  height: 100%;
  width: 50%;
  z-index: -1;
  background-color: #0000004d;
  margin-top: -1px;
  position: absolute;
}

.buyRight {
  float: right;
  width: calc(40% - 40px);
  color: #fff;
  text-align: center;
  padding: 10px 10px 0;
  padding: 0px 30px 0dppx 30dppx;
  background-color: #303131;
  margin-right: 15px;
}

.buySmallText {
  color: #eee;
  border-bottom: 1px solid #555;
  margin-bottom: 20px;
  padding-bottom: 20px;
  font-size: 12px;
}

.buySaleBlockCont {
  color: #fff;
  width: 200px;
  text-align: left;
  height: 200px;
  opacity: 0;
  background-color: #008cffe6;
  margin-top: 120px;
  margin-left: 65%;
  padding: 20px;
  transition: all .5s;
  position: absolute;
  transform: rotate(45deg);
}

.buySaleBlock {
  color: #fff;
  width: 200px;
  text-align: left;
  height: 200px;
  opacity: 0;
  margin-top: 120px;
  margin-left: 65%;
  padding: 20px;
  transition: all .5s;
  position: absolute;
}

.buySaleBlockLogo {
  width: 68px;
  opacity: 0;
  margin-bottom: 5px;
  margin-left: 65px;
  transition: all .5s;
}

.buySaleBlockNumber {
  text-align: center;
  font-size: 50px;
  font-weight: 300;
}

.buySaleBlockSmallText, .buySaleBlockSmallText a {
  text-align: center;
  margin-top: 30px;
  font-size: 11px;
}

.buySaleBlockOrder, .buySaleBlockContact {
  float: right;
  width: 200px;
  height: 31px;
  color: #008cff;
  text-align: center;
  text-transform: uppercase;
  background: url("https://d35408cyocioye.cloudfront.net/legacy/new2/getjosh/get-josh-page-reserve-button-stacked.png") no-repeat;
  margin-top: 10px;
  margin-bottom: 10px;
  padding-top: 9px;
  font-weight: 700;
}

.buySaleBlockOrder:hover, .buySaleBlockContact:hover {
  cursor: pointer;
  color: #fff;
  background-position: 0 -40px;
}

.buyReserveText {
  text-align: center;
  margin-top: 50px;
  margin-bottom: 20px;
  font-size: 16px;
  font-weight: 800;
}

.buyLeft a {
  color: #fff;
  font-size: 12px;
}

#buyBlockHidden {
  display: none;
}

.customCompany input {
  width: 180px;
  height: 20px;
  border: none;
  border-radius: 15px;
  margin-left: 5px;
  padding: 5px;
}

.demoUnitSaleBlockOrder {
  float: right;
  width: 200px;
  height: 31px;
  color: #008cff;
  text-align: center;
  text-transform: uppercase;
  opacity: .9;
  background: url("https://d35408cyocioye.cloudfront.net/legacy/new2/getjosh/get-josh-page-reserve-button-stacked.png") no-repeat;
  margin-top: -10px;
  margin-bottom: 5px;
  padding-top: 9px;
  font-weight: 700;
}

.demoUnitSaleBlockOrder:hover {
  cursor: pointer;
  opacity: 1;
}

.demoUnitDeviceList {
  text-align: left;
  color: #9a9a95;
  columns: 4;
  -webkit-columns: 4;
  -moz-columns: 4;
  margin: 20px auto auto;
  font-size: 15px;
  line-height: 2em;
  list-style-type: none;
}

.demoUnitDeviceList li:before {
  content: "• ";
}

.demoUnitSaleButton {
  cursor: pointer;
  color: #fff;
  opacity: .9;
  float: none;
  background-position: 0 -40px;
  margin: 20px auto;
}

.demoUnitSaleButton:hover {
  opacity: 1;
}

.buyBlocks3LeftDemoUnit {
  margin-top: 173px;
}

.buyBlocks3MiddleDemoUnit {
  margin-top: 182px;
}

.buyBlocks3RightDemoUnit {
  margin-top: 165px;
}

#faqsBody {
  width: 100%;
  color: #303131;
  margin-bottom: 180px;
  padding: 20px 0 1px;
  font-size: 18px;
}

#faqsTopSection {
  width: 100%;
  background: url("https://d35408cyocioye.cloudfront.net/legacy/new2/faq-page-header-background.jpg") 0 0 / cover no-repeat;
  margin-bottom: 15px;
  padding: 120px 0 80px;
  transition: margin 1s;
}

#faqsTitle {
  opacity: 0;
  color: #555;
  letter-spacing: .02em;
  margin: auto auto 20px;
  font-size: 35px;
  font-weight: 300;
  transition: opacity 2s;
}

#faqsText {
  color: #666;
  margin: auto;
  font-size: 13px;
  line-height: 1.6;
}

.faqsTextTop {
  opacity: 0;
  transition: opacity 2s;
}

.faqsButton {
  height: 29px;
  width: 120px;
  color: #fff;
  opacity: 0;
  background: url("https://d35408cyocioye.cloudfront.net/legacy/new2/contact/email-button-four-stack.png") 0 -40px no-repeat;
  margin: 40px auto auto;
  padding-top: 11px;
  font-size: 11px;
  font-weight: 800;
  transition: opacity 1s;
}

.faqsButton:hover {
  cursor: pointer;
  background-position: 0 -80px;
}

.faqsContent {
  width: 100%;
}

.faqsContentLeft {
  width: calc(50% - 7.5px);
  min-height: 200px;
  height: auto;
  margin-bottom: 30px;
}

.faqsContentRight {
  width: calc(50% - 7.5px);
  margin-left: calc(50% + 7.5px);
  position: absolute;
}

.faqsContentBox {
  width: calc(100% - 50px);
  background-color: #f9f9f9;
  margin-bottom: 15px;
  padding: 25px;
  transition: all .5s;
}

.faqsContentBox:hover {
  background-color: #6388b9;
}

.faqsContentBoxTitle {
  text-transform: uppercase;
  color: #555;
  margin-bottom: 15px;
  font-size: 16px;
  font-weight: 800;
}

.faqsContentBoxText {
  text-align: left;
  color: #888;
  font-size: 14px;
  line-height: 1.5em;
}

.faqsContentBoxText a {
  color: #3581e6;
  font-size: 13px;
  transition: all;
}

.faqsContentBox:hover > .faqsContentBoxTitle, .faqsContentBox:hover > .faqsContentBoxText a {
  color: #fff;
}

.faqsContentBox:hover > .faqsContentBoxText {
  color: #c7ddfa;
}

#contactTopSection {
  width: 100%;
  background: url("https://d35408cyocioye.cloudfront.net/legacy/new2/contact/contact-page-header-background.jpg") 0 0 / cover no-repeat;
  padding: 120px 0 80px;
  transition: margin 1s;
}

#contactTitle {
  opacity: 0;
  color: #555;
  letter-spacing: .02em;
  margin: auto auto 20px;
  font-size: 35px;
  font-weight: 300;
  transition: opacity 2s;
}

#contactText {
  color: #666;
  margin: auto;
  font-size: 13px;
  line-height: 1.6;
}

.contactTextTop {
  opacity: 0;
  transition: opacity 2s;
}

.contactButton {
  height: 29px;
  width: 120px;
  color: #fff;
  opacity: 0;
  background: url("https://d35408cyocioye.cloudfront.net/legacy/new2/contact/email-button-four-stack.png") 0 -40px no-repeat;
  margin: 40px auto auto;
  padding-top: 11px;
  font-size: 11px;
  font-weight: 800;
  transition: opacity 1s;
}

.contactButton:hover {
  cursor: pointer;
  background-position: 0 -80px;
}

.contactTopLeft {
  height: 50%;
  width: 50%;
  background-color: red;
}

.contactSection {
  height: 530px;
  margin-bottom: 180px;
  padding: 15px 0 0;
}

.contactTopLeft {
  float: left;
  width: calc(50% - 7.5px);
  background: url("https://d35408cyocioye.cloudfront.net/legacy/new2/contact/contact-page-jobs-section-background.jpg") 0 0 / 100% 100% no-repeat;
  transition: all .5s;
}

.contactTopLeft:hover {
  background: url("https://d35408cyocioye.cloudfront.net/legacy/new2/contact/contact-page-jobs-section-background.jpg") 0 0 / 105% 105% no-repeat;
}

.contactTopRight {
  float: right;
  width: calc(50% - 7.5px);
  background: url("https://d35408cyocioye.cloudfront.net/legacy/new2/contact/contact-page-press-section-background.jpg") 0 0 / 100% 100% no-repeat;
  transition: all .5s;
}

.contactTopRight:hover {
  background: url("https://d35408cyocioye.cloudfront.net/legacy/new2/contact/contact-page-press-section-background.jpg") 0 0 / 105% 105% no-repeat;
}

.contactBottomLeft {
  float: left;
  width: calc(50% - 7.5px);
  background: url("https://d35408cyocioye.cloudfront.net/legacy/new2/contact/contact-page-support-section-background.jpg") 0 0 / 100% 100% no-repeat;
  margin-top: 15px;
  transition: all .5s;
}

.contactBottomLeft:hover {
  background: url("https://d35408cyocioye.cloudfront.net/legacy/new2/contact/contact-page-support-section-background.jpg") 0 0 / 105% 105% no-repeat;
}

.contactBottomRight {
  float: right;
  width: calc(50% - 7.5px);
  background: url("https://d35408cyocioye.cloudfront.net/legacy/new2/contact/contact-page-sales-section-background.jpg") 0 0 / 100% 100% no-repeat;
  margin-top: 15px;
  transition: all .5s;
}

.contactBottomRight:hover {
  background: url("https://d35408cyocioye.cloudfront.net/legacy/new2/contact/contact-page-sales-section-background.jpg") 0 0 / 105% 105% no-repeat;
}

.contactSquaresText {
  height: 180px;
  color: #fff;
  padding-top: 70px;
  font-size: 40px;
  font-weight: 100;
}

.contactSquaresButton {
  height: 28px;
  width: 120px;
  color: #fff;
  background: url("https://d35408cyocioye.cloudfront.net/legacy/new2/contact/email-button-four-stack.png") no-repeat;
  margin: 10px auto auto;
  padding-top: 12px;
  font-size: 11px;
  font-weight: 800;
}

.contactSquaresButton:hover {
  cursor: pointer;
  color: #555;
  background-position: 0 -120px;
}

.contactSquaresButton a {
  font-size: 11px;
  transition: opacity 10ms;
}

.contactSquaresButton:hover a {
  color: #555;
}

#guideTopSection {
  width: 100%;
  background: url("https://d35408cyocioye.cloudfront.net/legacy/guide/guide-page-header-background.jpg") 0 0 / cover no-repeat;
  padding: 120px 0 80px;
  transition: margin 1s;
}

#guideTitle {
  opacity: 0;
  color: #555;
  letter-spacing: .02em;
  margin: auto auto 20px;
  font-size: 35px;
  font-weight: 300;
  transition: opacity 2s;
}

#guideText {
  color: #666;
  margin: auto;
  font-size: 13px;
  line-height: 1.6;
}

.guideTextTop {
  opacity: 0;
  transition: opacity 2s;
}

.guideTextTop a, .guideTextBottom a {
  color: #008cff;
  font-size: 13px;
}

.guideTextTop a:hover, .guideTextBottom a:hover {
  color: #008cff;
  cursor: pointer;
  text-decoration: underline;
}

.guideListLink a {
  color: #0069bf;
  font-size: 18px;
  font-weight: 500;
}

.guideListLink a:hover {
  color: #008cff;
}

.guideSection {
  background-color: #fff;
  margin-bottom: 180px;
  padding: 30px 0;
}

.guideListTitle {
  text-align: left;
  color: #0069bf;
  margin-left: calc(50% - 90px);
  font-size: 20px;
  font-weight: 300;
  line-height: 3.5em;
  transition: color 1s;
}

.guideListTitle:hover {
  cursor: pointer;
  color: #008cff;
}

.guideListIcon {
  width: 40px;
  margin-top: 15px;
  margin-left: -50px;
  position: absolute;
}

.guideListTitle:hover > .guideListIcon {
  width: 38px;
  margin-top: 17px;
  margin-left: -48px;
  position: absolute;
}

.guideList1, .guideList2, .guideList3, .guideList4, .guideList5, .guideList6, .guideList7, .guideList8, .guideList9 {
  max-width: 500px;
  text-align: left;
  border-top: 1px solid #eee;
  border-bottom: 1px solid #eee;
  margin: auto;
  padding: 15px;
  display: none;
}

.guideListClose {
  color: #0069bf;
  text-transform: uppercase;
  text-align: center;
  margin-top: 20px;
  font-size: 11px;
  transition: color 1s;
}

.guideListClose:hover {
  cursor: pointer;
  color: #008cff;
}

.guideList2Item {
  margin-top: 30px;
  margin-bottom: 15px;
}

.guideList2Note {
  color: #b33427;
  margin-bottom: 15px;
  font-size: 14px;
  font-style: italic;
}

.guideList2Note a {
  color: #b33427;
  font-size: 14px;
  font-style: italic;
}

.guideList2Note a:hover {
  color: #e74c3c;
}

.guideList2ItemImage {
  opacity: .9;
  border-bottom: 1px solid #eee;
  padding-bottom: 15px;
  transition: all 1s;
}

.guideList2ItemImage:hover {
  opacity: 1;
  cursor: pointer;
}

.guideCommandsList, .guideDeviceList, .guideAdvancedList {
  list-style-type: circle;
}

.guideCommandsList li {
  font-style: italic;
}

.guideList4Icon {
  width: 30px;
  vertical-align: middle;
  margin-top: -4px;
  margin-right: 10px;
}

.guideAdvancedList1, .guideAdvancedList2, .guideAdvancedList3, .guideAdvancedList4, .guideAdvancedList5, .guideAdvancedList6 {
  border-top: 1px solid #eee;
  border-bottom: 1px solid #eee;
  margin-top: 15px;
  margin-bottom: 15px;
  padding-top: 15px;
  padding-bottom: 15px;
  display: none;
}

.guideAdvancedList li {
  color: #0069bf;
  font-weight: 300;
  transition: color 1s;
}

.guideAdvancedList li:hover {
  cursor: pointer;
  color: #008cff;
}

#guideLocalizationList li, #guideLocalizationList2 li {
  color: #000;
  cursor: auto;
  margin-bottom: 10px;
  font-weight: 500;
}

#guideLocalizationList2 {
  margin-top: 10px;
  margin-bottom: 10px;
  list-style-type: lower-latin;
}

#packagingTopSection {
  width: 100%;
  background: url("https://d35408cyocioye.cloudfront.net/legacy/packaging/packaging-page-header-background.jpg") 0 0 / cover no-repeat;
  padding: 120px 0 80px;
  transition: margin 1s;
}

#packagingTitle {
  opacity: 0;
  color: #555;
  letter-spacing: .02em;
  margin: auto auto 20px;
  font-size: 35px;
  font-weight: 300;
  transition: opacity 2s;
}

#packagingText {
  color: #666;
  margin: auto;
  font-size: 13px;
  line-height: 1.6;
}

.packagingTextTop {
  opacity: 0;
  transition: opacity 2s;
}

.packagingTextTop a, .packagingTextBottom a {
  color: #88aac4;
  color: #008cff;
  font-size: 13px;
}

.packagingTextTop a:hover, .packagingTextBottom a:hover {
  color: #008cff;
  cursor: pointer;
  text-decoration: underline;
}

.packagingSection {
  height: 530px;
  background-color: #fff;
  margin-bottom: 180px;
  padding: 15px 0 0;
}

.packagingTextBottom {
  color: #666;
  opacity: 0;
  margin: auto auto 177px;
  padding-bottom: 25px;
  font-size: 13px;
  transition: opacity 2s;
  position: static;
}

.docRightCont {
  text-align: left;
  background-color: #f9f9f9;
  padding: 40px 50px 0;
}

#manifesto-cont {
  margin-bottom: 180px;
}

.sectionHeaders {
  color: #00467f;
  border-top: 1px solid #ccc;
  margin-top: 50px;
  padding-top: 50px;
  font-size: 1.17em;
  font-style: italic;
  font-weight: bold;
}

.decagon {
  width: 40%;
  margin: auto;
  display: block;
}

.sectionHeader {
  color: #00467f;
  padding-top: 40px;
}

.divider {
  height: 50px;
  width: 100%;
  border-top: 1px solid #aaa;
  margin-top: 50px;
}

.manifestoParagraph {
  letter-spacing: 0;
  width: 100%;
  margin: 20px auto auto;
  font-size: 18px;
  font-weight: 500;
}

.integratorPageSmallHeaders {
  text-align: center;
  color: #575755;
  text-transform: uppercase;
  letter-spacing: .02em;
  font-family: Lato, sans-serif;
  font-size: 15px;
  font-weight: 600;
  line-height: 1;
}

#a {
  width: 100%;
  height: 560px;
  background-color: #0000;
  background-image: linear-gradient(#0006, #0006), url("https://d35408cyocioye.cloudfront.net/new2/integrators/video.jpg");
  background-position: 0 0, 0 0;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: scroll, scroll;
  background-origin: padding-box, padding-box;
  background-clip: border-box, border-box;
  padding-top: 46px;
  position: relative;
}

#integratorBlock1 {
  width: 100%;
  height: 560px;
  background-color: #0000;
  background-image: linear-gradient(#0000001a, #0000001a), url("https://d35408cyocioye.cloudfront.net/new2/integrators/video.jpg");
  background-position: 0 0, 0 0;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: scroll, scroll;
  background-origin: padding-box, padding-box;
  background-clip: border-box, border-box;
  padding-top: 46px;
  position: relative;
}

#integratorBlock1:hover {
  cursor: pointer;
}

.integratorTopPlayButton {
  width: 70px;
  height: 50px;
  background: url("https://d35408cyocioye.cloudfront.net/new2/integrators/play.png") 0 0 / 71px 101px;
  margin: 255px auto auto;
}

.integratorTopPlayButton:hover, #integratorBlock1:hover > .integratorTopPlayButton {
  cursor: pointer;
  background-position: 0 51px;
}

.integratorTopText {
  color: #fff;
  margin-top: 50px;
  font-family: Lato, sans-serif;
  font-size: 40px;
  font-weight: 300;
}

.integratorTopTextSmall {
  color: #fff;
  margin-top: 20px;
  font-family: Lato, sans-serif;
  font-size: 16px;
  font-weight: 500;
}

#integratorReferrals {
  height: 360px;
  width: 100%;
  margin-top: 30px;
}

#integratorReferralsTop, #integratorReferralsBottom {
  height: 180px;
  width: 100%;
}

.integratorReferralsHorizontalLine {
  margin-top: -17px;
  position: absolute;
}

.integratorReferralsVerticalLine {
  margin-left: 50%;
  position: absolute;
}

.integratorReferralQuad {
  width: calc(50% - 80px);
  height: calc(100% - 35px);
  float: left;
  padding-top: 35px;
  padding-left: 40px;
  padding-right: 40px;
  font-size: 14px;
  font-style: italic;
  line-height: 1.8em;
  overflow: hidden;
}

.integratorReferralQuadRVC {
  height: calc(100% - 25px);
  padding-top: 25px;
}

.integratorReferralQuadApply {
  font-style: normal;
}

.integratorReferralButton {
  width: 201px;
  height: 32px;
  color: #fff;
  text-align: center;
  text-transform: uppercase;
  opacity: 1;
  background: url("https://d35408cyocioye.cloudfront.net/new2/integrators/applybutton.png") no-repeat;
  margin: auto;
  padding-top: 8px;
  font-weight: 700;
}

.integratorReferralButton:hover {
  opacity: .9;
  cursor: pointer;
}

.integratorReferralQuadSmallText {
  margin-top: 5px;
  font-size: 15px;
}

#integratorBlock2 {
  margin-top: 20px;
}

.integratorBlock2Title {
  color: #575755;
  font-size: 40px;
  font-weight: 300;
}

.integratorBlock9Title {
  color: #575755;
  margin-bottom: 30px;
  font-size: 40px;
  font-weight: 300;
}

#integratorBlock3 {
  width: 100%;
  height: 560px;
  background: url("https://d35408cyocioye.cloudfront.net/new2/integrators/packaging_micro.jpg") 0 0 / cover no-repeat;
  border-bottom: 15px solid #fff;
  margin-top: 40px;
  padding-top: 46px;
  position: relative;
  overflow: hidden;
}

#integratorBlock4 {
  width: 100%;
  height: 200px;
  margin-top: 20px;
  margin-bottom: 20px;
}

.integratorBlock4LeftRight {
  width: calc(50% - 140px);
  height: 100%;
  float: left;
  padding-left: 70px;
  padding-right: 70px;
  overflow: hidden;
}

.integratorBlock4LeftRight a, #integratorBlock12 a {
  color: #008cff;
  font-size: 14px;
  text-decoration: none;
}

.integratorBlock4LeftRight a:hover, #integratorBlock12 a:hover {
  color: #0069bf;
}

.integratorBoxText {
  margin-top: 20px;
  font-size: 14px;
  line-height: 1.8em;
}

.integratorBoxText1 {
  margin-top: 10px;
  font-size: 15px;
  font-weight: 800;
}

.integratorBoxText2 {
  color: #aaaaa6;
  margin-top: 5px;
  font-size: 15px;
  font-weight: 800;
}

#integratorBlock7 {
  width: 100%;
  padding-top: 50px;
}

#integratorBlock11 {
  height: 560px;
  width: 100%;
  margin-bottom: 15px;
}

#integratorBlock11Top {
  height: 280px;
  width: 100%;
  margin: 0;
  padding: 0;
}

.integratorBlock11TopIconTitle {
  text-transform: uppercase;
  text-align: center;
  color: #575755;
  font-size: 15px;
  font-weight: 800;
  line-height: 1;
}

.integratorBlock11TopIconText {
  text-align: center;
  color: #575755;
  padding: 20px;
  font-size: 15px;
  line-height: 1.8;
}

.integratorBlock11IconAsset {
  width: 100%;
  transition: all .5s;
}

.integratorBlock11IconAsset:hover {
  width: 95%;
}

.integratorBlock11IconAsset:active {
  width: 105%;
}

.integratorBlock11Vertical1 {
  height: 560px;
  position: absolute;
  left: 33.3%;
}

.integratorBlock11Vertical2 {
  height: 560px;
  position: absolute;
  left: 66.6%;
}

#integratorBlock11Bottom {
  height: 280px;
  width: 100%;
  margin: 0;
  padding: 0;
}

#integratorBlock13 {
  height: 240px;
  width: 100%;
}

.integratorBlock13cont {
  height: 100%;
  width: 50%;
  float: left;
  overflow: hidden;
}

#integratorBlock13left {
  width: 100%;
  height: 100%;
  background: url("https://d35408cyocioye.cloudfront.net/new2/integrators/plugandplay_micro.jpg") 0 / cover no-repeat;
}

#integratorBlock13right {
  width: 100%;
  height: 100%;
  background: url("https://d35408cyocioye.cloudfront.net/new2/integrators/noprogramming.jpg") 0 / cover no-repeat;
}

.integratorList li {
  width: 300px;
  text-align: left;
  margin: auto auto 20px;
}

.integratorDeviceList {
  text-align: left;
  color: #575755;
  columns: 4;
  -webkit-columns: 4;
  -moz-columns: 4;
  margin: 30px auto auto;
  font-size: 15px;
  line-height: 2.5em;
  list-style-type: none;
}

.integratorDeviceList li:before {
  content: "• ";
}

#integratorBlock12 {
  margin-top: 20px;
  margin-bottom: 20px;
}

#integratorBlock12Plans {
  width: 100%;
}

.integratorBlock12LeftRight {
  width: calc(50% - 10px);
  float: left;
  text-align: center;
  color: #575755;
  background-color: #f9f9f9;
  margin-bottom: 40px;
  font-size: 15px;
  line-height: 1.3;
  overflow: hidden;
}

.integratorBlock12Title {
  color: #88aac4;
  margin-top: 20px;
}

#jobsTopSection {
  color: #fff;
  width: 100%;
  background: url("https://d35408cyocioye.cloudfront.net/legacy/jobs-dark-background.jpg") 0 0 / cover no-repeat;
  padding: 90px 0 50px;
  transition: margin 1s;
}

#jobsBody {
  width: 100%;
  transition: margin 1s;
}

#jobsTitle {
  opacity: 0;
  color: #fff;
  letter-spacing: .02em;
  margin: auto auto 20px;
  font-size: 35px;
  font-weight: 300;
  transition: opacity 2s;
}

#jobsText {
  color: #f9f9f9;
  max-width: 560px;
  margin: auto;
  padding: 20px;
  font-size: 15px;
  line-height: 1.33;
}

.jobsButtonCont {
  height: 29px;
  width: 120px;
  margin: 40px auto auto;
}

.jobsButton {
  height: 29px;
  width: 120px;
  color: #fff;
  opacity: 0;
  background: url("https://d35408cyocioye.cloudfront.net/legacy/new2/contact/email-button-four-stack.png") no-repeat;
  margin: auto;
  padding-top: 11px;
  font-size: 11px;
  font-weight: 800;
  transition: opacity 1s;
}

.jobsButton a {
  text-transform: uppercase;
  font-size: 11px;
}

.jobsButton:hover {
  cursor: pointer;
  background-position: 0 -40px;
}

.jobsH1 {
  text-align: center;
  color: #575755;
  font-size: 40px;
  font-weight: 300;
}

.jobsH2 {
  letter-spacing: normal;
  text-align: center;
  color: #9a9a95;
  margin-top: 10px;
  font-size: 15px;
  font-style: normal;
  font-weight: normal;
  font-stretch: normal;
  line-height: 1.33;
}

#jobsOpening {
  max-width: 560px;
  margin: auto;
  padding: 40px;
}

#jobsOpening a {
  color: #88aac4;
  font-size: 18px;
  text-decoration: none;
}

#jobsOpening a:hover {
  color: #008cff;
}

#jobsLocation {
  height: 600px;
  width: 100%;
  text-align: center;
  color: #9a9a95;
  background-color: #f7f7f7;
  font-size: 15px;
}

.jobsLocTop {
  height: 300px;
  width: 100%;
}

.jobsLocationTitle {
  color: #575755;
  text-transform: uppercase;
  margin-bottom: 20px;
  font-weight: bold;
}

.jobsLocationBody {
  text-align: left;
}

.jobsLocTopLeft {
  float: left;
  width: calc(50% - 120px);
  height: calc(100% - 120px);
  padding: 60px;
}

.jobsLocTopRight {
  width: 50%;
  float: right;
  height: 100%;
  background: url("https://d35408cyocioye.cloudfront.net/legacy/colorado.jpg") 0 0 / cover no-repeat;
  transition: all .5s;
}

.jobsLocBottom {
  height: 300px;
  width: 100%;
}

.jobsLocBottomLeft {
  width: 50%;
  float: left;
  height: 100%;
  background: url("https://d35408cyocioye.cloudfront.net/legacy/california.jpg") 0 0 / cover no-repeat;
  transition: all .5s;
}

.jobsLocBottomRight {
  float: right;
  height: calc(100% - 140px);
  width: calc(50% - 140px);
  padding: 70px;
}

.jobsLocTopRight:hover, .jobsLocBottomLeft:hover {
  filter: brightness(80%);
}

#jobsPhotosInfo {
  height: 600px;
  width: 100%;
}

#jobsPhotosInfoLeft {
  width: 50%;
  height: 100%;
  float: left;
}

.jobsPhotosLeftRow1, .jobsPhotosLeftRow2, .jobsPhotosLeftRow3 {
  height: 200px;
  width: 100%;
}

.jobsPhotosLeftRow1Left {
  height: 100%;
  width: calc(50% - 10px);
  float: left;
  background: url("https://d35408cyocioye.cloudfront.net/legacy/job-photos-1.jpg") no-repeat;
  margin-right: 10px;
  transition: all .5s;
}

.jobsPhotosLeftRow1Right {
  height: 100%;
  width: 50%;
  float: right;
  background: url("https://d35408cyocioye.cloudfront.net/legacy/job-photos-2.jpg") no-repeat;
  transition: all .5s;
}

.jobsPhotosLeftRow2Left {
  height: 100%;
  width: calc(50% - 10px);
  float: left;
  background: url("https://d35408cyocioye.cloudfront.net/legacy/job-photos-3.jpg") no-repeat;
  border-bottom-left-radius: 5px;
  margin-top: 10px;
  margin-bottom: 10px;
  margin-right: 10px;
  transition: all .5s;
}

.jobsPhotosLeftRow2Right {
  height: 100%;
  width: 50%;
  float: right;
  background: url("https://d35408cyocioye.cloudfront.net/legacy/job-photos-4.jpg") no-repeat;
  margin-top: 10px;
  margin-bottom: 10px;
  transition: all .5s;
}

.jobsPhotosLeftRow3Left {
  height: 100%;
  width: calc(50% - 10px);
  float: left;
  background: url("https://d35408cyocioye.cloudfront.net/legacy/job-photos-5.jpg") no-repeat;
  margin-right: 10px;
  transition: all .5s;
}

.jobsPhotosLeftRow3Right {
  height: 100%;
  width: 50%;
  float: right;
  background: url("https://d35408cyocioye.cloudfront.net/legacy/job-photos-6.jpg") no-repeat;
  transition: all .5s;
}

.jobsPhotosLeftRow1Left:hover, .jobsPhotosLeftRow1Right:hover, .jobsPhotosLeftRow2Right:hover, .jobsPhotosLeftRow2Left:hover, .jobsPhotosLeftRow3Right:hover, .jobsPhotosLeftRow3Left:hover {
  background-position: 0 -200px;
}

#jobsPhotosInfoRight {
  width: calc(50% - 80px);
  height: calc(100% - 80px);
  float: right;
  text-align: left;
  color: #575755;
  padding: 40px;
  font-size: 15px;
}

#jobsBenefits {
  margin: 0 0 0 40px;
  padding: 0;
}

#jobsBenefits li {
  line-height: 2.67em;
}

#jobListings {
  width: 100%;
  margin: auto auto 40px;
  padding-bottom: 0;
}

#jobListingsTopGap {
  height: 150px;
}

.jobsListingTime {
  font-style: italic;
}

#jobsListingList {
  margin: 40px 0 0;
  padding: 0;
}

#jobsListingList > li {
  text-align: left;
  color: #575755;
  border: 1px solid #ccc;
  border-radius: 5px;
  margin-bottom: 20px;
  padding: 20px;
  font-size: 20px;
  line-height: 1.5;
  list-style-type: none;
}

#jobsListingList > li:hover {
  cursor: pointer;
  border: 1px solid #008cff;
}

.jobsListingArrow {
  height: 25px;
  width: 25px;
  pointer-events: none;
  background: url("https://d35408cyocioye.cloudfront.net/legacy/arrow-down-blue1.png") no-repeat;
  margin-top: 36px;
  transition: all .3s;
  position: absolute;
  right: 27px;
}

#jobsListingList li:hover > .jobsListingArrow {
  margin-top: 40px;
}

.jobsListingArrow--flip {
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
}

.jobsListingBody {
  margin-top: 20px;
  font-size: 15px;
  display: none;
}

.jobsListingBody a {
  color: #88aac4;
  font-size: 15px;
}

.jobsListingBody ul li p {
  text-align: left;
  margin: 0;
}

.jobsListingBody p {
  text-align: left;
  max-width: 100%;
  margin: 0;
}

.jobsListingBody a:hover {
  color: #008cff;
}

.jobsListingBodyList {
  margin-top: 10px;
  margin-bottom: 10px;
}

.jobsListingBodyList li {
  list-style-type: circle;
}

.jobsListingTitle {
  font-weight: bold;
}

.jobsListingTitle, .jobsListingText, .jobsListingTime {
  pointer-events: none;
}

#jobsFooter {
  color: #fff;
  width: 100%;
  height: 140px;
  max-width: 1200px;
  background-color: #3ca4fc;
  border-bottom: 15px solid #fff;
  margin: auto auto 180px;
  padding: 50px 0 0;
  font-size: 14px;
}

#testTop {
  height: 80px;
  width: 100%;
  background-color: #f9f9f9;
  border-bottom: 1px solid #000;
  position: fixed;
  top: 0;
}

#testMiddle {
  height: calc(100% - 40px);
  width: 100%;
  color: #000;
  background-color: #fff;
  margin-top: 80px;
  font-size: 40px;
  position: static;
}

#testBottom {
  height: 80px;
  width: 100%;
  background-color: #f9f9f9;
  border-top: 1px solid #000;
  position: fixed;
  bottom: 0;
}

#testMiddleLeft {
  width: calc(30% - 41px);
  height: 100%;
  float: left;
  border-right: 1px solid #000;
  padding: 20px;
}

#testMiddleRight {
  width: calc(70% - 40px) % ;
  height: 100%;
  margin-left: 30%;
  padding: 20px;
}

#portal-content {
  height: 100%;
  width: 100%;
  background-color: #ff0;
}

#portal-content-left {
  width: 20%;
  float: left;
  height: 100%;
  margin-top: 100px;
}

#portal-content-right {
  width: 80%;
  float: right;
  height: 100%;
  margin-top: 100px;
}

#preload {
  display: none;
}

#shitBlock1 {
  width: 100%;
  height: 560px;
  background-color: #0000;
  background-image: linear-gradient(#0000001a, #00000080), url("https://d35408cyocioye.cloudfront.net/new2/shit.jpg");
  background-position: 0 0, 0 0;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: scroll, scroll;
  background-origin: padding-box, padding-box;
  background-clip: border-box, border-box;
  padding-top: 46px;
  position: relative;
}

#shitBlock1:hover {
  cursor: pointer;
}

#shitBlock3 {
  width: 100%;
  height: 560px;
  background: url("https://d35408cyocioye.cloudfront.net/new2/shitproduct.jpg") 0 0 / cover no-repeat;
  border-bottom: 15px solid #fff;
  margin-top: 40px;
  padding-top: 46px;
  position: relative;
  overflow: hidden;
}

#shitBlock4 {
  width: 100%;
  height: 250px;
  margin-top: 20px;
  margin-bottom: 20px;
}

@media screen and (max-width: 1150px) {
  #navmenureveal, #navmenujoshdoghead {
    position: absolute;
    left: 15px;
  }

  .dividerVideo1, .dividerVideo2, .dividerVideo3 {
    width: calc(33% - 6px);
  }

  #jobsListingList {
    margin-left: 20px;
    margin-right: 20px;
  }

  #navlist1 li {
    padding-right: 30px;
  }

  .nav1home {
    margin-right: 15px;
  }
}

@media screen and (max-width: 960px) {
  #reliableDiamond {
    height: 250px;
    width: 250px;
    margin-top: 225px;
    margin-left: -125px;
  }

  .reliableTextLarge {
    margin-bottom: 0;
  }

  .reliableLine, .newblock6Button {
    display: none;
  }

  .newblock6Cont {
    width: 400px;
    margin-right: 0;
  }

  #registerTerms {
    width: 50%;
  }
}

@media screen and (max-width: 820px) {
  #navlist1 li {
    padding-right: 25px;
  }

  .pressGridObject {
    width: calc(33% - 38px);
  }

  .pressGrid3 {
    margin-right: 0;
    display: none;
  }

  #aboutFooter {
    margin-bottom: 0;
  }

  .pressGrid4 {
    margin-right: 10px;
  }

  #buyBlock11TopRight, #buyBlock11BottomRight, .buyBlock11Vertical2 {
    display: none;
  }

  #buyBlock11TopLeft, #buyBlock11TopMiddle, #buyBlock11BottomLeft, #buyBlock11BottomMiddle {
    width: 50%;
  }

  .buyBlock11Vertical1 {
    left: 50%;
  }

  .frameLeft, .frameRight, .frameBottom, #buyBlock6 ul, #buySaleBlockContPro, #buySaleBlockPro {
    display: none;
  }

  #buyBlock8Title {
    text-align: center;
    padding-left: 0;
  }

  #buyBlock8 ul {
    margin-left: -125px;
    position: absolute;
    left: 50%;
  }

  #newblock1, #newBlock1Vid, .newtext1Container {
    height: 460px;
    object-fit: fill;
    background-color: #0000;
  }

  .newsection1text {
    padding-top: 110px;
    font-size: 38px;
  }

  .newsection1line5, .newsection1line6 {
    top: 250px;
  }

  #newblock5, #newblock6, #newblock8 {
    height: 400px;
  }

  .newblock5TextLarge {
    padding-top: 150px;
  }

  #newblock2 {
    height: 500px;
  }

  .newblock2Button, .featuresCornerTextTitle, .newblock6Button, .newblock8logo {
    display: none;
  }

  .newblock6Cont {
    width: 400px;
    margin-right: 0;
  }

  .newblock8cont {
    margin-top: 70px;
  }

  .section1ripple {
    margin-top: 50px;
  }
}

@media screen and (max-width: 740px) {
  #navlist1 li {
    padding-right: 20px;
  }

  #jobsPhotosInfo, #jobsGreatBenefits, #jobsLocation, #jobsOpenPositions, #jobsOpening {
    display: none;
  }

  #jobListingsTopGap {
    height: 70px;
  }

  #jobsListingList {
    margin-left: 10px;
    margin-right: 10px;
  }

  .jobsListingArrow1, .jobsListingArrow2, .jobsListingArrow3, .jobsListingArrow4, .jobsListingArrow5, .jobsListingArrow6, .jobsListingArrow7, .jobsListingArrow8, .jobsListingArrow9, .jobsListingArrow10 {
    right: 20px;
  }
}

@media screen and (max-width: 720px) {
  #section1Cont, .disappear, .sectionNaderText, .tile2, .tile3, .tile4, .tile5, .tile6, .section1textSmall, .section1textSmall2.footer, .impressiveTextWorksWith, .impressiveWorksImage, .section4quote, .meetTextSmall, .liberatingTextArea2, #impressiveWorksWith, .section5quote, #buyBlock8, #buyBlock9, #buyBlock12, #buyBlock13, #buyBlock6, #buyBlock14, #buyBlock7, #block10, #block3, .divider0, .divider2, #block4, .divider3, #block5, #block6, #block7, .bottomLogo, .buyBlock2Arrows, #buyBlock3, #buyBlock4, #buyBlock1Vid, #buyBlock5 {
    display: none;
  }

  #block8 {
    height: 275px;
    margin-top: 0;
  }

  .bottomText {
    margin-top: 50px;
    padding: 20px;
  }

  .bottomButton {
    margin-top: 160px;
  }

  .bottomNowShippingText {
    text-transform: uppercase;
    margin: 350px auto auto;
    font-size: 12px;
    position: absolute;
    left: 0;
    right: 0;
  }

  .tile1 {
    opacity: 1;
    top: 60px;
  }

  .section1text {
    opacity: 1;
    margin-top: -100px;
    padding-bottom: 10px;
    padding-left: 20px;
    padding-right: 20px;
    font-size: 30px;
  }

  .text1Container {
    height: auto;
  }

  #block1 {
    background-color: #0000;
    background-image: linear-gradient(#fffc, #fffc), url("https://d35408cyocioye.cloudfront.net/legacy/new2/cover-slide-bg-photo.jpg");
    background-position: 0 0, 0 0;
    background-repeat: repeat, no-repeat;
    background-size: cover;
    background-attachment: scroll, scroll;
    background-origin: padding-box, padding-box;
    background-clip: border-box, border-box;
  }

  .sectionNader {
    height: 250px;
    margin-top: 60px;
  }

  .section1GetJoshButton {
    opacity: 1;
    margin-top: 18px;
  }

  #impressiveLeftcont {
    height: 233px;
  }

  #impressiveRightcont {
    height: 233px;
    margin-top: -233px;
  }

  #impressiveRight3 {
    margin-top: 0;
  }

  #impressiveTriangle {
    height: 250px;
    background: none;
    margin-top: 0;
    position: static;
  }

  .impressiveIcon {
    margin-top: 100px;
  }

  #impressiveText {
    margin-top: -212px;
  }

  #block8 {
    margin-bottom: 0;
  }

  #buyBlock1 {
    height: 440px;
    background: url("https://d35408cyocioye.cloudfront.net/legacy/new2/getjosh/puppy.jpg") no-repeat;
    margin-top: -70px;
  }

  #buyBlock1demo {
    height: 440px;
    background: url("https://d35408cyocioye.cloudfront.net/legacy/new2/demounit/sunset.jpg") no-repeat;
    margin-top: -70px;
  }

  #buyBlock1Left {
    width: 100%;
    margin-top: 440px;
    display: none;
  }

  .buySaleBlockCont, .buySaleBlock {
    opacity: 1;
    margin-left: calc(50% - 115px);
  }

  .buyBlock2Photos {
    height: 100px;
    width: 33%;
  }

  #buyBlockPhoto1 {
    margin-left: 0;
  }

  #buyBlockPhoto2 {
    margin-left: 34%;
  }

  #buyBlockPhoto3 {
    margin-left: 68%;
  }

  .buyBlock2PhotoText {
    margin-top: 108px;
  }

  #buyBlock2Cont {
    height: 85px;
  }

  #buyCont {
    margin-bottom: 0;
  }

  #buyBlockHidden {
    margin-bottom: 20px;
    display: block;
  }

  #buyBlockHiddenTitle {
    color: #222;
    padding-top: 20px;
    font-size: 40px;
    font-weight: 100;
  }

  .buySaleBlockLogo {
    opacity: 1;
  }

  .recsContent {
    margin-bottom: 0;
  }

  .demoUnithide, .newblock6TextLarge {
    display: none;
  }

  .newdivider0 {
    padding: 30px;
  }

  #newblock2, #newblock3, #newblock4, #newblock5, #newblock6, #newblock8 {
    border-bottom: 5px solid #fff;
  }

  .aboutLeftPhoto, .aboutRightPhoto {
    display: none;
  }

  .aboutRightText, .aboutLeftText {
    max-width: 100%;
    margin: 30px;
    padding: 0;
  }

  .teamSection {
    height: auto;
    border-top: 1px solid #999;
    margin-bottom: 0;
    padding-top: 1px;
    padding-bottom: 15px;
  }

  #teamBody {
    margin-top: -5px;
  }
}

@media screen and (max-width: 600px) {
  .pressGridObject {
    width: calc(50% - 40px);
  }

  .pressGrid2 {
    margin-right: 10px;
  }

  .pressGrid3 {
    margin-right: 10px;
    display: block;
  }

  #aboutFooter {
    margin-bottom: 0;
  }

  #pressText {
    display: none;
  }

  .recsGrid li {
    width: calc(50% - 12px);
  }

  .recsGridLiRightSmall {
    margin-right: 0 !important;
  }

  .newsection1text {
    padding-top: 110px;
    padding-left: 50px;
    padding-right: 50px;
    font-size: 32px;
  }

  .newsection1line5, .newsection1line6 {
    border-top: 1px solid #fff;
    margin-top: 35px;
  }

  #newblock2, #newblock3, #newblock4, #newblock5, #newblock6, #newblock8 {
    border-bottom: 3px solid #fff;
  }

  .newblock2TextSmall, .newblock6TextLarge, #featuresCorner1, #featuresCorner3 {
    display: none;
  }

  #newblock2 {
    height: 385px;
  }

  #newblock6 {
    height: 300px;
  }

  .newblock6TextSmall {
    width: 90%;
  }

  .newblock6Cont {
    width: 300px;
  }

  .newblock8cont {
    width: 400px;
    float: none;
    margin: auto;
    padding-top: 80px;
  }

  #featuresCorner2, #featuresCorner4 {
    width: 100%;
  }

  #registerTerms {
    width: 75%;
  }
}

@media screen and (max-width: 540px) {
  .newblock6TextSmall {
    display: none;
  }
}

@media screen and (max-width: 460px) {
  #reliableDiamond {
    height: 200px;
    width: 200px;
    margin-top: 250px;
    margin-left: -100px;
  }

  .reliableTextLarge {
    margin-top: 25px;
  }

  .hide460, .footer, .frameBorder, .frameTop, .divider0Cedia, .newblock5TextSmall, .newblock3Top, .newblock3BottomLeft, .divider0Text, .reliableTextSmall, #guideText, #buyBlock11TopLeft, #buyBlock11BottomLeft, .buyBlock11Vertical1 {
    display: none;
  }

  #guideTopSection {
    width: calc(100% - 40px);
    background: none;
    padding: 50px 20px 0;
  }

  .guideSection {
    margin-bottom: 0;
    padding-top: 0;
    padding-bottom: 0;
  }

  .guideListTitle {
    text-align: left;
    margin-left: 75px;
  }

  #buyBlock11TopMiddle, #buyBlock11BottomMiddle {
    width: 100%;
  }

  #newblock5, #newblock3, #newblock8, #newblock2 {
    height: 300px;
  }

  .newsection1text {
    margin-top: -30px;
    padding-top: 95px;
  }

  .newdivider0 {
    padding: 2px;
  }

  .newblock5TextLarge {
    padding-top: 100px;
  }

  .newblock3BottomRight, .newblock3Bottom {
    height: 100%;
    width: 100%;
  }

  .newblock8cont {
    padding-top: 25px;
  }

  #newblock8 {
    margin-bottom: 0;
  }

  #newblockmobile {
    height: 300px;
    width: 100%;
    z-index: 100;
    background-color: #0000;
    background-image: linear-gradient(#0000004d, #0000004d), url("https://d35408cyocioye.cloudfront.net/new2/modern4.jpg");
    background-position: bottom;
    background-repeat: repeat, no-repeat;
    background-size: cover;
    background-attachment: scroll, scroll;
    background-origin: padding-box, padding-box;
    background-clip: border-box, border-box;
    border-bottom: 2px solid #fff;
    display: block;
  }

  .section1PlayButtonMobile {
    margin-left: -34px;
    position: absolute;
    left: 50%;
  }

  .newsection1text {
    padding-left: 0;
    padding-right: 0;
  }
}

.blueLink {
  color: #88aac4;
  font-size: 12px;
}

.blueLink--big {
  font-size: 18px;
}

.blueLink:hover, .blueLink:active {
  color: #008cff;
}

.formErrorList {
  padding: 0;
}

.noAccess__heading {
  letter-spacing: 1px;
  margin: 45px 0 10px;
}

.noAccess__copy {
  letter-spacing: 1px;
  margin: 45px 0 10px;
  padding: 0 5px;
}

.fullNav {
  z-index: 0;
  height: 100vh;
  -webkit-overflow-scrolling: touch;
  width: 100vw;
  opacity: 0;
  -webkit-backface-visibility: hidden;
  background-color: #272a2d;
  transition: all .5s;
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  overflow: scroll;
}

.fullNavList {
  height: 110vh;
  margin-top: 6vh;
  padding-left: 0;
}

#fullNavListLeft {
  width: 70%;
  padding-left: 30vw;
}

#fullNavListMid {
  margin-top: calc(6vh + 78px);
  position: absolute;
  left: 45vw;
  transform: translateX(-40%);
}

#fullNavListRight {
  height: auto;
  margin-top: calc(6vh + 760px);
  position: absolute;
  left: 30vw;
}

.fullNavText {
  text-align: left;
  opacity: 0;
  margin-bottom: 15px;
  list-style: none;
  transform: translate3d(-30px, 0, 0);
}

.fullNavText.view, .horizontalAboutLine.view {
  opacity: 1;
  transition: all .15s ease-out;
  transform: translate3d(0, 0, 0);
}

.horizontalAboutLine.view {
  opacity: .25;
  transition: all .2s ease-out;
}

.horizontalAboutLine.view:before {
  width: 7vw;
  transition: all .3s ease-out;
}

.fullNavText.leave, .horizontalAboutLine.leave {
  opacity: 0;
  transition: all 50ms ease-in;
  transform: translate3d(-50px, 0, 0);
}

.fullNavText.active {
  border-left: 2px solid #008cff;
  margin-left: -20px;
  padding-left: 20px;
}

.fullNavText.active a {
  opacity: .9;
}

.fullNavText a {
  opacity: .7;
  color: #fff;
  font-family: Lato;
  font-size: 30px;
  font-weight: 300;
  position: relative;
}

.aboutLine {
  width: 100%;
  height: 1px;
  background: #fff;
  display: block;
  position: relative;
  top: -1rem;
  left: 6rem;
}

.horizontalAboutLine {
  width: 0;
  height: 11rem;
  opacity: .25;
  border: 1px solid #fff;
  margin-left: -20px;
  position: absolute;
  top: .25rem;
}

.horizontalAboutLine:before {
  content: "";
  width: 0;
  height: 0;
  border: none;
  margin-left: -7.2vw;
  position: absolute;
  top: 4rem;
}

#fixedHeader.pinned {
  position: absolute !important;
}

#fixedHeader {
  height: 40px;
  z-index: 12;
  opacity: 0;
  -webkit-backface-visibility: hidden;
  justify-content: space-between;
  transition: all 1s;
  display: flex;
  position: fixed;
  left: 0;
  overflow: hidden;
}

.fixedHeaderPosition, .fixedHeaderSplash {
  width: calc(100% - 100px);
  margin-top: 10px;
  padding-left: 20px;
  padding-right: 50px;
}

.hamburger {
  width: 30px;
  opacity: .8;
  cursor: pointer;
  z-index: 3;
  transition: all .5s;
}

.hamburger:hover {
  opacity: 1 !important;
}

.hamburger .burgerLine {
  height: 1px;
  opacity: 1;
  width: 25px;
  background-color: #fff;
  transition: all .5s;
  position: absolute;
}

.hamburger.dark .burgerLine {
  background-color: #303c45;
}

.hamburger.light .burgerLine {
  background-color: #fff;
}

.burgerLine1 {
  margin-top: 11px;
}

.burgerLine2 {
  margin-top: 19px;
}

.burgerLine3 {
  margin-top: 27px;
}

#joshdoghead {
  height: auto;
  width: 115px;
  z-index: 1;
  opacity: 0;
  background: url("josh-horiz-logo-white.4d76fc5a.svg") 0 0 / cover no-repeat;
  transition: all .5s;
}

.headerLogin {
  color: #fff;
  opacity: 0;
  z-index: 1;
  line-height: 40px;
  transition: all .5s;
}

.headerLogin:hover {
  opacity: 1 !important;
}

@media (min-width: 768px) {
  .fixedHeaderSplash {
    width: calc(100% - 140px);
    margin-top: 30px;
    padding-left: 40px;
    padding-right: 70px;
  }
}

@media (min-width: 1024px) {
  .not-tablet {
    display: block !important;
  }

  .fixedHeaderPosition {
    width: calc(100% - 100px);
    margin-top: 50px;
    padding-left: 50px;
    padding-right: 50px;
    position: fixed !important;
  }

  .fixedHeaderSplash {
    width: calc(100% - 140px) !important;
    margin-top: 60px !important;
    padding-left: 70px !important;
    padding-right: 70px !important;
  }

  #fullNavListLeft {
    margin-left: 12vw !important;
    padding-left: 0 !important;
  }

  #fullNavListRight {
    left: 75.5vw !important;
  }

  .fullNavList {
    margin-top: 22.5vh !important;
  }

  .fullNavText a {
    font-size: 30px !important;
  }

  .fullNavText {
    margin-bottom: 1rem !important;
  }

  .horizontalAboutLine:before {
    border: 1px solid #fff !important;
  }

  #fullNavListLeft li:nth-child(3) {
    margin-top: 0 !important;
  }

  .horizontalAboutLine {
    height: 14.5rem !important;
    top: .5rem !important;
  }

  #fullNavListMid {
    margin-top: 22.5vh !important;
    left: 38vw !important;
  }

  .fullNavText a:hover {
    opacity: 1 !important;
  }

  .fullNavText a:active {
    opacity: .9 !important;
  }

  .hoverLink:before {
    content: "";
    width: 100%;
    height: 1px;
    visibility: hidden;
    background-color: #fff;
    transition: all .2s ease-in-out;
    position: absolute;
    bottom: -1px;
    left: 0;
    transform: scaleX(0);
  }

  .hoverLink:hover:before {
    visibility: visible;
    transform: scaleX(1);
  }
}

/*# sourceMappingURL=index.c9d21c1d.css.map */
