body,
html {
  margin: 0;
  background: #fff;
}
body {
  font-family: "Lato", sans-serif;
  font-size: 18px;
  padding: 0;
  text-align: center;
  position: relative;
  -webkit-font-smoothing: antialiased;
  color: #333;
  max-width: 1120px;
  margin: auto;
}
a {
  text-decoration: none;
  color: #fff;
  /* opacity: 0.8; */
  font-size: 16px;
  font-weight: 800;
  cursor: pointer;
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
}
a:hover {
  opacity: 1;
}
h1 {
  width: 100%;
  float: left;
  margin-bottom: 5px;
  font-size: 50px;
  letter-spacing: -4px;
  font-weight: 300;
  margin-top: 0px;
}
p {
  font-size: 18px;
  max-width: 610px;
  margin: auto;
  margin-top: 20px;
  letter-spacing: 0px;
  font-weight: 500;
}

/* ****************************** */
/* NEW  2 */
/* ****************************** */
#frame {
  z-index: 11;
  position: fixed;
}
.frameBorder {
  position: fixed;
  z-index: 5;
  background-color: #fff;
}
.frameTop {
  width: 100%;
  height: 46px;
  padding-top: 10px;
  padding-bottom: 10px;
  top: 0;
  overflow: hidden;
  -webkit-transition: all 1s;
  transition: all 1s;
  max-width: 1120px;
  z-index: 12;
}
.frameLeft {
  height: 100%;
  width: 15px;
  left: 0;
}
.frameRight {
  height: 100%;
  width: 15px;
  right: 0;
}
.frameBottom {
  width: 100%;
  height: 15px;
  bottom: 0;
}
#content {
  min-height: 100%;
}
.sectionPosition {
  position: absolute;
  margin-top: -40px;
}

/* NAV 1 */
#navmenureveal {
  height: 35px;
  width: 25px;
  position: absolute;
  margin-top: 10px;
  opacity: 0;
  top: 5px;
  -webkit-transition: all 1s;
  transition: all 1s;
  z-index: 13;
}
#navmenujoshdoghead {
  height: 25px;
  width: 32px;
  position: absolute;
  margin-top: 10px;
  display: none;
  opacity: 0;
  top: 10px;
  background: url(https://d35408cyocioye.cloudfront.net/legacy/new2/josh_logo.png)
    no-repeat;
  background-size: 72px 25px;
  -webkit-transition: all 1s;
  transition: all 1s;
  z-index: 13;
}
.menuLine {
  height: 1px;
  width: 25px;
  background-color: #555;
  position: absolute;
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
}
.menuLine1 {
  margin-top: 8px;
}
.menuLine2 {
  margin-top: 17px;
}
.menuLine3 {
  margin-top: 26px;
}
.joshLogoTop {
  height: 25px;
  width: 72px;
  opacity: 0;
  margin: auto;
  margin-top: 10px;
  background: url(https://d35408cyocioye.cloudfront.net/legacy/new2/josh_logo.png)
    no-repeat;
  background-size: 72px 25px;
  -webkit-transition: all 1.5s;
  transition: all 1.5s;
}
#nav1 {
  position: relative;
  display: none;
  top: 0;
  left: 0;
  width: 100%;
  -webkit-transition: all 1.5s;
  transition: all 1.5s;
}
#navlist1 {
  margin: 10px 0px 10px 25px;
  padding: 0px;
  text-align: left;
  text-align: center;
  -webkit-transition: margin 1s;
  transition: margin 1s;
}
#navlist1 li {
  display: inline;
  list-style-type: none;
  padding-right: 30px;
  color: #bbb;
}
.nav1home {
  position: absolute;
  right: 0px;
  opacity: 0;
  top: 20px;
  z-index: 13;
  list-style: none;
  -webkit-transition: all 1s;
  transition: all 1s;
}
.nav1home a {
  color: #555;
  font-size: 12px;
}
.nav1home:hover a {
  color: #000;
}
.navJoshLogo {
  position: absolute;
  left: 0px;
}
.navJoshLogo a {
  opacity: 1;
  border: none;
}
.navJosh1 {
  position: fixed;

  /* HIDDEN */
  display: none;

  margin-top: 1px;
  height: 25px;
  width: 72px;
  background: url(https://d35408cyocioye.cloudfront.net/legacy/new2/nav-josh-logo-filled.png)
    no-repeat;
  background: url(https://d35408cyocioye.cloudfront.net/legacy/new2/nav-josh-logo-whole.png)
    no-repeat;
  -webkit-transition: background 0.5s;
  transition: background 0.5s;
}
.nav1text a {
  color: #bbb;
  font-size: 12px;
  opacity: 1;
  -webkit-transition: all 1s;
  transition: all 1s;
}
.nav1text a:hover {
  color: #000;
  cursor: pointer;
}
.nav1textActive a {
  color: #000;
  cursor: pointer;
}
.nav1aboutDropdown {
  display: none;
  position: fixed;
  background-color: #fff;
  width: 100%;
  top: 52px;
  left: 0px;
  z-index: 11;
}
.nav1aboutDropdownDivider {
  width: 400px;
  position: absolute;
  margin-left: -200px;
  margin-top: 3px;
}
#aboutList {
  margin: 10px 0px 10px 25px;
  color: #bbb;
  font-size: 12px;
  font-weight: 600;
  text-transform: uppercase;
  padding: 0px;
  text-align: center;
  -webkit-transition: margin 1s;
  transition: margin 1s;
}
#aboutList li,
#aboutList li a {
  display: inline;
  list-style-type: none;
  padding-right: 15px;
  color: #bbb;
  font-size: 12px;
  font-weight: 600;
  text-transform: uppercase;
  line-height: 1.7em;
}
#aboutList li:hover,
#aboutList li a:hover {
  cursor: pointer;
  color: #000;
}
.getJoshButton {
  display: none;
  position: absolute;
  right: 0px;
  top: 50px;
  height: 25px;
  width: 80px;
  background: url(https://d35408cyocioye.cloudfront.net/legacy/new2/nav-get-josh-button-outline.png)
    no-repeat;
  padding-top: 4px;
  -webkit-transition: background 1.5s;
  transition: background 1.5s;
}
.getJoshButton:hover {
  background: url(https://d35408cyocioye.cloudfront.net/legacy/new2/nav-get-josh-button-filled.png)
    no-repeat;
}
.getJoshButtonText {
  color: #008cff;
  -webkit-transition: all 0.4s;
  transition: all 0.4s;
}
.getJoshButton:hover > .getJoshButtonText {
  color: #fff;
}
.nav1homeicon {
  width: 20px;
  height: 20px;
  margin-top: -19px;
  position: absolute;
  margin-left: 36px;
  background: url(https://d35408cyocioye.cloudfront.net/new2/home_icon.png)
    no-repeat;
  background-position: 0px 0px;
}
.navhomeHover:hover > .nav1homeicon,
.nav1home:hover > .nav1homeicon {
  background-position: 0px -24px;
}

/* NAV 2 */
#nav2 {
  position: relative;
  top: 14px;
  left: 0;
  display: block;
  margin: 0px 112px 0px 112px;
  -webkit-transition: all 1s;
  transition: all 1s;
}
#navlist2 {
  margin: 10px;
  padding: 0px;
  text-align: left;
  text-align: center;
  -webkit-transition: margin 1s;
  transition: margin 1s;
}
#navlist2 li {
  display: inline;
  list-style-type: none;
  padding-right: 40px;
}
#navlist2 a {
  font-size: 18px;
}
.nav2icon1 {
  background: url(https://d35408cyocioye.cloudfront.net/legacy/new2/icons/icon1.png)
    no-repeat;
}
.nav2icon2 {
  background: url(https://d35408cyocioye.cloudfront.net/legacy/new2/icons/icon2.png)
    no-repeat;
}
.nav2icon3 {
  background: url(https://d35408cyocioye.cloudfront.net/legacy/new2/icons/icon5.png)
    no-repeat;
}
.nav2icon4 {
  background: url(https://d35408cyocioye.cloudfront.net/legacy/new2/icons/icon4.png)
    no-repeat;
}
.nav2icon5 {
  background: url(https://d35408cyocioye.cloudfront.net/legacy/new2/icons/icon3.png)
    no-repeat;
}
.nav2icon6 {
  background: url(https://d35408cyocioye.cloudfront.net/legacy/new2/icons/icon6.png)
    no-repeat;
}
.nav2icon7 {
  background: url(https://d35408cyocioye.cloudfront.net/legacy/new2/icons/icon7.png)
    no-repeat;
}
.nav2icon8 {
  background: url(https://d35408cyocioye.cloudfront.net/legacy/new2/icons/icon8.png)
    no-repeat;
}
.nav2icon9 {
  background: url(https://d35408cyocioye.cloudfront.net/legacy/new2/icons/icon9.png)
    no-repeat;
}
.nav2icon10 {
  background: url(https://d35408cyocioye.cloudfront.net/legacy/new2/icons/icon10.png)
    no-repeat;
}
.nav2icon11 {
  background: url(https://d35408cyocioye.cloudfront.net/legacy/new2/icons/icon11.png)
    no-repeat;
}
.nav2icon12 {
  background: url(https://d35408cyocioye.cloudfront.net/legacy/new2/icons/icon12.png)
    no-repeat;
}
.nav2icon {
  background-position: 0px 0px;
}
.nav2icon:hover {
  background-position: 0px -22px;
}
#navlist2 a.active > .nav2icon {
  background-position: 0px -22px;
}

/* BLOCKS */
#block1,
#block2,
#block3,
#block4,
#block5,
#block6,
#block7,
#block8 {
  border-bottom: 15px solid #fff;
  background-color: #fff;
}

/* BLOCK 1 - HOME */
#block1 {
  background: url(https://d35408cyocioye.cloudfront.net/legacy/new2/landing-page-hero-image.jpg)
    no-repeat;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  background-position: center;
}
.tileContainer {
  width: 100%;
  height: 359px;
  overflow: hidden;
  -webkit-transition: all 1s;
  transition: all 1s;
}
.tileImage {
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
}
.tileImage:hover {
  -webkit-filter: grayscale(100%);
  filter: grayscale(100%);
}
.tile {
  position: absolute;
  -webkit-transition: all 1s;
  transition: all 1s;
}
.tile1 {
  top: 55px;
  left: 50%;
  margin-left: -100px;
  opacity: 0;
}
.tile2 {
  top: -49px;
  left: 50%;
  margin-left: 5px;
  opacity: 0;
}
.tile3 {
  top: -49px;
  left: 50%;
  margin-left: -205px;
  opacity: 0;
}
.tile4 {
  top: -153px;
  left: 50%;
  margin-left: 109px;
  opacity: 0;
}
.tile5 {
  top: -153px;
  left: 50%;
  margin-left: -100px;
  opacity: 0;
}
.tile6 {
  top: -153px;
  left: 50%;
  margin-left: -311px;
  opacity: 0;
}
.text1Container {
  height: 300px;
  width: 100%;
}
.section1text {
  font-size: 34px;
  text-align: center;
  font-weight: 300;
  opacity: 0;
  margin-top: 50px;
  -webkit-transition: all 2s;
  transition: all 2s;
}
.section1textSmall {
  font-size: 14px;
  text-align: center;
  font-weight: 300;
  opacity: 0;
  margin-top: 10px;
  -webkit-transition: all 2s;
  transition: all 2s;
}
.section1textSmall2 {
  font-size: 12px;
  text-align: center;
  font-weight: 300;
  opacity: 0;
  margin-top: 10px;
  -webkit-transition: all 2s;
  transition: all 2s;
  margin-top: 85px;
  text-transform: uppercase;
  letter-spacing: 0.13em;
}
.section1GetJoshButton {
  position: absolute;
  height: 40px;
  width: 112px;
  margin-top: 30px;
  margin-left: -56px;
  background: url(https://d35408cyocioye.cloudfront.net/legacy/new2/footer-get-josh-button.png)
    no-repeat;
  padding-top: 9px;
  opacity: 0;
  -webkit-transition: all 1.5s;
  transition: all 1.5s;
}
.section1GetJoshButton:hover {
  opacity: 1 !important;
}
.section1GetJoshButtonText {
  color: #fff;
  font-size: 17px;
}

/* NEW TOP SECTION */
.newTextLarge {
  font-size: 48px;
  font-weight: 300;
  color: #50504e;
}
.newTextSmall {
  font-size: 16px;
  line-height: 1.6em;
  color: #50504e;
}

.navJosh1Animate {
  position: absolute;
  background: url(https://d35408cyocioye.cloudfront.net/new2/josh_animate.gif)
    no-repeat;
  height: 28px;
  width: 33px;
  background-size: 33px 28px;
  margin-top: -1px;
  margin-left: -4px;
  opacity: 0;
  -webkit-transition: all 2s;
  transition: all 2s;
}
.navJosh1:hover > .navJosh1Animate {
  opacity: 1;
}

#newblock1 {
  position: relative;
  height: 630px;
  width: 100%;
  overflow: hidden;
}
#newBlock1Vid {
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  min-width: 100%;
  width: auto;
  width: 100%;
  min-height: 100%;
  height: auto;
  height: 100%;
  z-index: -100;
  -webkit-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);
  pointer-events: none;
  overflow-y: hidden;
}
.newBlock1VidOverlay {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: -99;
  background: #000;
  opacity: 0.5;
}
.newsection1text {
  text-align: center;
  color: #fff;
  opacity: 0;
  margin: auto;
  margin-top: 50px;
  padding-top: 150px;
  padding-left: 20px;
  padding-right: 20px;
  max-width: 700px;
  -webkit-transition: all 2s;
  transition: all 2s;
}
.newtext1Container {
  height: 630px;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
}
.ignore-css {
  all: unset;
}
.section1PlayButton {
  height: 50px;
  width: 68px;
  margin: auto;
  margin-top: 50px;
  background: url(https://d35408cyocioye.cloudfront.net/new2/playbutton.png)
    no-repeat;
  opacity: 0;
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
}
.section1PlayButton:hover {
  opacity: 1 !important;
  cursor: pointer;
}
.newsection1textSmall {
  font-size: 14px;
  text-align: center;
  color: #fff;
  font-weight: 300;
  opacity: 0;
  margin-top: 30px;
  -webkit-transition: all 2s;
  transition: all 2s;
}
.section1ripple {
  height: 400px;
  width: 400px;
  position: absolute;
  background: url(https://d35408cyocioye.cloudfront.net/new2/ripple.png)
    no-repeat;
  margin: auto;
  margin-top: 100px;
  left: 50%;
  margin-left: -200px;
  opacity: 0;
  -webkit-transition: all 2s;
  transition: all 2s;
}
.newsection1line1 {
  position: absolute;
  width: 10px;
  height: 0%;
  border-right: 1px solid #fff;
  top: 10%;
  left: 100px;
  opacity: 0.3;
  -webkit-transition: all 2s;
  transition: all 2s;
}
.newsection1line2 {
  position: absolute;
  width: 0%;
  height: 10px;
  border-top: 1px solid #fff;
  top: 20%;
  left: 5%;
  opacity: 0.3;
  -webkit-transition: all 2s;
  transition: all 2s;
}
.newsection1line3 {
  position: absolute;
  width: 10px;
  height: 0%;
  border-right: 1px solid #fff;
  top: 20%;
  right: 10%;
  opacity: 0.3;
  -webkit-transition: all 2s;
  transition: all 2s;
}
.newsection1line4 {
  position: absolute;
  width: 0%;
  height: 10px;
  border-top: 1px solid #fff;
  top: 80%;
  right: 5%;
  opacity: 0.3;
  -webkit-transition: all 2s;
  transition: all 2s;
}
.newsection1line5 {
  position: absolute;
  width: 0%;
  height: 10px;
  border-top: 1px solid #fff;
  top: 330px;
  right: 50%;
  opacity: 0.8;
  -webkit-transition: all 1s;
  transition: all 1s;
}
.newsection1line6 {
  position: absolute;
  width: 0%;
  height: 10px;
  border-top: 1px solid #fff;
  top: 330px;
  left: 50%;
  opacity: 0.8;
  -webkit-transition: all 1s;
  transition: all 1s;
}

/* WORKS WITH */
.newdivider0 {
  text-align: center;
  padding: 50px;
}

/* BEDROOM */
#newblock5 {
  height: 600px;
  width: 100%;
  z-index: 100;
  border-bottom: 15px solid #fff;
  background: url(https://d35408cyocioye.cloudfront.net/new2/bedroom.jpg)
    no-repeat;
  background-size: cover;
  background-position: bottom;
}
.newblock5TextLarge {
  padding-top: 250px;
  color: #fff;
}
.newblock5TextSmall {
  color: #fff;
  width: 50%;
  margin: auto;
  margin-top: 10px;
  width: 60%;
}

/* MICRO PHOTOGRAPH */
#newblock2 {
  height: 600px;
  width: 100%;
  z-index: 100;
  border-bottom: 15px solid #fff;
  background: url(https://d35408cyocioye.cloudfront.net/new2/josh_photograph.jpg)
    no-repeat;
  background-size: cover;
  background-position: bottom;
}
.newblock2TextLarge {
  padding-top: 30px;
}
.newblock2TextSmall {
  width: 50%;
  margin: auto;
  margin-top: 10px;
  width: 60%;
}
.newblock2Button {
  margin-top: 30px;
}
.newblock2Button a {
  color: #008cff;
  text-decoration: none;
}
.newblock2Button a:hover {
  color: #0069bf;
}

/* TALK TOUCH */
#newblock3 {
  height: 600px;
  width: 100%;
  border-bottom: 15px solid #fff;
}
.newblock3Top,
.newblock3Bottom {
  height: 50%;
  width: 100%;
}
.newblock3TopLeft {
  background: url(https://d35408cyocioye.cloudfront.net/new2/micro_talk2.jpg)
    no-repeat;
  height: 100%;
  width: 50%;
  float: left;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  background-position: center;

  -webkit-transition: all 3s;
  transition: all 3s;
}
.newblock3TopLeft:hover {
  opacity: 0.8;
}
.newblock3TopRight {
  width: 50%;
  height: 100%;
  margin-left: 50%;
  text-align: center;
}
.newblock3BottomLeft {
  width: 50%;
  height: 100%;
  float: left;
}
.newblock3BottomRight {
  background: url(https://d35408cyocioye.cloudfront.net/new2/micro_touch.jpg)
    no-repeat;
  height: 100%;
  width: 50%;
  float: right;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  background-position: center;

  -webkit-transition: all 3s;
  transition: all 3s;
}
.newblock3BottomRight:hover {
  opacity: 0.8;
}
.newblock3TextLarge {
  padding-top: 50px;
}
.newblock3TextSmall {
  width: 80%;
  margin: auto;
  margin-top: 10px;
}
.newblock3Line {
  width: 150px;
  height: 3px;
  background-color: #f2c760;
  border-radius: 5px;
  margin: auto;
  margin-top: 20px;
}

/* ADVANCED */
#newblock4 {
  height: 600px;
  width: 100%;
  border-bottom: 15px solid #fff;
}
#featuresDiamond {
  background-color: #fff;
  height: 300px;
  width: 300px;
  position: absolute;
  left: 50%;
  margin-top: 150px;
  margin-left: -150px;
  -moz-transform: rotate(45deg); /* FF3.5/3.6 */
  -o-transform: rotate(45deg); /* Opera 10.5 */
  -webkit-transform: rotate(45deg); /* Saf3.1+ */
  transform: rotate(45deg); /* Newer browsers (incl IE9) */
}
#featuresText {
  position: absolute;
  width: 400px;
  left: 50%;
  margin-left: -200px;
  margin-top: 225px;
}
.featuresTextSmall {
  width: 250px;
  margin: auto;
  margin-top: 20px;
}
.featuresLine {
  width: 200px;
  height: 3px;
  background-color: #f2c760;
  border-radius: 5px;
  margin: auto;
  margin-top: 20px;
}
.featuresCornerText {
  color: #fff;
  padding-top: 135px;
  margin: auto;
}
.featuresCornerTextTitle {
  margin-bottom: 10px;
  font-weight: 300;
  font-size: 40px;
}
.featuresCornerTextBody {
  display: none;
}
#featuresCorner1 {
  float: left;
  height: 50%;
  width: 50%;
  background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url(https://d35408cyocioye.cloudfront.net/new2/modern1.jpg) no-repeat;
  background-size: 100% 100%;
  -webkit-transition: all 1.5s;
  transition: all 1.5s;
}
#featuresCorner1:hover {
  background: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)),
    url(https://d35408cyocioye.cloudfront.net/new2/modern1.jpg) no-repeat;
  background-size: 105% 105%;
}
#featuresCorner2 {
  float: right;
  height: 50%;
  width: 50%;
  background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url(https://d35408cyocioye.cloudfront.net/new2/modern2.jpg) no-repeat;
  background-size: 100% 100%;
  -webkit-transition: all 1.5s;
  transition: all 1.5s;
}
#featuresCorner2:hover {
  background: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)),
    url(https://d35408cyocioye.cloudfront.net/new2/modern2.jpg) no-repeat;
  background-size: 105% 105%;
}
#featuresCorner3 {
  float: left;
  height: 50%;
  width: 50%;
  background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url(https://d35408cyocioye.cloudfront.net/new2/modern3.jpg) no-repeat;
  background-size: 100% 100%;
  -webkit-transition: all 1.5s;
  transition: all 1.5s;
}
#featuresCorner3:hover {
  background: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)),
    url(https://d35408cyocioye.cloudfront.net/new2/modern3.jpg) no-repeat;
  background-size: 105% 105%;
}
#featuresCorner4 {
  float: right;
  height: 50%;
  width: 50%;
  background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url(https://d35408cyocioye.cloudfront.net/new2/modern4.jpg) no-repeat;
  background-size: 100% 100%;
  -webkit-transition: all 1.5s;
  transition: all 1.5s;
}
#featuresCorner4:hover {
  background: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)),
    url(https://d35408cyocioye.cloudfront.net/new2/modern4.jpg) no-repeat;
  background-size: 105% 105%;
}

/* PHONE APP */
#newblock6 {
  height: 560px;
  width: 100%;
  z-index: 100;
  border-bottom: 15px solid #fff;
  background: url(https://d35408cyocioye.cloudfront.net/new2/joshapp.jpg)
    no-repeat;
  background-size: cover;
  background-position: middle;
}
.newblock6Cont {
  position: inherit;
  width: 550px;
  margin-top: 50px;
  float: right;
  padding-right: 15px;
  margin-right: 5%;
}
.newblock6TextSmall {
  width: 50%;
  margin: auto;
  margin-top: 15px;
  width: 60%;
}
.newblock6Button {
  margin-top: 20px;
}
.newblock6Button a {
  color: #008cff;
  text-decoration: none;
}
.newblock6Button a:hover {
  color: #0069bf;
}

/* LEARN MORE SECTION */
#newblock8 {
  height: 600px;
  width: 100%;
  z-index: 100;
  border-bottom: 15px solid #fff;
  background-color: #fff;
  margin-bottom: 180px;

  background: url(https://d35408cyocioye.cloudfront.net/new2/newfooter.jpg)
    no-repeat;
  background-size: cover;
  background-position: bottom;
}
.newblock8cont {
  width: 400px;
  float: left;
  margin-left: 50px;
  margin-top: 80px;
}
.newblock8Text {
  color: #fff;
  margin-top: 30px;
}
.newbottomButton {
  background-color: RGBA(255, 255, 255, 0.9);
  color: #008cff;
  width: 150px;
  padding: 10px;
  padding-bottom: 12px;
  border-radius: 50px;
  margin: auto;
  margin-top: 30px;
}
.newbottomButton:hover {
  background-color: #fff;
  cursor: pointer;
}

/* MOBILE HEADER */
#newblockmobile {
  display: none;
}

/* DIVIDER SECTIONS */
#block1 {
  border-bottom: none;
}
.divider0,
.divider1 {
  background-color: #f9f9f9;
  text-align: center;
  padding: 30px 0px 40px 0px;
  color: #303c45;
  margin-bottom: 15px;
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
}
.divider0 {
  padding: 20px;
  padding-top: 26px;
}
.divider0Cedia {
  position: absolute;
  margin-left: -197px;
  width: 185px;
  margin-top: 5px;
}
.divider0Text {
  position: absolute;
  font-size: 10px;
  color: #cdcdc5;
  text-transform: uppercase;
  margin-left: 20px;
  margin-top: 15px;
  font-weight: 800;
}
.worksWithLogo2 {
  position: absolute;
  margin-top: -27px;
  margin-left: 40px;
  height: 40px;
  width: 161px;
  background: url(https://d35408cyocioye.cloudfront.net/legacy/new2/works+with/lutron.png)
    no-repeat;
  -webkit-transition: all 1s;
  transition: all 1s;
}

.divider1_old {
  width: 100%;
  background-color: #303c45;
  text-align: center;
  padding: 30px 0px 40px 0px;
  color: #fff;
  border-bottom: 15px solid #fff;
}

/* VIDEO SECTION */
.dividerVideos {
  height: 199px;
  text-align: center;
  color: #fff;
  border-bottom: 15px solid #fff;
  overflow: hidden;
}
#mainVideo {
  position: relative;
  width: 100%;
  height: 560px;
  padding-top: 46px;
  background: linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)),
    url(https://d35408cyocioye.cloudfront.net/new2/video.jpg);
  background-size: cover;
  background-repeat: no-repeat;
}
#mainVideo:hover {
  cursor: pointer;
}
#mainVideo:hover > .integratorTopPlayButton {
  cursor: pointer;
  background-position: 0px 51px;
}
.dividerVideo1,
.dividerVideo2,
.dividerVideo3 {
  float: left;
  width: calc(33% - 3px);
  margin-right: 10px;
  margin-bottom: 10px;
}

/* BLOCK 4 - EFFORTLESS */
.section4rows {
  width: 100%;
  height: 350px;
  background-color: #f9f9f9;
}
.section4boxes {
  height: 350px;
  width: 33%;
  float: left;
}
.section4text {
  font-size: 11px;
  margin-top: 15px;
  color: #fff;
}
.section4boxes:hover > .section4text {
  opacity: 0;
}
.section4quote {
  color: #188efa;
  background-color: #fff;
  padding: 10px;
  border-radius: 8px;
  width: 200px;
  font-style: italic;
  position: relative;
  opacity: 0;
  margin-top: -72px;
  left: 0;
  right: 0;
  margin: 0 auto;
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
}
.section4boxes:hover > .section4quote {
  margin-top: 135px;
  opacity: 1;
}

/* Top Left */
.section4topLeft {
  background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url(https://d35408cyocioye.cloudfront.net/legacy/new2/effortless/sectional1.jpg)
      no-repeat;
  background-size: cover;
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
  border-bottom: 2px solid #fff;
  border-right: 2px solid #fff;
}
.section4topLeft:hover {
  background: url(https://d35408cyocioye.cloudfront.net/legacy/new2/effortless/sectional1.jpg)
    no-repeat;
  background-size: cover;
}
.section4mic1 {
  position: absolute;
  background: url(https://d35408cyocioye.cloudfront.net/legacy/new2/effortless/mic-button.png)
    no-repeat;
  background-position: 0px 0px;
  height: 50px;
  width: 50px;
  left: calc(16.5% - 20px);
  margin-top: 105px;
  -webkit-transition: margin-top 0.5s;
  transition: margin-top 0.5s;
}
.section4topLeft:hover > .section4mic1 {
  background-position: 0px -50px;
  margin-top: 10px;
}

/* Top Middle */
.section4topMiddle {
  background-color: #fff;
  border-bottom: 2px solid #fff;
  padding-top: 100px;
  height: 240px;
}

/* Top Right */
.section4topRight {
  background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url(https://d35408cyocioye.cloudfront.net/legacy/new2/effortless/sectional2.jpg)
      no-repeat;
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
  background-size: cover;
  border-bottom: 2px solid #fff;
  border-left: 2px solid #fff;
}
.section4topRight:hover {
  background: url(https://d35408cyocioye.cloudfront.net/legacy/new2/effortless/sectional2.jpg)
    no-repeat;
  background-size: cover;
}
.section4mic2 {
  position: absolute;
  background: url(https://d35408cyocioye.cloudfront.net/legacy/new2/effortless/mic-button.png)
    no-repeat;
  background-position: 0px 0px;
  height: 50px;
  width: 50px;
  left: calc(82.5% - 20px);
  margin-top: 105px;
  -webkit-transition: margin-top 0.5s;
  transition: margin-top 0.5s;
}
.section4mic1:hover,
.section4mic2:hover,
.section4mic3:hover,
.section4mic4:hover,
.section4mic5:hover {
  cursor: pointer;
}
.section4topRight:hover > .section4mic2 {
  background-position: 0px -50px;
  margin-top: 10px;
}

/* Bottom Left */
.section4bottomLeft {
  background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url(https://d35408cyocioye.cloudfront.net/legacy/new2/effortless/sectional5.jpg)
      no-repeat;
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
  background-size: cover;
  border-right: 2px solid #fff;
}
.section4bottomLeft:hover {
  background: url(https://d35408cyocioye.cloudfront.net/legacy/new2/effortless/sectional5.jpg)
    no-repeat;
  background-size: cover;
}
.section4mic3 {
  position: absolute;
  background: url(https://d35408cyocioye.cloudfront.net/legacy/new2/effortless/mic-button.png)
    no-repeat;
  background-position: 0px 0px;
  height: 50px;
  width: 50px;
  left: calc(16.5% - 20px);
  margin-top: 105px;
  -webkit-transition: margin-top 0.5s;
  transition: margin-top 0.5s;
}
.section4bottomLeft:hover > .section4mic3 {
  background-position: 0px -50px;
  margin-top: 10px;
}

/* Bottom Middle */
.section4bottomMiddle {
  background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url(https://d35408cyocioye.cloudfront.net/legacy/new2/effortless/sectional4.jpg)
      no-repeat;
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
  background-size: cover;
}
.section4bottomMiddle:hover {
  background: url(https://d35408cyocioye.cloudfront.net/legacy/new2/effortless/sectional4.jpg)
    no-repeat;
  background-size: cover;
}
.section4mic4 {
  position: absolute;
  background: url(https://d35408cyocioye.cloudfront.net/legacy/new2/effortless/mic-button.png)
    no-repeat;
  background-position: 0px 0px;
  height: 50px;
  width: 50px;
  left: calc(49.5% - 20px);
  margin-top: 105px;
  -webkit-transition: margin-top 0.5s;
  transition: margin-top 0.5s;
}
.section4bottomMiddle:hover > .section4mic4 {
  background-position: 0px -50px;
  margin-top: 10px;
}

/* Bottom Right */
.section4bottomRight {
  background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url(https://d35408cyocioye.cloudfront.net/legacy/new2/effortless/sectional3.jpg)
      no-repeat;
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
  background-size: cover;
  border-left: 2px solid #fff;
}
.section4bottomRight:hover {
  background: url(https://d35408cyocioye.cloudfront.net/legacy/new2/effortless/sectional3.jpg)
    no-repeat;
  background-size: cover;
}
.section4mic5 {
  position: absolute;
  background: url(https://d35408cyocioye.cloudfront.net/legacy/new2/effortless/mic-button.png)
    no-repeat;
  background-position: 0px 0px;
  height: 50px;
  width: 50px;
  left: calc(82.5% - 20px);
  margin-top: 105px;
  -webkit-transition: margin-top 0.5s;
  transition: margin-top 0.5s;
}
.section4bottomRight:hover > .section4mic5 {
  background-position: 0px -50px;
  margin-top: 10px;
}

/* DIVIDER 2 */
.divider2 {
  height: 120px;
  width: 100%;
  background-color: #f9f9f9;
  padding-top: 30px;
}
.divider2text {
  font-size: 11px;
  margin-bottom: 20px;
  color: #555;
}
.divider2list {
  height: 86px;
}
.divider2icons {
  height: 50px;
  width: 50px;
  display: inline;
  list-style-type: none;
  padding-right: 80px;
  padding-bottom: 30px;
  -webkit-transition: padding 0.5s;
  transition: padding 0.5s;
}
.divider2icons1 {
  background: url(https://d35408cyocioye.cloudfront.net/legacy/new2/buffericons/josh-works-with-thermostat-stacked.png)
    no-repeat;
  background-position: 0px -50px;
}
.divider2icons2 {
  background: url(https://d35408cyocioye.cloudfront.net/legacy/new2/buffericons/josh-works-with-lights-stacked.png)
    no-repeat;
  background-position: 0px -50px;
}
.divider2icons3 {
  background: url(https://d35408cyocioye.cloudfront.net/legacy/new2/buffericons/josh-works-with-cameras-stacked.png)
    no-repeat;
  background-position: 0px -50px;
}
.divider2icons4 {
  background: url(https://d35408cyocioye.cloudfront.net/legacy/new2/buffericons/josh-works-with-music-stacked.png)
    no-repeat;
  background-position: 0px -50px;
}
.divider2icons5 {
  background: url(https://d35408cyocioye.cloudfront.net/legacy/new2/buffericons/josh-works-with-entertain-stacked.png)
    no-repeat;
  background-position: 0px -50px;
}
.divider2icons6 {
  background: url(https://d35408cyocioye.cloudfront.net/legacy/new2/buffericons/josh-works-with-security-stacked.png)
    no-repeat;
  background-position: 0px -50px;
}
.divider2icons7 {
  background: url(https://d35408cyocioye.cloudfront.net/legacy/new2/buffericons/josh-works-with-sensors-stacked.png)
    no-repeat;
  background-position: 0px -50px;
}
.divider2icons8 {
  background: url(https://d35408cyocioye.cloudfront.net/legacy/new2/buffericons/josh-works-with-outdoors-stacked.png)
    no-repeat;
  background-position: 0px -50px;
}
.divider2icons9 {
  background: url(https://d35408cyocioye.cloudfront.net/legacy/new2/buffericons/josh-works-with-switches-stacked.png)
    no-repeat;
  background-position: 0px -50px;
}
.divider2icons10 {
  background: url(https://d35408cyocioye.cloudfront.net/legacy/new2/buffericons/josh-works-with-shades-stacked.png)
    no-repeat;
  background-position: 0px -50px;
}
.divider2icons11 {
  background: url(https://d35408cyocioye.cloudfront.net/legacy/new2/buffericons/josh-works-with-garage-stacked.png)
    no-repeat;
  background-position: 0px -50px;
}

.divider2icons:hover {
  background-position: 0px 0px;
}
.divider2listText {
  font-size: 11px;
  color: #555;
  position: absolute;
  margin-top: 62px;
  text-transform: uppercase;
  opacity: 0;
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
}
.divider2icons:hover > .divider2listText {
  opacity: 1;
}

/* DIVIDER 3 */

.divider3 {
  width: 100%;
  height: 70px;
  background-color: #fff;
  text-align: center;
  padding-top: 20px;
}

/* BLOCK 8 - BOTTOM */
#block8 {
  height: 600px;
  width: 100%;
  z-index: 100;
  margin-top: 1415px;
  margin-bottom: 180px;

  background: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)),
    url(https://d35408cyocioye.cloudfront.net/legacy/new2/alex-house-back-outside.jpg)
      no-repeat;
  background-size: cover;
  background-position: bottom;
}
.bottomLogo {
  position: absolute;
  left: 50%;
  margin-left: -80px;
  margin-top: 150px;
}
.bottomText {
  font-size: 30px;
  color: #fff;
  font-weight: 300;
  position: absolute;
  left: 0;
  right: 0;
  margin: auto auto;
  margin-top: 350px;
}
.bottomButton {
  background: url(https://d35408cyocioye.cloudfront.net/legacy/new2/footer/sectional-footer-get-josh-button.png)
    no-repeat;
  height: 40px;
  width: 112px;
  font-size: 14px;
  color: #fff;
  padding-top: 12px;
  font-weight: 800;
  position: absolute;
  margin: auto;
  left: 0;
  right: 0;
  margin-top: 405px;
}
.bottomButton:hover {
  cursor: pointer;
  opacity: 0.9;
}

/* FOOTER */
.footer {
  background-color: #303c45;
  position: fixed;
  bottom: 0px;
  width: 100%;
  max-width: 1120px;
  height: 80px;
  z-index: 1;
}
#footerLinks {
  position: absolute;
  bottom: 20px;
  width: 100%;
  -webkit-transition: all 1s;
  transition: all 1s;
}
#footerList {
  margin: 10px 20px 10px 20px;
  padding: 0px;
  text-align: center;
}
#footerList li {
  display: inline;
  list-style-type: none;
  padding-right: 20px;
  color: #fff;
}
.footerText a {
  color: #fff;
  font-size: 12px;
  opacity: 1;
  -webkit-transition: all 0s;
  transition: all 0s;
}
.footerText a:hover {
  color: #ccc;
}
/* #emailform{
  background-color: #303c45;
  height: 39px;
  width: 233px;
  padding-left: 23px;
  font-size: 16px;
  color: #fff;
  border: 1px solid #fff;
  margin-top: 50px;
  padding-top: 0px;
  padding-right: 50px;
  border-radius: 50px;
}
#emailform:focus{
  outline-color: transparent;
  outline-style: none;
  color: #fff;
  background-color: RGBA(255,255,255,0.1);
} */
.submitForm {
  background: none;
  border: none;
  position: absolute;
  margin-left: -41px;
  margin-top: 59px;
  background: url(https://d35408cyocioye.cloudfront.net/legacy/new2/footer/sectional-footer-email-container-arrow.png)
    no-repeat;
  height: 20px;
  width: 11px;
  cursor: pointer;
}
#emailform:focus > .submitForm,
.submitForm:hover,
.submitForm:active,
.submitForm:focus {
  outline-color: transparent;
  outline-style: none;
  margin-left: -40px;
}
::-webkit-input-placeholder {
  color: #ccc;
}
:-moz-placeholder {
  /* Firefox 18- */
  color: #ccc;
}
::-moz-placeholder {
  /* Firefox 19+ */
  color: #ccc;
}
:-ms-input-placeholder {
  color: #ccc;
}

/* ****************************** */
/* BASE */
/* ****************************** */

#newbase-content {
  position: relative;
  z-index: 10;
  min-height: 100px;
  background-color: #fff;
}
#newbase-bottom-margin {
  height: 1px;
  margin-top: 2014px;
  position: inherit;
  z-index: -1;
  margin-bottom: 180px;
}

/* .enterText {
  color: #fff;
  font-size: 11px;
  margin-top: 5px;
} */

/* ****************************** */
/* USER FORMS */
/* ****************************** */

#new-login-form {
  padding-top: 100px;
  height: 600px;
  width: 100%;
  background: url(https://d35408cyocioye.cloudfront.net/legacy/new2/login-page-body-background2.jpg)
    no-repeat;
  background-size: cover;
  margin-bottom: 80px;
  border-bottom: 15px solid #fff;
}
.loginCont {
  position: absolute;
  background-color: #fff;
  width: 300px;
  padding: 50px;
  top: 50%;
  margin-top: -230px;
  opacity: 0;
  left: 50%;
  margin-left: -200px;
  border-radius: 2px;
  -webkit-transition: all 0.5s;
  transition: all 0.5s;

  -webkit-box-shadow: 0 0px 9px -4px rgba(0, 0, 0, 0.44);
  -moz-box-shadow: 0 0px 9px -4px rgba(0, 0, 0, 0.44);
  box-shadow: 0 0px 9px -4px rgba(0, 0, 0, 0.44);
}
#loginTitle {
  margin: auto;
  opacity: 0;
  font-size: 35px;
  font-weight: 300;
  color: #555;
  letter-spacing: 0.02em;
  margin-bottom: 20px;
  -webkit-transition: opacity 2s;
  transition: opacity 2s;
}
#loginText {
  margin: auto;
  font-size: 13px;
  color: #666;
  line-height: 1.6;
}
.loginTextTop {
  opacity: 0;
  -webkit-transition: opacity 2s;
  transition: opacity 2s;
}
#loginForm {
  margin-top: 10px;
  opacity: 0;
  -webkit-transition: opacity 2s;
  transition: opacity 2s;
}
#loginTerms {
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
  opacity: 0;
  -webkit-transition: opacity 2s;
  transition: opacity 2s;
}
#registerTerms {
  width: 25%;
  margin: auto;
  padding-bottom: 20px;
  display: flex;
  justify-content: space-between;
}
#loginForm input,
#forgotPasswordForm input {
  border-radius: 50px;
  border: 1px solid #008cff;
  width: 260px;
  height: 40px;
  background-position: 0px 0px;
  padding-left: 20px;
  padding-right: 20px;
  margin-top: 20px;
}
#loginForm input:active,
#loginForm input:focus,
#forgotPasswordForm input:active,
#forgotPasswordForm input:focus {
  outline-color: transparent;
  outline-style: none;
}
#loginForm input[type="submit"],
#forgotPasswordForm input[type="submit"] {
  background-color: #008cff;
  border-radius: 50px;
  border: none;
  color: #fff;
  width: 121px;
  letter-spacing: 2px;
  font-size: 15px;
  text-transform: uppercase;
  font-weight: 800;
  font-family: "Lato", sans-serif;
  padding-top: 2px;
}
#loginForm input[type="submit"]:hover,
#forgotPasswordForm input[type="submit"]:hover {
  background-color: #0069bf;
  cursor: pointer;
}
#loginForm label,
.text-right {
  display: none;
}

#forgotPasswordTitle {
  margin: auto;
  font-size: 35px;
  font-weight: 300;
  color: #555;
  letter-spacing: 0.02em;
  margin-bottom: 20px;
  -webkit-transition: opacity 2s;
  transition: opacity 2s;
}
#forgotPasswordText {
  margin: auto;
  font-size: 13px;
  color: #666;
  line-height: 1.6;
}

#changePasswordForm input {
  background: url(https://d35408cyocioye.cloudfront.net/legacy/new2/sectional-footer-email-stack.png)
    no-repeat;
  width: 260px;
  height: 40px;
  background-position: 0px 0px;
  border: none;
  padding-left: 20px;
  padding-right: 20px;
  margin-top: 20px;
  outline: none;
}
#changePasswordForm input[type="submit"] {
  background: url(https://d35408cyocioye.cloudfront.net/legacy/new2/contact/email-button-four-stack.png)
    no-repeat;
  background-position: 0px -40px;
  color: #fff;
  width: 121px;
  font-size: 12px;
  text-transform: uppercase;
  font-weight: 800;
  font-family: "Lato", sans-serif;
  padding-top: 4px;
  margin-top: 20px;
}
#changePasswordForm input[type="submit"]:hover {
  background: url(https://d35408cyocioye.cloudfront.net/legacy/new2/contact/email-button-four-stack.png)
    no-repeat;
  background-position: 0px -80px;
  cursor: pointer;
}

/* ****************************** */
/* ABOUT PAGE */
/* ****************************** */
#aboutTopSection {
  background: linear-gradient(
      rgba(255, 255, 255, 0.4),
      rgba(2555, 255, 2555, 0.4)
    ),
    url(https://d35408cyocioye.cloudfront.net/legacy/team/about-header-background.jpg)
      no-repeat;
  background-size: cover;
  width: 100%;
  padding: 110px 0px 70px 0px;
  -webkit-transition: margin 1s;
  transition: margin 1s;
}
.aboutManifesto {
  width: 300px;
  height: 31px;
  background: url(https://d35408cyocioye.cloudfront.net/legacy/team/about-header-manifesto-button-stacked.png)
    no-repeat;
  background-position: 0px 0px;
  margin: auto;
  margin-top: 40px;
  padding-top: 9px;
  color: #fff;
  -webkit-transition: opacity 1s;
  transition: opacity 1s;
  opacity: 0;
}
.aboutManifesto:hover {
  background-position: 0px -40px;
  cursor: pointer;
}
#aboutBody {
  width: 100%;
  padding: 40px 0px 0px 0px;
  -webkit-transition: margin 1s;
  transition: margin 1s;
}
#aboutTitle {
  margin: auto;
  opacity: 0;
  font-size: 35px;
  font-weight: 300;
  color: #555;
  letter-spacing: 0.02em;
  margin-bottom: 20px;
  -webkit-transition: opacity 2s;
  transition: opacity 2s;
}
#aboutText {
  margin: auto;
  font-size: 12px;
  color: #888;
  line-height: 1.6;
}
.aboutTextTop {
  opacity: 0;
  -webkit-transition: opacity 2s;
  transition: opacity 2s;
}
.aboutLocations {
  height: 250px;
  width: 100%;
  text-transform: center;
  margin-bottom: 160px;
}
.aboutLocationsLeft {
  width: 50%;
  float: left;
  padding-top: 25px;
}
.aboutLocationsRight {
  width: 50%;
  margin-left: 50%;
  padding-top: 25px;
}
.aboutLocationCity {
  font-size: 16px;
  color: #3ca4fc;
  margin-bottom: 20px;
  margin-top: 10px;
  font-weight: 800;
}
.aboutLocationAddress {
  font-size: 13px;
  color: #777;
}
.aboutLocationContact a {
  font-size: 11px;
  margin-top: 10px;
  color: #555;
}
.aboutLocationsDivider {
  position: absolute;
  margin-left: 50%;
  margin-top: 10px;
}
#teamBody {
  margin-top: 20px;
  width: 100%;
  padding: 0px;
  color: #fff;
  /*display: none;*/
}
.teamEmojis {
  margin-top: 5px;
  -webkit-transition: margin 1s;
  transition: margin 1s;
}
.teamEmojis a {
  opacity: 1;
}
#navTeam {
  padding: 0px;
  margin: 0px;
}
#navTeam li {
  display: inline;
  list-style-type: none;
  padding: 0px;
}
.teamSection {
  height: 300px;
  width: 100%;
  background-color: #f9f9f9;
  max-width: 1200px;
  margin: auto;
  margin-bottom: 15px;
}
.aboutLeftPhoto {
  height: 300px;
  width: 300px;
  float: left;
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
}
.aboutRightPhoto {
  height: 300px;
  width: 300px;
  float: right;
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
}
.aboutPhotoState {
  margin-top: 100px;
  margin-bottom: 20px;
  display: none;
}
.aboutPhotoText {
  font-size: 10px;
  margin-bottom: 5px;
  padding-left: 20px;
  padding-right: 20px;
  display: none;
}
.aboutPhotoText a {
  font-size: 10px;
  font-weight: 800;
}
.aboutLeftPhoto:hover > .aboutPhotoState,
.aboutLeftPhoto:hover > .aboutPhotoText,
.aboutRightPhoto:hover > .aboutPhotoState,
.aboutRightPhoto:hover > .aboutPhotoText {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.aboutLeftText {
  padding: 50px;
  margin-right: 300px;
  margin-left: 15px;
}
.aboutRightText {
  padding: 50px;
  margin-left: 300px;
  margin-right: 15px;
  max-width: 900px;
}
.aboutTeamName {
  font-size: 39px;
  font-weight: 100;
  color: #333;
  margin-bottom: 25px;
  margin-top: 20px;
}
.aboutTeamText {
  font-size: 13px;
  color: #777;
  text-align: justify;
  line-height: 1.4;
}
.aboutTeamText a {
  color: #333;
  font-size: 13px;
}
.teamPhotoAlex {
  background: url(https://d35408cyocioye.cloudfront.net/legacy/team/alex.jpg)
    no-repeat;
  background-size: cover;
}
.teamPhotoAlex:hover {
  background: url(https://d35408cyocioye.cloudfront.net/legacy/team/about-page-alex-hover.jpg)
    no-repeat;
  background-size: cover;
}

.teamPhotoTim {
  background: url(https://d35408cyocioye.cloudfront.net/legacy/team/tim.jpg) top
    left no-repeat;
  background-size: cover;
}
.teamPhotoTim:hover {
  background: url(https://d35408cyocioye.cloudfront.net/legacy/team/about-page-tim-hover.jpg)
    no-repeat;
  background-size: cover;
}

.teamPhotoJason {
  background: url(https://d35408cyocioye.cloudfront.net/legacy/team/jason.jpg)
    top left no-repeat;
  background-size: cover;
}
.teamPhotoJason:hover {
  background: url(https://d35408cyocioye.cloudfront.net/legacy/team/about-page-jason-hover.jpg.jpg)
    no-repeat;
  background-size: cover;
}

.teamPhotoNader {
  background: url(https://d35408cyocioye.cloudfront.net/legacy/team/about-page-nader.jpg)
    center left no-repeat;
  background-size: cover;
}
.teamPhotoNader:hover {
  background: url(https://d35408cyocioye.cloudfront.net/legacy/team/about-page-nader-hover.jpg)
    no-repeat;
  background-size: cover;
}

.teamPhotoNate {
  background: url(https://d35408cyocioye.cloudfront.net/legacy/team/about-page-nate.jpg)
    top left no-repeat;
  background-size: cover;
}
.teamPhotoNate:hover {
  background: url(https://d35408cyocioye.cloudfront.net/legacy/team/about-page-nate-hover.jpg)
    no-repeat;
  background-size: cover;
}

.teamPhotoMyers {
  background: url(https://d35408cyocioye.cloudfront.net/legacy/team/about-page-tmyers.jpg)
    center left no-repeat;
  background-size: cover;
}
.teamPhotoMyers:hover {
  background: url(https://d35408cyocioye.cloudfront.net/legacy/team/about-page-tmyers-hover.jpg)
    no-repeat;
  background-size: cover;
}

.teamPhotoAtticus {
  background: url(https://d35408cyocioye.cloudfront.net/legacy/team/atticus.jpg)
    top left no-repeat;
  background-size: cover;
}
.teamPhotoAtticus:hover {
  background: url(https://d35408cyocioye.cloudfront.net/legacy/team/about-page-atticus-hover.jpg)
    no-repeat;
  background-size: cover;
}

.teamPhotoBenji {
  background: url(https://d35408cyocioye.cloudfront.net/legacy/team/about-page-benji.jpg)
    top left no-repeat;
  background-size: cover;
}
.teamPhotoBenji:hover {
  background: url(https://d35408cyocioye.cloudfront.net/legacy/team/about-page-benji-hover.jpg)
    no-repeat;
  background-size: cover;
}

.teamPhotoSonny {
  background: url(https://d35408cyocioye.cloudfront.net/legacy/team/about-page-sonny.jpg)
    top left no-repeat;
  background-size: cover;
}
.teamPhotoSonny:hover {
  background: url(https://d35408cyocioye.cloudfront.net/legacy/team/about-page-sonny-hover.jpg)
    no-repeat;
  background-size: cover;
}

.teamPhotoPaul {
  background: url(https://d35408cyocioye.cloudfront.net/legacy/team/about-page-paul.jpg)
    top left no-repeat;
  background-size: cover;
}
.teamPhotoPaul:hover {
  background: url(https://d35408cyocioye.cloudfront.net/legacy/team/about-page-paul-hover.jpg)
    no-repeat;
  background-size: cover;
}

.teamPhotoBridget {
  background: url(https://d35408cyocioye.cloudfront.net/legacy/team/team-page-bridget.jpg)
    top left no-repeat;
  background-size: cover;
}
.teamPhotoBridget:hover {
  background: url(https://d35408cyocioye.cloudfront.net/legacy/team/team-page-bridget-hover.jpg)
    no-repeat;
  background-size: cover;
}

.teamPhotoStephen {
  background: url(https://d35408cyocioye.cloudfront.net/legacy/new2/team-page-stephen.jpg)
    top left no-repeat;
  background-size: cover;
}
.teamPhotoStephen:hover {
  background: url(https://d35408cyocioye.cloudfront.net/legacy/new2/team-page-stephen-hover.jpg)
    no-repeat;
  background-size: cover;
}

.teamPhotoSchuyler {
  background: url(https://d35408cyocioye.cloudfront.net/legacy/new2/team-page-schuyler.jpg)
    top left no-repeat;
  background-size: cover;
}
.teamPhotoSchuyler:hover {
  background: url(https://d35408cyocioye.cloudfront.net/legacy/new2/team-page-schuyler-hover.jpg)
    no-repeat;
  background-size: cover;
}

.teamPhotoScotty {
  background: url(https://d35408cyocioye.cloudfront.net/legacy/new2/team-page-scotty.jpg)
    top left no-repeat;
  background-size: cover;
}
.teamPhotoScotty:hover {
  background: url(https://d35408cyocioye.cloudfront.net/legacy/new2/team-page-scotty-hover.jpg)
    no-repeat;
  background-size: cover;
}

.teamPhotoEd {
  background: url(https://d35408cyocioye.cloudfront.net/legacy/new2/team-page-ed.jpg)
    top left no-repeat;
  background-size: cover;
}
.teamPhotoEd:hover {
  background: url(https://d35408cyocioye.cloudfront.net/legacy/new2/team-page-ed-hover.jpg)
    no-repeat;
  background-size: cover;
}

.teamPhotoCasey {
  background: url(https://d35408cyocioye.cloudfront.net/legacy/new2/team-page-casey.jpg)
    top left no-repeat;
  background-size: cover;
}
.teamPhotoCasey:hover {
  background: url(https://d35408cyocioye.cloudfront.net/legacy/new2/team-page-casey-hover.jpg)
    no-repeat;
  background-size: cover;
}
.teamPhotoMichaelG {
  background: url(https://d35408cyocioye.cloudfront.net/legacy/new2/team-page-michael-g.jpg)
    top left no-repeat;
  background-size: cover;
}
.teamPhotoMichaelG:hover {
  background: url(https://d35408cyocioye.cloudfront.net/legacy/new2/team-page-michael-g-hover.jpg)
    no-repeat;
  background-size: cover;
}
.teamPhotoBrian {
  background: url(https://d35408cyocioye.cloudfront.net/legacy/new2/team-page-brianh.jpg)
    top left no-repeat;
  background-size: cover;
}
.teamPhotoBrian:hover {
  background: url(https://d35408cyocioye.cloudfront.net/legacy/new2/team-page-brianh-hover.jpg)
    no-repeat;
  background-size: cover;
}
.teamPhotoDerek {
  background: url(https://d35408cyocioye.cloudfront.net/legacy/new2/team-page-derekm.jpg)
    top left no-repeat;
  background-size: cover;
}
.teamPhotoDerek:hover {
  background: url(https://d35408cyocioye.cloudfront.net/legacy/new2/team-page-derekm-hover.jpg)
    no-repeat;
  background-size: cover;
}
.teamPhotoDylan {
  background: url(https://d35408cyocioye.cloudfront.net/legacy/team/team-page-dylan.jpg)
    top left no-repeat;
  background-size: cover;
}
.teamPhotoDylan:hover {
  background: url(https://d35408cyocioye.cloudfront.net/legacy/team/team-page-dylan-hover.jpg)
    no-repeat;
  background-size: cover;
}
.teamPhotoKevin {
  background: url(https://d35408cyocioye.cloudfront.net/legacy/team/team-page-kevin.png)
    top left no-repeat;
  background-size: cover;
}
.teamPhotoKevin:hover {
  background: url(https://d35408cyocioye.cloudfront.net/legacy/team/team-page-kevin-hover.png)
    no-repeat;
  background-size: cover;
}
.teamPhotoMukesh {
  background: url(https://d35408cyocioye.cloudfront.net/legacy/team/team-page-mukesh.jpg)
    top left no-repeat;
  background-size: cover;
}
.teamPhotoMukesh:hover {
  background: url(https://d35408cyocioye.cloudfront.net/legacy/team/team-page-mukesh-hover.jpg)
    no-repeat;
  background-size: cover;
}
.teamPhotoAaron {
  background: url(https://d35408cyocioye.cloudfront.net/legacy/team/team-page-aarona.png)
    top left no-repeat;
  background-size: cover;
}
.teamPhotoAaron:hover {
  background: url(https://d35408cyocioye.cloudfront.net/legacy/team/team-page-aarona-hover.png)
    no-repeat;
  background-size: cover;
}
.teamPhotoSaina {
  background: url(https://d35408cyocioye.cloudfront.net/legacy/team/team-page-saina.jpg)
    top left no-repeat;
  background-size: cover;
}
.teamPhotoSaina:hover {
  background: url(https://d35408cyocioye.cloudfront.net/legacy/team/team-page-saina-hover.jpg)
    no-repeat;
  background-size: cover;
}
.teamPhotoJack {
  background: url(https://d35408cyocioye.cloudfront.net/legacy/team/about-page-jack.jpg)
    top left no-repeat;
  background-size: cover;
}
.teamPhotoJack:hover {
  background: url(https://d35408cyocioye.cloudfront.net/legacy/team/about-page-jack-hover.jpg)
    no-repeat;
  background-size: cover;
}
.teamPhotoJonathan {
  background: url(https://d35408cyocioye.cloudfront.net/legacy/team/team-page-jonathan.png)
    top left no-repeat;
  background-size: cover;
}
.teamPhotoJonathan:hover {
  background: url(https://d35408cyocioye.cloudfront.net/legacy/team/team-page-jonathan-hover.png)
    no-repeat;
  background-size: cover;
}
.teamPhotoBryan {
  background: url(https://d35408cyocioye.cloudfront.net/legacy/team/team-page-bryan.png)
    top left no-repeat;
  background-size: cover;
}
.teamPhotoBryan:hover {
  background: url(https://d35408cyocioye.cloudfront.net/legacy/team/team-page-bryan-hover.png)
    no-repeat;
  background-size: cover;
}

#teamEmojis {
  padding: 0px;
  -webkit-transition: margin 1s;
  transition: margin 1s;
}
ul#teamEmojis li {
  display: inline;
}
#teamEmojis a {
  opacity: 1;
  -webkit-transition: none;
  transition: none;
  margin-right: 5px;
}
#teamEmojis a:hover {
  opacity: 1;
  -ms-transform: rotate(14deg); /* IE 9 */
  -webkit-transform: rotate(14deg); /* Chrome, Safari, Opera */
  transform: rotate(14deg);
  display: inline-table;
}
#aboutFooter {
  color: #fff;
  background-color: #3ca4fc;
  width: 80%;
  height: 140px;
  margin-top: 0px;
  padding: 50px 0px 0px 0px;
  font-size: 14px;
  border-bottom: 15px solid #fff;
  max-width: 1200px;
  margin: auto;
}
.aboutFooterButton {
  height: 32px;
  width: 120px;
  background: url(https://d35408cyocioye.cloudfront.net/legacy/team/other-sections-footer-email-button-stacked.png)
    no-repeat;
  background-position: 0px 0px;
  padding-top: 8px;
  margin: auto;
  margin-top: 20px;
}
.aboutFooterButton:hover {
  background-position: 0px -40px;
  cursor: pointer;
}
.recsFooterLink {
  color: #fff;
  font-size: 12px;
  margin-top: 11px;
}
.aboutFooterButton:hover .recsFooterLink {
  color: #3ca4fc;
  cursor: pointer;
}

.aboutTopLeft {
  float: left;
  width: calc(50% - 7.5px);
  background: url(https://d35408cyocioye.cloudfront.net/new2/about/team_hover.jpg)
    no-repeat;
  background-size: 100% 100%;
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
}
.aboutTopLeft:hover {
  background: url(https://d35408cyocioye.cloudfront.net/new2/about/team.jpg)
    no-repeat;
  background-size: 105% 105%;
}
.aboutTopRight {
  float: right;
  width: calc(50% - 7.5px);
  background: url(https://d35408cyocioye.cloudfront.net/new2/about/press_hover.jpg)
    no-repeat;
  background-size: 100% 100%;
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
}
.aboutTopRight:hover {
  background: url(https://d35408cyocioye.cloudfront.net/new2/about/press.jpg)
    no-repeat;
  background-size: 105% 105%;
}
.aboutBottomLeft {
  float: left;
  margin-top: 15px;
  width: calc(50% - 7.5px);
  background: url(https://d35408cyocioye.cloudfront.net/new2/about/faqs_hover.jpg)
    no-repeat;
  background-size: 100% 100%;
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
}
.aboutBottomLeft:hover {
  background: url(https://d35408cyocioye.cloudfront.net/new2/about/faqs.jpg)
    no-repeat;
  background-size: 105% 105%;
}
.aboutBottomRight {
  float: right;
  margin-top: 15px;
  width: calc(50% - 7.5px);
  background: url(https://d35408cyocioye.cloudfront.net/new2/about/contact_hover.jpg)
    no-repeat;
  background-size: 100% 100%;
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
}
.aboutBottomRight:hover {
  background: url(https://d35408cyocioye.cloudfront.net/new2/about/contact.jpg)
    no-repeat;
  background-size: 105% 105%;
}
.aboutSquareText {
  height: 160px;
  padding-top: 110px;
  color: #fff;
  font-size: 40px;
  font-weight: 300;
}

/* ****************************** */
/* PRESS PAGE */
/* ****************************** */
#pressTopSection {
  background: linear-gradient(
      rgba(255, 255, 255, 0.4),
      rgba(2555, 255, 2555, 0.4)
    ),
    url(https://d35408cyocioye.cloudfront.net/legacy/press-header-background.jpg)
      no-repeat;
  background-size: cover;
  width: 100%;
  padding: 80px 0px 50px 0px;
  -webkit-transition: margin 1s;
  transition: margin 1s;
}
#navPress {
  color: #333;
  padding: 0px;
  margin: 0px;
  opacity: 0;
  -webkit-transition: opacity 1s;
  transition: opacity 1s;
}
#pressBody {
  width: 100%;
  padding-top: 20px;
  -webkit-transition: margin 1s;
  transition: margin 1s;
}
#pressText {
  margin: auto;
  font-size: 15px;
  color: #888;
  line-height: 1.6;
  margin-bottom: 40px;
  padding-left: 13%;
  padding-right: 13%;
  margin-top: 20px;
}
.pressGridObject {
  width: calc(25% - 38px);
  height: 130px;
  background-color: #f9f9f9;
  float: left;
  margin-right: 10px;
  margin-bottom: 10px;
  color: #333;
  font-size: 12px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 10px;
  padding-left: 20px;
}
.pressGridObject a {
  color: #88aac4;
}
.pressGridObject a:hover {
  color: #3ca4fc;
}
.pressGrid2 {
  margin-right: 10px;
}
.pressGrid3 {
  margin-right: 10px;
}
.pressGrid4 {
  margin-right: 0px;
}
.pressGridTitles {
  font-weight: 500;
  opacity: 0.8;
  margin-top: 5px;
}
.pressGridDate {
  font-size: 9px;
  color: #fff;
  font-weight: 800;
  margin-top: 10px;
  text-transform: uppercase;
}
.pressImage {
  width: 60%;
  display: block;
  margin: auto;
  margin-bottom: 20px;
  border-radius: 4px;
  opacity: 0;
  -webkit-transition: opacity 1s;
  transition: opacity 1s;

  -webkit-box-shadow: 2px 3px 8px -4px rgba(0, 0, 0, 0.74);
  -moz-box-shadow: 2px 3px 8px -4px rgba(0, 0, 0, 0.74);
  box-shadow: 2px 3px 8px -4px rgba(0, 0, 0, 0.74);
}
.pressapplepodcast {
  background: url(https://joshai.s3.amazonaws.com/legacy/press-bg/applepodcasts.png)
    no-repeat;
}
.pressstereoplanet {
  background: url(https://joshai.s3.amazonaws.com/legacy/press-bg/stereoplanet.png)
    no-repeat;
}
.pressrelativehomesystems {
  background: url(https://joshai.s3.amazonaws.com/legacy/press-bg/relative.png)
    no-repeat;
}
.presspcmag {
  background: url(https://d35408cyocioye.cloudfront.net/legacy/press-bg/pressbg_pcmag.jpg)
    no-repeat;
}
.presssmashd {
  background: url(https://d35408cyocioye.cloudfront.net/legacy/press-bg/pressbg_smashd.jpg)
    no-repeat;
}
.pressconnectedlifestyle {
  background: url(https://d35408cyocioye.cloudfront.net/legacy/press-bg/pressbg_connectedlifestyle.jpg)
    no-repeat;
}
.presssmarthomeshow {
  background: url(https://d35408cyocioye.cloudfront.net/legacy/press-bg/pressbg_smarthomeshow.jpg)
    no-repeat;
}
.presstwice {
  background: url(https://d35408cyocioye.cloudfront.net/legacy/press-bg/pressbg_twice.jpg)
    no-repeat;
}
.presscb {
  background: url(https://joshai.s3.amazonaws.com/legacy/press-bg/pressbg_crunchbase.png)
    no-repeat;
}
.pressvoicebotai {
  background: url(https://joshai.s3.amazonaws.com/legacy/press-bg/pressbg_voicebotai.jpg)
    no-repeat;
}
.presslatechwatch {
  background: url(https://d35408cyocioye.cloudfront.net/legacy/press-bg/pressbg_latechwatch.jpg)
    no-repeat;
}
.pressbuiltinco {
  background: url(https://d35408cyocioye.cloudfront.net/legacy/press-bg/pressbg_builtinco.jpg)
    no-repeat;
}
.pressproducthunt {
  background: url(https://d35408cyocioye.cloudfront.net/legacy/press-bg/pressbg_producthunt.jpg)
    no-repeat;
}
.presshtacertified {
  background: url(https://d35408cyocioye.cloudfront.net/legacy/press-bg/pressbg_htacertified.jpg)
    no-repeat;
}
.pressresidentialsystems {
  background: url(https://d35408cyocioye.cloudfront.net/legacy/press-bg/pressbg_residentialsystems.jpg)
    no-repeat;
}
.press5280magazines {
  background: url(https://d35408cyocioye.cloudfront.net/legacy/press-bg/pressbg_5280magazine.jpg)
    no-repeat;
}
.presscepro {
  background: url(https://d35408cyocioye.cloudfront.net/legacy/press-bg/pressbg_cepro.jpg)
    no-repeat;
}
.pressHospitalityNet {
  background: url(https://d35408cyocioye.cloudfront.net/legacy/press-bg/hospitality_net.svg)
    no-repeat;
}
.pressAVNirvana {
  background: url(https://d35408cyocioye.cloudfront.net/legacy/press-bg/av_nirvana.png)
    no-repeat;
}
.pressVoyageDenver {
  background: url(https://d35408cyocioye.cloudfront.net/legacy/press-bg/voyage_denver.jpeg)
    no-repeat;
}
.pressHotelManagement {
  background: url(https://d35408cyocioye.cloudfront.net/legacy/press-bg/hotel_management.svg)
    no-repeat;
}
.presspsfk {
  background: url(https://d35408cyocioye.cloudfront.net/legacy/press-bg/pressbg_psfk.jpg)
    no-repeat;
}
.pressfashnerd {
  background: url(https://d35408cyocioye.cloudfront.net/legacy/press-bg/pressbg_fashnerd.jpg)
    no-repeat;
}
.press4rfv {
  background: url(https://d35408cyocioye.cloudfront.net/legacy/press-bg/pressbg_4rfv.png)
    no-repeat;
}
.presswidescreen {
  background: url(https://d35408cyocioye.cloudfront.net/legacy/press-bg/pressbg_widescreen_review.png)
    no-repeat;
}
.pressnahb {
  background: url(https://d35408cyocioye.cloudfront.net/legacy/press-bg/pressbg_nahb_now.png)
    no-repeat;
}
.pressrestech {
  background: url(https://d35408cyocioye.cloudfront.net/legacy/press-bg/pressbg_res_tech_today.png)
    no-repeat;
}
.pressindiethink {
  background: url(https://d35408cyocioye.cloudfront.net/legacy/press-bg/pressbg_indie-think.png)
    no-repeat;
}
.pressessential {
  background: url(https://d35408cyocioye.cloudfront.net/legacy/press-bg/pressbg_essential_install.png)
    no-repeat;
}
.presssiw {
  background: url(https://d35408cyocioye.cloudfront.net/legacy/press-bg/pressbg_siw.png)
    no-repeat;
}
.pressopenpr {
  background: url(https://d35408cyocioye.cloudfront.net/legacy/press-bg/pressbg_open_pr.png)
    no-repeat;
}
.presspac {
  background: url(https://d35408cyocioye.cloudfront.net/legacy/press-bg/pressbg_pacific_audio_comms.png)
    no-repeat;
}
.pressght {
  background: url(https://d35408cyocioye.cloudfront.net/legacy/press-bg/pressbg_georgia_home_theater.png)
    no-repeat;
}
.pressscenester {
  background: url(https://d35408cyocioye.cloudfront.net/legacy/press-bg/pressbg_scenester.jpg)
    no-repeat;
}
.pressselectronichouse {
  background: url(https://d35408cyocioye.cloudfront.net/legacy/press-bg/pressbg_electronichouse.jpg)
    no-repeat;
}
.presssiotpodcast {
  background: url(https://d35408cyocioye.cloudfront.net/legacy/press-bg/pressbg_iotpodcast.jpg)
    no-repeat;
}
.pressyahoonews {
  background: url(https://d35408cyocioye.cloudfront.net/legacy/press-bg/pressbg_yahoonews.jpg)
    no-repeat;
}
.presssdigitaltrends {
  background: url(https://d35408cyocioye.cloudfront.net/legacy/press-bg/pressbg_digitaltrends.jpg)
    no-repeat;
}
.pressbgsmarthomewelt {
  background: url(https://d35408cyocioye.cloudfront.net/legacy/press-bg/pressbg_smarthomewelt.jpg)
    no-repeat;
}
.pressbgtechomebuilder {
  background: url(https://d35408cyocioye.cloudfront.net/legacy/press-bg/pressbg_techomebuilder.jpg)
    no-repeat;
}
.pressbgsdtimes {
  background: url(https://d35408cyocioye.cloudfront.net/legacy/press-bg/pressbg_sdtimes.jpg)
    no-repeat;
}
.pressivanestrada {
  background: url(https://d35408cyocioye.cloudfront.net/legacy/press-bg/pressbg_ivanestrada.jpg)
    no-repeat;
}
.pressphilharmonic {
  background: url(https://d35408cyocioye.cloudfront.net/legacy/press-bg/pressbg_philharmonic.png)
    no-repeat;
}
.presshomealarmreport {
  background: url(https://d35408cyocioye.cloudfront.net/legacy/press-bg/pressbg_homealarmreport.jpg)
    no-repeat;
}
.presshiddenwires {
  background: url(https://d35408cyocioye.cloudfront.net/legacy/press-bg/pressbg_hiddenwires.jpg)
    no-repeat;
}
.pressgizmag {
  background: url(https://d35408cyocioye.cloudfront.net/legacy/press-bg/pressbg_gizmag.jpg)
    no-repeat;
}
.pressyourconnectedfamily {
  background: url(https://d35408cyocioye.cloudfront.net/legacy/press-bg/pressbg_yourconnectedfamily.jpg)
    no-repeat;
}
.presseverythingaboutdesing {
  background: url(https://d35408cyocioye.cloudfront.net/legacy/press-bg/pressbg_everythingaboutdesign.jpg)
    no-repeat;
}
.pressworldiot {
  background: url(https://d35408cyocioye.cloudfront.net/legacy/press-bg/pressbg_worldiot.jpg)
    no-repeat;
}
.presstechnologyintegrator {
  background: url(https://d35408cyocioye.cloudfront.net/legacy/press-bg/pressbg_technologyintegrator.jpg)
    no-repeat;
}
.presstechdesigner {
  background: url(https://d35408cyocioye.cloudfront.net/legacy/press-bg/pressbg_technology_designer.png)
    no-repeat;
}
.presslinkedin {
  background: url(https://d35408cyocioye.cloudfront.net/legacy/press-bg/pressbg_linkedin.png)
    no-repeat;
}
.presscommunitec {
  background: url(https://d35408cyocioye.cloudfront.net/legacy/press-bg/pressbg_communitec.jpg)
    no-repeat;
}
.pressmobilegeeks {
  background: url(https://d35408cyocioye.cloudfront.net/legacy/press-bg/pressbg_mobilegeeks.jpg)
    no-repeat;
}
.presstechsmarthome {
  background: url(https://d35408cyocioye.cloudfront.net/legacy/press-bg/pressbg_techsmarthome.jpg)
    no-repeat;
}
.presssuperyachtnews {
  background: url(https://d35408cyocioye.cloudfront.net/legacy/press-bg/pressbg_superyachtnews.jpg)
    no-repeat;
}
.pressblogdaengenharia {
  background: url(https://d35408cyocioye.cloudfront.net/legacy/press-bg/pressbg_blogdaengenharia.jpg)
    no-repeat;
}
.presswdrbnews {
  background: url(https://d35408cyocioye.cloudfront.net/legacy/press-bg/pressbg_wdrbnews.jpg)
    no-repeat;
}
.pressrave {
  background: url(https://d35408cyocioye.cloudfront.net/legacy/press-bg/pressbg_rave.jpg)
    no-repeat;
}
.pressdealerscope {
  background: url(https://d35408cyocioye.cloudfront.net/legacy/press-bg/pressbg_dealerscope.jpg)
    no-repeat;
}
.presstechinsider {
  background: url(https://d35408cyocioye.cloudfront.net/legacy/press-bg/pressbg_technology_insider_group.jpg)
    no-repeat;
}
.pressssound_vision {
  background: url(https://d35408cyocioye.cloudfront.net/legacy/press-bg/pressbg_sound_vision.jpg)
    no-repeat;
}
.pressavinsider {
  background: url(https://d35408cyocioye.cloudfront.net/legacy/press-bg/pressbg_avinsider.jpg)
    no-repeat;
}
.presscedia {
  background: url(https://d35408cyocioye.cloudfront.net/legacy/press-bg/pressbg_cedia_360.jpg)
    no-repeat;
}
.pressyoutube {
  background: url(https://d35408cyocioye.cloudfront.net/legacy/press-bg/pressbg_youtube_360.jpg)
    no-repeat;
}
.presshometech {
  background: url(https://d35408cyocioye.cloudfront.net/legacy/press-bg/pressbg_hometech_360.jpg)
    no-repeat;
}
.presshuffingtonpost {
  background: url(https://d35408cyocioye.cloudfront.net/legacy/press-bg/pressbg_huffingtonpost_360.jpg)
    no-repeat;
}
.presscommercialintegrator {
  background: url(https://d35408cyocioye.cloudfront.net/legacy/press-bg/pressbg_commercialintegrator.jpg)
    no-repeat;
}
.pressavshoptalk {
  background: url(https://d35408cyocioye.cloudfront.net/legacy/press-bg/pressbg_avshoptalk.jpg)
    no-repeat;
}
.pressdenverite {
  background: url(https://d35408cyocioye.cloudfront.net/legacy/press-bg/pressbg_denverite.jpg)
    no-repeat;
}
.pressprotheticknowledge {
  background: url(https://d35408cyocioye.cloudfront.net/legacy/press-bg/pressbg_pk.jpg)
    no-repeat;
}
.pressinnovationtech {
  background: url(https://d35408cyocioye.cloudfront.net/legacy/press-bg/pressbg_innovation.jpg)
    no-repeat;
}
.pressihummingbird {
  background: url(https://d35408cyocioye.cloudfront.net/legacy/press-bg/pressbg_ihummingbird.jpg)
    no-repeat;
}
.presslatech {
  background: url(https://d35408cyocioye.cloudfront.net/legacy/press-bg/pressbg_latech.jpg)
    no-repeat;
}
.pressdandy {
  background: url(https://d35408cyocioye.cloudfront.net/legacy/press-bg/pressbg_dandy.jpg)
    no-repeat;
}
.pressavnation {
  background: url(https://d35408cyocioye.cloudfront.net/new2/press/pressbg_avnation.jpg)
    no-repeat;
}
.pressconvergent {
  background: url(https://d35408cyocioye.cloudfront.net/new2/press/pressbg_convergent.jpg)
    no-repeat;
}
.pressentrepreneur {
  background: url(https://d35408cyocioye.cloudfront.net/new2/press/pressbg_entrepreneur.jpg)
    no-repeat;
}
.pressinavate {
  background: url(https://d35408cyocioye.cloudfront.net/new2/press/pressbg_inavate.jpg)
    no-repeat;
}
.presscedia2 {
  background: url(https://d35408cyocioye.cloudfront.net/new2/press/pressbg_cedia.jpg)
    no-repeat;
}
.pressraveorig {
  background: url(https://d35408cyocioye.cloudfront.net/new2/press/pressbg_rave.jpg)
    no-repeat;
}
.pressforbes {
  background: url(https://d35408cyocioye.cloudfront.net/new2/press/pressbg_forbes.jpg)
    no-repeat;
}
.pressshowhomestyle {
  background: url(https://d35408cyocioye.cloudfront.net/new2/press/pressbg_slowhomestyle.jpg)
    no-repeat;
}
.pressbesttechie {
  background: url(https://d35408cyocioye.cloudfront.net/new2/press/pressbg_besttechie.jpg)
    no-repeat;
}
.pressprnewswire {
  background: url(https://d35408cyocioye.cloudfront.net/new2/press/pressbg_prnewswire.jpg)
    no-repeat;
}
.pressdenverbizjournal {
  background: url(https://d35408cyocioye.cloudfront.net/new2/press/pressbg_bizjournals.jpg)
    no-repeat;
}
.presstechcast {
  background: url(https://d35408cyocioye.cloudfront.net/new2/press/pressbg_cotechcast.jpg)
    no-repeat;
}
.presssdut {
  background: url(https://d35408cyocioye.cloudfront.net/new2/press/pressbg_sdut.jpg)
    no-repeat;
}
.pressabc7 {
  background: url(https://d35408cyocioye.cloudfront.net/new2/press/pressbg_abc7.jpg)
    no-repeat;
}
.pressSynergyEnv {
  background: url(https://d35408cyocioye.cloudfront.net/new2/press/pressbg_synergyenv.jpg)
    no-repeat;
}
.pressCoBiz {
  background: url(https://d35408cyocioye.cloudfront.net/new2/press/pressbg_cobiz.jpg)
    no-repeat;
}
.presscoloradoinno {
  background: url(https://joshai.s3.amazonaws.com/new2/press/presscoloradoinno.png)
    no-repeat;
}
.pressconnecteddesign {
  background: url(https://joshai.s3.amazonaws.com/new2/press/pressconnecteddesign.png)
    no-repeat;
}
.pressinteractions {
  background: url(https://joshai.s3.amazonaws.com/new2/press/pressinteractions.png)
    no-repeat;
}
.pressTechnoBuffalo {
  background: url(https://d35408cyocioye.cloudfront.net/new2/press/pressbg_technobuffalo.jpg)
    no-repeat;
}
.pressinnovatorspeak {
  background: url(https://d35408cyocioye.cloudfront.net/new2/press/pressbg_innovatorspeak.jpg)
    no-repeat;
}
.presshomeadvisor {
  background: url(https://d35408cyocioye.cloudfront.net/new2/press/pressbg_homeadvisor.jpg)
    no-repeat;
}
.presslanacion {
  background: url(https://d35408cyocioye.cloudfront.net/new2/press/pressbg_lanacion.jpg)
    no-repeat;
}
.presstechcrunch {
  background: url(https://d35408cyocioye.cloudfront.net/new2/press/pressbg_techcrunch.jpg)
    no-repeat;
}
.pressventurebeat {
  background: url(https://d35408cyocioye.cloudfront.net/new2/press/pressbg_venturebeat.jpg)
    no-repeat;
}
.press9news {
  background: url(https://d35408cyocioye.cloudfront.net/new2/press/pressbg_9news.jpg)
    no-repeat;
}
.presscw39 {
  background: url(https://d35408cyocioye.cloudfront.net/new2/press/pressbg_cw39.jpg)
    no-repeat;
}
.pressbuilder {
  background: url(https://d35408cyocioye.cloudfront.net/new2/press/pressbg_builder.jpg)
    no-repeat;
}
.pressdenverpost {
  background: url(https://d35408cyocioye.cloudfront.net/new2/press/pressbg_denverpost.jpg)
    no-repeat;
}
.pressnewscenter {
  background: url(https://d35408cyocioye.cloudfront.net/new2/press/pressbg_newscenter.jpg)
    no-repeat;
}
.pressstrata {
  background: url(https://d35408cyocioye.cloudfront.net/new2/press/pressbg_strata.jpg)
    no-repeat;
}
.pressuncgenius {
  background: url(https://d35408cyocioye.cloudfront.net/new2/press/pressbg_uncgenius.jpg)
    no-repeat;
}
.presssmhome {
  background: url(https://d35408cyocioye.cloudfront.net/new2/press/pressbg_smhome.jpg)
    no-repeat;
}
.pressconnectedhome {
  background: url(https://d35408cyocioye.cloudfront.net/new2/press/pressbg_connectedhome.jpg)
    no-repeat;
}
.presscmi {
  background: url(https://d35408cyocioye.cloudfront.net/new2/press/pressbg_cmi.jpg)
    no-repeat;
}
.presslatimes {
  background: url(https://d35408cyocioye.cloudfront.net/new2/press/pressbg_latimes.jpg)
    no-repeat;
}
.pressonefirefly {
  background: url(https://d35408cyocioye.cloudfront.net/new2/press/pressbg_onefirefly_dup.jpg)
    no-repeat;
}
.presslodging {
  background: url(https://d35408cyocioye.cloudfront.net/new2/press/pressbg_lodging.jpg)
    no-repeat;
}
.pressnbcpalmsprings {
  background: url(https://d35408cyocioye.cloudfront.net/new2/press/pressbg_nbcpalmsprings.jpg)
    no-repeat;
}
.pressmannreport {
  background: url(https://d35408cyocioye.cloudfront.net/new2/press/pressbg_mannreport.jpg)
    no-repeat;
}
.pressusae {
  background: url(https://d35408cyocioye.cloudfront.net/new2/press/pressbg_usae.jpg)
    no-repeat;
}
.pressapnews {
  background: url(https://d35408cyocioye.cloudfront.net/new2/press/pressbg_apnews.jpg)
    no-repeat;
}
.pressyahoofinance {
  background: url(https://d35408cyocioye.cloudfront.net/new2/press/pressbg_yahoofinance.jpg)
    no-repeat;
}
.pressglobenewswire {
  background: url(https://d35408cyocioye.cloudfront.net/new2/press/pressbg_globenewswire.svg);
}

.pressLinkPhotos {
  background-size: 100% 100%;
  -webkit-transition: background-size 0.6s;
  transition: background-size 0.6s;
}
.pressLinkPhotos:hover {
  background-size: 103% 103%;
}
.pressLinkPhotos a {
  color: #fff;
}
.pressLinkPhotos a:hover {
  color: #fff;
}

/* ****************************** */
/* RECOMMENDATIONS (RECS) PAGE */
/* ****************************** */

#recsTopSection {
  background: url(https://d35408cyocioye.cloudfront.net/legacy/new2/works+with/works-with-page-header-background.jpg)
    no-repeat;
  background-size: cover;
  width: 100%;
  padding: 120px 0px 80px 0px;
  margin-bottom: 15px;
  -webkit-transition: margin 1s;
  transition: margin 1s;
}
#recsTitle {
  margin: auto;
  opacity: 0;
  font-size: 35px;
  font-weight: 300;
  color: #555;
  letter-spacing: 0.02em;
  margin-bottom: 20px;
  -webkit-transition: opacity 2s;
  transition: opacity 2s;
}
#recsText {
  margin: auto;
  font-size: 13px;
  color: #666;
  line-height: 1.6;
}
.recsTextTop {
  opacity: 0;
  -webkit-transition: opacity 2s;
  transition: opacity 2s;
}
.recsButton {
  background: url(https://d35408cyocioye.cloudfront.net/legacy/new2/contact/email-button-four-stack.png)
    no-repeat;
  background-position: 0px -40px;
  height: 29px;
  width: 120px;
  font-size: 11px;
  color: #fff;
  font-weight: 800;
  margin: auto;
  margin-top: 40px;
  padding-top: 11px;
  -webkit-transition: opacity 1s;
  transition: opacity 1s;
  opacity: 0;
}
.recsButton:hover {
  background-position: 0px -80px;
  cursor: pointer;
}
.recsContent {
  display: table;
  background-color: #f9f9f9;
  margin-bottom: 180px;
  border-bottom: 15px solid #fff;
}
.recsRevealTitle {
  font-weight: 300;
  font-size: 40px;
  color: #002340;
}
.recsRevealBodyText {
  margin-top: 30px;
  font-size: 14px;
  color: #888;
}
.recsDevicesRow {
  height: 250px;
  width: 100%;
  margin-top: 20px;
}
.recsDevicesRowSecond {
  height: 500px;
}
.recsDevicesRowThird {
  height: 500px;
}
.recsDevicesRow1,
.recsDevicesRow2,
.recsDevicesRow3,
.recsDevicesRow4,
.recsDevicesRow5,
.recsDevicesRow6,
.recsDevicesRow7,
.recsDevicesRow8,
.recsDevicesRow9 {
  width: 33.3333%;
  height: 50%;
  float: left;
  text-align: center;
}
.recsDeviceRowPhoto {
  width: 130px;
  margin-top: 10px;
}
.recsDeviceRowPhotoSoon {
  opacity: 0.2;
}
.recsDeviceRowTitle {
  font-size: 12px;
  color: #444;
  margin-bottom: 10px;
}
.recsDeviceRowText {
  font-size: 14px;
  color: #888;
}
#recsThermostats,
#recsLights,
#recsCameras,
#recsMusic,
#recsEntertain,
#recsSensors,
#recsSecurity,
#recsOutdoors,
#recsSwitches,
#recsShades,
#recsGarage,
#recsMics,
#recsStb,
#recsMatrix,
#recsFans {
  display: none;
  margin-top: 40px;
  margin-bottom: 40px;
}
#recsThermostats a,
#recsLights a,
#recsCameras a,
#recsMusic a,
#recsEntertain a,
#recsSensors a,
#recsSecurity a,
#recsOutdoors a,
#recsSwitches a,
#recsShades a,
#recsGarage a,
#recsMics a,
#recsStb a,
#recsMatrix a,
#recsFans a {
  font-size: 13px;
  color: #333;
  font-weight: 400;
}
.recsGrid {
  padding: 0;
  margin-top: 0;
  list-style: none;
}
.recsGrid li {
  float: left;
  width: calc(25% - 12px);
  margin-right: 15px;
}
.recsGridLiRight {
  margin-right: 0px !important;
}
.recsGridLiRightSmall {
  margin-right: 15px;
}
.recsContentSquareTop {
  margin-top: 0px;
}
.recsContentSquare {
  height: 268px;
  width: 100%;
  margin-right: 15px;
  margin-top: 15px;
  overflow: hidden;
  -webkit-transition: background-size 0.5s;
  transition: background-size 0.5s;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.recsContentSquareThermostat {
  background: url(https://d35408cyocioye.cloudfront.net/legacy/new2/works+with/works-with-page-thermostat-thumbnail.jpg)
    no-repeat;
  background-size: 100% 100%;
}
.recsContentSquareLights {
  background: url(https://d35408cyocioye.cloudfront.net/legacy/new2/works+with/works-with-page-lights-thumbnail.jpg)
    no-repeat;
  background-size: 100% 100%;
}
.recsContentSquareCameras {
  background: url(https://d35408cyocioye.cloudfront.net/legacy/new2/works+with/works-with-page-cameras-thumbnail.jpg)
    no-repeat;
  background-size: 100% 100%;
}
.recsContentSquareMusic {
  background: url(https://d35408cyocioye.cloudfront.net/legacy/new2/works+with/works-with-page-music-thumbnail.jpg)
    no-repeat;
  background-size: 100% 100%;
}
.recsContentSquareEntertain {
  background: url(https://d35408cyocioye.cloudfront.net/legacy/new2/works+with/works-with-page-entertain-thumbnail.jpg)
    no-repeat;
  background-size: 100% 100%;
}
.recsContentSquareSensors {
  background: url(https://d35408cyocioye.cloudfront.net/legacy/new2/works+with/works-with-page-sensors-thumbnail.jpg)
    no-repeat;
  background-size: 100% 100%;
}
.recsContentSquareSecurity {
  background: url(https://d35408cyocioye.cloudfront.net/legacy/new2/works+with/works-with-page-security-thumbnail.jpg)
    no-repeat;
  background-size: 100% 100%;
}
.recsContentSquareOutdoors {
  background: url(https://d35408cyocioye.cloudfront.net/legacy/new2/works+with/works-with-page-outdoor-thumbnail.jpg)
    no-repeat;
  background-size: 100% 100%;
}
.recsContentSquareSwitches {
  background: url(https://d35408cyocioye.cloudfront.net/legacy/new2/works+with/works-with-page-switches-thumbnail.jpg)
    no-repeat;
  background-size: 100% 100%;
}
.recsContentSquareShades {
  background: url(https://d35408cyocioye.cloudfront.net/legacy/new2/works+with/works-with-page-shades-thumbnail.jpg)
    no-repeat;
  background-size: 100% 100%;
}
.recsContentSquareMics {
  background: url(https://d35408cyocioye.cloudfront.net/new2/devices/mics.jpg)
    no-repeat;
  background-size: 100% 100%;
}
.recsContentSquareStb {
  background: url(https://d35408cyocioye.cloudfront.net/new2/devices/stb.jpg)
    no-repeat;
  background-size: 100% 100%;
}
.recsContentSquareMatrix {
  background: url(https://d35408cyocioye.cloudfront.net/new2/devices/matrix.jpg)
    no-repeat;
  background-size: 100% 100%;
}
.recsContentSquareFans {
  background: url(https://d35408cyocioye.cloudfront.net/new2/devices/fans.jpg)
    no-repeat;
  background-size: 100% 100%;
}
.recsContentSquareGarage {
  background: url(https://d35408cyocioye.cloudfront.net/legacy/new2/works+with/works-with-page-garage-thumbnail.jpg)
    no-repeat;
  background-size: 100% 100%;
}
.recsContentSquareMore {
  height: 267px;
  width: calc(100% - 2px);
  border: 1px solid #ccc;
}

.recsContentSquareThermostat:hover,
.recsContentSquareLights:hover,
.recsContentSquareCameras:hover,
.recsContentSquareMusic:hover,
.recsContentSquareEntertain:hover,
.recsContentSquareSensors:hover,
.recsContentSquareSecurity:hover,
.recsContentSquareOutdoors:hover,
.recsContentSquareSwitches:hover,
.recsContentSquareShades:hover,
.recsContentSquareGarage:hover,
.recsContentSquareMatrix:hover,
.recsContentSquareFans:hover,
.recsContentSquareStb:hover,
.recsContentSquareMics:hover {
  background-size: 105% 105%;
  cursor: pointer;
}

.recsContentSquareRight {
  margin-right: 0px;
}
.recsContentIcon {
  height: 50px;
  width: 50px;
  background-position: 0px 0px;
}
.recsContentIcon2 {
  width: 45px;
}
.recsContentIconThermostat {
  background: url(https://d35408cyocioye.cloudfront.net/legacy/new2/works+with/works-with-page-thermostat-icon-stacked.png);
}
.recsContentIconLights {
  background: url(https://d35408cyocioye.cloudfront.net/legacy/new2/works+with/works-with-page-lights-icon-stacked.png);
}
.recsContentIconCameras {
  background: url(https://d35408cyocioye.cloudfront.net/legacy/new2/works+with/works-with-page-cameras-icon-stacked.png);
}
.recsContentIconMusic {
  background: url(https://d35408cyocioye.cloudfront.net/legacy/new2/works+with/works-with-page-music-icon-stacked.png);
}
.recsContentIconEntertain {
  background: url(https://d35408cyocioye.cloudfront.net/legacy/new2/works+with/works-with-page-entertain-icon-stacked.png);
}
.recsContentIconSensors {
  background: url(https://d35408cyocioye.cloudfront.net/legacy/new2/works+with/works-with-page-sensors-icon-stacked.png);
}
.recsContentIconSecurity {
  background: url(https://d35408cyocioye.cloudfront.net/legacy/new2/works+with/works-with-page-security-icon-stacked.png);
}
.recsContentIconOutdoors {
  background: url(https://d35408cyocioye.cloudfront.net/legacy/new2/works+with/works-with-page-outdoors-icon-stacked.png);
}
.recsContentIconSwitches {
  background: url(https://d35408cyocioye.cloudfront.net/legacy/new2/works+with/works-with-page-switches-icon-stacked.png);
}
.recsContentIconMics {
  background: url(https://d35408cyocioye.cloudfront.net/new2/devices/mics-icon-stacked.png);
}
.recsContentIconStb {
  background: url(https://d35408cyocioye.cloudfront.net/new2/devices/stb-icon-stacked.png);
}
.recsContentIconMatrix {
  background: url(https://d35408cyocioye.cloudfront.net/new2/devices/matrix-switchers-icon-stacked.png);
}
.recsContentIconFans {
  background: url(https://d35408cyocioye.cloudfront.net/new2/devices/fans-icon-stacked.png);
}
.recsContentIconShades {
  background: url(https://d35408cyocioye.cloudfront.net/legacy/new2/works+with/works-with-page-shades-icon-stacked.png);
}
.recsContentIconGarage {
  background: url(https://d35408cyocioye.cloudfront.net/legacy/new2/works+with/works-with-page-garage-icon-stacked.png);
}

.recsContentSquare:hover > .recsContentIcon {
  background-position: 0px -50px;
}
.recsContentName {
  color: #fff;
  font-weight: 800;
  text-transform: uppercase;
  margin-top: 15px;
  font-size: 15px;
}
.recsContentMore {
  color: #ccc;
  font-weight: 800;
  font-style: italic;
  font-size: 15px;
  line-height: 1.5em;
}

.recsImage {
  box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.2);
  width: calc(100% - 100px);
  padding: 100px 50px 500px 50px;
  font-size: 2em;
  cursor: pointer;
}
.recsThermostat {
  background: url(https://d35408cyocioye.cloudfront.net/legacy/products/nest.png)
    bottom left no-repeat;
  background-size: cover;
  margin-bottom: 120px;
}
.recsMusic {
  background: url(https://d35408cyocioye.cloudfront.net/legacy/products/sonos.png)
    bottom left no-repeat;
  background-size: cover;
  margin-top: 120px;
  margin-bottom: 120px;
}
.recsLights {
  background: url(https://d35408cyocioye.cloudfront.net/legacy/products/lights.png)
    bottom left no-repeat;
  background-size: cover;
  margin-top: 120px;
  margin-bottom: 120px;
}
.recsCameras {
  background: url(https://d35408cyocioye.cloudfront.net/legacy/products/nestcam.png)
    bottom left no-repeat;
  background-size: cover;
  margin-top: 120px;
  margin-bottom: 120px;
}

.recsDesc {
  color: #333;
}
.recsDivider {
  width: 100%;
  margin-top: 20px;
  border-top: 1px solid #eee;
  margin-bottom: 20px;
}
.recsRevealText {
  color: #3581e6;
  cursor: pointer;
  font-weight: 700;
}
#recsThermostatCollapse,
#recsMusicCollapse,
#recsLightsCollapse,
#recsCamerasCollapse {
  display: none;
  position: static;

  border-top: 1px solid #eee;
  margin-top: 100px;
}
.recsThermHide,
.recsMusicHide,
.recsLightsHide,
.recsCamerasHide {
  display: none;
}

/* ****************************** */
/* BLOG PAGE */
/* ****************************** */

#blogBody {
  width: 100%;
  padding: 20px 0px 1px 0px;
  -webkit-transition: margin 1s;
  transition: margin 1s;
  font-size: 14px;
  border-bottom: 15px solid #fff;
}
#blogTopSection {
  background: url(https://d35408cyocioye.cloudfront.net/new2/blog.jpg) no-repeat;
  background-size: cover;
  width: 100%;
  padding: 80px 0px 60px 0px;
  margin-bottom: 15px;
  -webkit-transition: margin 1s;
  transition: margin 1s;
}
#blogTitle {
  margin: auto;
  opacity: 0;
  font-size: 35px;
  font-weight: 300;
  color: #555;
  letter-spacing: 0.02em;
  margin-bottom: 20px;
  -webkit-transition: opacity 2s;
  transition: opacity 2s;
}
#blogText {
  margin: auto;
  font-size: 13px;
  color: #666;
  line-height: 1.6;
}
.blogTextTop {
  opacity: 0;
  -webkit-transition: opacity 2s;
  transition: opacity 2s;
}
.blogButton {
  background: url(https://d35408cyocioye.cloudfront.net/legacy/new2/contact/email-button-four-stack.png)
    no-repeat;
  background-position: 0px -40px;
  height: 29px;
  width: 120px;
  font-size: 11px;
  color: #fff;
  font-weight: 800;
  margin: auto;
  margin-top: 40px;
  padding-top: 11px;
  -webkit-transition: opacity 1s;
  transition: opacity 1s;
  opacity: 0;
}
.blogButton:hover {
  background-position: 0px -80px;
  cursor: pointer;
}
#blog-loading {
  position: absolute;
  left: 50%;
  margin-left: -40px;
  font-size: 20px;
  letter-spacing: 0.01em;
  margin-top: 150px;
  font-weight: 300;
}

/* ****************************** */
/* BUY PAGE */
/* ****************************** */

#buyCont {
  width: 100%;
}
#buyBlock2Title,
#buyBlock4Title,
#buyBlock7Title,
#buyBlock1Title,
#buyBlock3Title,
#buyBlock8Title,
#buyBlock9Title,
#buyBlock12Title {
  font-size: 40px;
  font-weight: 300;
  color: #575755;
}
#buyBlock1Title {
  color: #fff;
  padding-top: 80px !important;
  padding-bottom: 20px;
}
#buyBlock3Title {
  margin-top: 100px;
}
.stripe-result-message {
  margin-top: 30px;
  margin-bottom: 45px;
  padding: 0px;
  color: green;
  font-size: 22px;
}
.stripe-result-message.stripe-fail {
  margin-top: 30px;
  margin-bottom: 45px;
  padding: 0px;
  color: red;
  font-size: 22px;
}

/* BLOCK 1 */

#buyBlock1,
#buyBlock1demo {
  position: relative;
  width: 100%;
  height: 100%;
  border-bottom: 15px solid #fff;
  padding-top: 46px;
  overflow: hidden;
  -webkit-backface-visibility: hidden;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -ms-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  z-index: 1;
}
#buyBlock1Vid {
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  min-width: 100%;
  min-height: 100%;
  width: auto;
  height: auto;
  z-index: -100;
  -webkit-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);
  pointer-events: none;
  overflow-y: hidden;
  vertical-align: top;
}
#buyBlock1Left {
  height: 100%;
  width: 50%;
  background-color: RGBA(0, 0, 0, 0.4);
  color: #fff;
  padding-bottom: 40px;
}
.buyList {
  line-height: 2.4;
  font-size: 16px;
  width: 300px;
  margin: auto;
  margin-top: 30px;
  text-align: left;
  font-style: italic;
}
.buyList a {
  color: #3ca4fc;
}

/* BLOCK 2 */
#buyBlock2 {
  width: 100%;
  padding-top: 40px;
  padding-bottom: 80px;
  background-color: #f9f9f9;
  border-bottom: 15px solid #fff;
}
#buyBlock2Cont {
  width: 100%;
  height: 250px;
  margin-top: 40px;
}
.buyBlock2Photos {
  height: 250px;
  width: 22.3%;
  position: absolute;
  -webkit-transition: opacity 1s;
  transition: opacity 1s;
}
.buyBlock2Photos:hover {
  opacity: 0.8;
}
.buyBlock2PhotoText {
  font-size: 16px;
  font-style: italic;
  position: absolute;
  margin-top: 260px;
  width: 100%;
  color: #666;
}
.buyBlock2Arrows {
  height: 150px;
  width: 16.5%;
  position: absolute;
  padding-top: 100px;
}
#buyBlockPhoto1 {
  background: url(https://d35408cyocioye.cloudfront.net/legacy/new2/getjosh/get-josh-page-setup-1.jpg)
    no-repeat;
  background-size: cover;
  margin-left: 50px;
}
#buyBlockPhoto2 {
  background: url(https://d35408cyocioye.cloudfront.net/legacy/new2/getjosh/get-josh-page-setup-3.jpg)
    no-repeat;
  background-size: cover;
  margin-left: 38.8%;
}
#buyBlockPhoto3 {
  background: url(https://d35408cyocioye.cloudfront.net/legacy/new2/getjosh/get-josh-page-setup-2.jpg)
    no-repeat;
  background-size: cover;
  margin-left: 73%;
}
#buyBlockArrow1 {
  margin-left: 25%;
}
#buyBlockArrow2 {
  margin-left: 59.2%;
}

/* BLOCK 3 */
#buyBlock3 {
  width: 100%;
  height: 240px;
  background-color: #f9f9f9;
  border-bottom: 15px solid #fff;
}
.buyBlocks {
  height: 240px;
  position: absolute;
  -webkit-transition: all 1s;
  transition: all 1s;
}
.buyBlocks:hover {
  opacity: 0.9;
}
.buyBlock3BlocksText {
  font-size: 15px;
  color: #fff;
  margin-top: 115px;
  font-weight: 600;
  text-transform: uppercase;
  -webkit-transition: margin 0.5s;
  transition: margin 0.5s;
}
.buyBlock3BlocksTextRight,
.buyBlock3BlocksTextLeft {
  margin-top: 122px;
}
.buyBlock3Icon {
  position: absolute;
  margin-top: -46px;
  margin-left: -23px;
}
.buyBlock3Icon2 {
  position: absolute;
  margin-top: -95px;
  margin-left: -89px;
}
#buyBlock3BottomLeft {
  width: 33%;
  background-color: #5c7385;
  overflow: hidden;
}
#buyBlock3BottomMiddle {
  width: 34%;
  background-color: #42525e;
  margin-left: 33%;
  overflow: hidden;
}
#buyBlock3BottomRight {
  width: 33%;
  background-color: #303c45;
  margin-left: 67%;
  overflow: hidden;
}
.buyBlocks3Left,
.buyBlocks3Middle,
.buyBlocks3Right {
  font-size: 15px;
  line-height: 1.3;
  text-align: center;
  color: #fff;
  text-transform: initial;
  margin-top: 179px;
  width: 80%;
  margin-left: auto;
  margin-right: auto;
}
.buyBlocks3Middle {
  margin-top: 198px;
}

#buyBlock3BottomLeft:hover > .buyBlock3BlocksText {
  margin-top: -110px;
  cursor: pointer;
}
#buyBlock3BottomMiddle:hover > .buyBlock3BlocksText {
  margin-top: -110px;
  cursor: pointer;
}
#buyBlock3BottomRight:hover > .buyBlock3BlocksText {
  margin-top: -110px;
  cursor: pointer;
}

/* BLOCK 4 */
#buyBlock4 {
  width: 100%;
  padding-top: 25px;
  padding-bottom: 40px;
}
#buyBlock4Text {
  margin-top: 10px;
  font-size: 15px;
  line-height: 1.3;
  text-align: center;
  color: #9a9a95;
}
.buyBlock4BottomStyle a {
  color: #000;
  font-size: 15px;
  font-weight: 800;
}
.buyBlock4BottomStyle a:hover {
  color: #555;
}
#buyBlock4Bottom {
  width: 100%;
  height: 200px;
  margin-top: 20px;
}
.buyBlock4BottomStyle {
  font-size: 16px;
  font-style: italic;
  position: absolute;
  color: #666;
  margin-top: 60px;
  width: 33%;
}
#buyBlock4BottomLeft {
}
#buyBlock4BottomMiddle {
  width: 34%;
  margin-left: 33%;
}
#buyBlock4BottomRight {
  margin-left: 67%;
}

/* BLOCK 5 */
#buyBlock5 {
  width: 100%;
  height: 600px;
  background: url(https://d35408cyocioye.cloudfront.net/legacy/new2/getjosh/get-josh-footer-background-image2.jpg)
    no-repeat;
  background-size: cover;
  border-bottom: 10px solid #fff;
}

/* BLOCK 6 */

#buyBlock6 {
  width: 100%;
  height: 540px;
  background: url(https://d35408cyocioye.cloudfront.net/new2/devices.jpg)
    no-repeat;
  background-size: cover;
  border-bottom: 15px solid #fff;
  background-position: bottom;
  padding-bottom: 25px;
}
#buyBlock6 ul {
  font-size: 15px;
  line-height: 2;
  color: #575755;
  text-align: left;
  margin-left: 75%;
  padding-top: 100px;
}

/* BLOCK 7 */
#buyBlock7 {
  width: 100%;
  padding-top: 20px;
  padding-bottom: 20px;
}

/* BLOCK 8 */
#buyBlock8 {
  width: 100%;
  height: 540px;
  background: url(https://d35408cyocioye.cloudfront.net/legacy/new2/getjosh/josh-pro-background-image.jpg)
    no-repeat;
  background-size: cover;
  border-bottom: 10px solid #fff;
  background-position: 0;
  padding-bottom: 25px;
}
#buyBlock8Title {
  color: #fff;
  text-align: left;
  padding-top: 120px;
  padding-left: 200px;
}
#buyBlock8 ul {
  font-size: 15px;
  line-height: 2.7;
  color: #ffffff;
  text-align: left;
  margin-left: 120px;
}

/* BLOCK 9 */
#buyBlock9 {
  width: 100%;
  height: 200px;
  background: url(https://d35408cyocioye.cloudfront.net/legacy/new2/getjosh/josh-micro-background-image.jpg)
    no-repeat;
  background-size: cover;
  border-bottom: 10px solid #fff;
  background-position: 0;
  padding-bottom: 25px;
}
#buyBlock9Title {
  text-align: left;
  padding-top: 95px;
  padding-left: 150px;
  position: absolute;
  right: 175px;
}
#buyBlock9Circle {
  position: absolute;
  margin-left: 180px;
  margin-top: 25px;
  width: 175px;
  height: 105px;
  border-radius: 200px;
  padding-top: 70px;
  background-color: rgba(0, 0, 0, 0.5);
  font-size: 16px;
  font-weight: 700;
  text-align: center;
  color: #fff;
  text-transform: uppercase;
  -webkit-transition: all 1s;
  transition: all 1s;
}
#buyBlock9Circle:hover {
  background-color: rgba(0, 0, 0, 0.3);
}
.buyBlock9CircleLink {
  font-size: 10px;
  text-transform: initial;
  letter-spacing: 0.07em;
}
/* BLOCK 10 */

#block10 {
  height: 40px;
  width: 400px;
  margin: auto;
  margin-bottom: 30px;
  margin-top: 10px;
  text-transform: uppercase;
  font-size: 15px;
  font-weight: bold;
}
#block10left {
  float: left;
  width: 200px;
  background: url(https://d35408cyocioye.cloudfront.net/legacy/new2/getjosh/hex-button-toggle-left-stacked.png)
    no-repeat;
  background-position: 0px 0px;
  color: #fff;
  height: 30px;
  padding-top: 10px;
  -webkit-transition: color 0.5s;
  transition: color 0.5s;
}
#block10right {
  float: right;
  width: 200px;
  background: url(https://d35408cyocioye.cloudfront.net/legacy/new2/getjosh/hex-button-toggle-right-stacked.png)
    no-repeat;
  background-position: 0px 0px;
  color: #fff;
  height: 30px;
  padding-top: 10px;
  -webkit-transition: color 1s;
  transition: color 1s;
}
.block10active {
  background-position: 0px -40px !important;
  color: #aaaaa6 !important;
}
.block10active:hover {
  color: #008cff !important;
  cursor: pointer !important;
}

/* BLOCK 11 */
#buyBlock11 {
  height: 640px;
  width: 100%;
  background-color: #f9f9f9;
  margin-bottom: 15px;
  display: none;
}
#buyBlock11Top {
  height: 320px;
  width: 100%;
  padding: 0px;
  margin: 0px;
}
#buyBlock11Bottom {
  height: 320px;
  width: 100%;
  padding: 0px;
  margin: 0px;
}
.buyBlock11TopIcon {
  height: 50px;
  width: 50px;
  margin: auto;
  margin-top: 75px;
  margin-bottom: 20px;
}
.buyBlock11TopIconTitle {
  text-transform: uppercase;
  font-size: 15px;
  font-weight: 500;
  line-height: 1;
  text-align: center;
  color: #575755;
}
.buyBlock11TopIconText {
  font-size: 15px;
  line-height: 1.3;
  text-align: center;
  color: #575755;
  padding: 20px;
}

#buyBlock11TopLeft {
  height: 100%;
  width: 33.3%;
  float: left;
}
#buyBlock11TopMiddle {
  height: 100%;
  width: 33.3%;
  float: left;
}
#buyBlock11TopRight {
  height: 100%;
  width: 33.3%;
  float: left;
}
#buyBlock11BottomLeft {
  height: 100%;
  width: 33.3%;
  float: left;
}
#buyBlock11BottomMiddle {
  height: 100%;
  width: 33.3%;
  float: left;
}
#buyBlock11BottomRight {
  height: 100%;
  width: 33.3%;
  float: left;
}
.buyBlock11Horizontal {
  position: absolute;
  width: 100%;
  left: 0px;
}

.buyBlock11Vertical1 {
  position: absolute;
  height: 640px;
  left: 33.3%;
}
.buyBlock11Vertical2 {
  position: absolute;
  height: 640px;
  left: 66.6%;
}
/* BLOCK 12 */
#buyBlock12 {
  width: 100%;
  padding-top: 25px;
  padding-bottom: 40px;
}
#buyBlock12Text {
  margin-top: 10px;
  font-size: 15px;
  line-height: 1.3;
  text-align: center;
  color: #9a9a95;
}

/* BLOCK 13 */
#buyBlock13 {
  height: 240px;
  width: 100%;
}
.buyBlock13cont {
  height: 100%;
  width: calc(50% - 2px);
  overflow: hidden;
  float: left;
}
.buyBlock13contLeft {
  margin-right: 4px;
}
#buyBlock13left {
  width: 100%;
  height: 100%;
  background: linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),
    url(https://d35408cyocioye.cloudfront.net/legacy/new2/getjosh/tap-to-control-graphic.jpg)
      no-repeat;
  background-size: cover;
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
}
#buyBlock13left:hover {
  -moz-transform: scale(1.05, 1.05);
  -webkit-transform: scale(1.05, 1.05);
  transform: scale(1.05, 1.05);
}
#buyBlock13right {
  width: 100%;
  height: 100%;
  background: url(https://d35408cyocioye.cloudfront.net/legacy/new2/getjosh/speak-to-control-graphic.jpg)
    no-repeat;
  background-size: cover;
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
}
#buyBlock13right:hover {
  -moz-transform: scale(1.05, 1.05);
  -webkit-transform: scale(1.05, 1.05);
  transform: scale(1.05, 1.05);
}

/* BLOCK 14 */
#buyBlock14 {
  width: 100%;
  margin-top: 40px;
  margin-bottom: 40px;
  height: 70px;
  text-align: center;
}
#buyBlock14left {
  width: 50%;
  float: left;
}
#buyBlock14right {
  width: 50%;
  float: left;
}
.buyBlock14leftTitle {
  font-size: 15px;
  font-weight: 600;
  line-height: 1;
  text-align: center;
  color: #575755;
  margin-bottom: 20px;
  text-transform: uppercase;
}
.buyBlock14leftText {
  font-size: 15px;
  line-height: 1.3;
  text-align: center;
  color: #575755;
}

/* Other stuff Get Josh */
video#bgvid {
  position: absolute;
  top: 50%;
  left: 50%;
  min-width: 100%;
  height: 100%;
  width: auto;
  height: auto;
  z-index: -100;
  -webkit-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);
  /* background: url(polina.jpg) no-repeat; */
  background-size: cover;
  margin-bottom: 180px;
  border-bottom: 15px solid #fff;
}

#buyBody {
  width: 100%;
  padding: 40px 0px 0px 0px;
  -webkit-transition: margin 1s;
  transition: margin 1s;
  font-size: 14px;
  color: #303131;
}
.buyHeader {
  background: url(https://d35408cyocioye.cloudfront.net/legacy/preorderhouse.jpg)
    center left no-repeat;
  background-size: cover;
  box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.4);
  width: calc(100% - 200px);
  height: 200px;
  color: #fff;
  text-align: center;
  font-size: 30px;
  padding: 100px;
}
.buyJoshHead {
  opacity: 0;
  width: 200px;
  margin-top: 60px;
  -webkit-transition: all 4.5s;
  transition: all 4.5s;
}
.buyCont {
  width: 100%;
  margin-bottom: 100px;
  text-align: left;
  min-height: 350px;
}
.buyLeft {
  width: 50%;
  padding: 15px;
  color: #f9f9f9;
  text-align: left;
}
.buyLeftTransBlack {
  position: absolute;
  margin-top: -1px;
  height: 100%;
  width: 50%;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: -1;
}
.buyRight {
  float: right;
  width: calc(40% - 40px);
  background-color: #303131;
  color: #fff;
  padding: 10px 10px 0px 10px;
  margin-right: 15px;
  text-align: center;
  padding: 0px 30px 0x 30x;
}
.buySmallText {
  font-size: 12px;
  color: #eee;
  padding-bottom: 20px;
  margin-bottom: 20px;
  border-bottom: 1px solid #555;
}
.buySaleBlockCont {
  position: absolute;
  background-color: rgba(0, 140, 255, 0.9);
  color: #fff;
  width: 200px;
  padding: 20px;
  text-align: left;
  height: 200px;
  opacity: 0;
  margin-left: 65%;
  margin-top: 120px;

  -webkit-transition: all 0.5s;
  transition: all 0.5s;

  -ms-transform: rotate(45deg); /* IE 9 */
  -webkit-transform: rotate(45deg); /* Chrome, Safari, Opera */
  transform: rotate(45deg);
}
.buySaleBlock {
  position: absolute;
  color: #fff;
  width: 200px;
  padding: 20px;
  text-align: left;
  height: 200px;
  margin-left: 65%;
  margin-top: 120px;
  opacity: 0;

  -webkit-transition: all 0.5s;
  transition: all 0.5s;
}
.buySaleBlockLogo {
  width: 68px;
  margin-left: 65px;
  margin-bottom: 5px;
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
  opacity: 0;
}
.buySaleBlockNumber {
  font-size: 50px;
  font-weight: 300;
  text-align: center;
}
.buySaleBlockSmallText,
.buySaleBlockSmallText a {
  font-size: 11px;
  text-align: center;
  margin-top: 30px;
}
.buySaleBlockOrder,
.buySaleBlockContact {
  float: right;
  background: url(https://d35408cyocioye.cloudfront.net/legacy/new2/getjosh/get-josh-page-reserve-button-stacked.png)
    no-repeat;
  width: 200px;
  height: 31px;
  background-position: 0px 0px;
  font-weight: 700;
  color: #008cff;
  padding-top: 9px;
  text-align: center;
  text-transform: uppercase;
  margin-top: 10px;
  margin-bottom: 10px;
}
.buySaleBlockOrder:hover,
.buySaleBlockContact:hover {
  background-position: 0px -40px;
  cursor: pointer;
  color: #fff;
}
.buyReserveText {
  font-size: 16px;
  font-weight: 800;
  text-align: center;
  margin-top: 50px;
  margin-bottom: 20px;
}
.buyLeft a {
  font-size: 12px;
  color: #fff;
}
#buyBlockHidden {
  display: none;
}

/* DEMO UNIT */
.customCompany input {
  width: 180px;
  height: 20px;
  border: none;
  border-radius: 15px;
  padding: 5px;
  margin-left: 5px;
}
.demoUnitSaleBlockOrder {
  float: right;
  background: url(https://d35408cyocioye.cloudfront.net/legacy/new2/getjosh/get-josh-page-reserve-button-stacked.png)
    no-repeat;
  width: 200px;
  height: 31px;
  background-position: 0px 0px;
  font-weight: 700;
  color: #008cff;
  padding-top: 9px;
  text-align: center;
  text-transform: uppercase;
  margin-top: -10px;
  margin-bottom: 5px;
  opacity: 0.9;
}
.demoUnitSaleBlockOrder:hover {
  cursor: pointer;
  opacity: 1;
}
.demoUnitDeviceList {
  text-align: left;
  margin: auto;
  margin-top: 20px;
  font-size: 15px;
  color: #9a9a95;
  columns: 4;
  -webkit-columns: 4;
  -moz-columns: 4;
  list-style-type: none;
  line-height: 2em;
}
.demoUnitDeviceList li:before {
  content: "• ";
}
.demoUnitSaleButton {
  background-position: 0px -40px;
  cursor: pointer;
  color: #fff;
  opacity: 0.9;
  float: none;
  margin: auto;
  margin-top: 20px;
  margin-bottom: 20px;
}
.demoUnitSaleButton:hover {
  opacity: 1;
}
.buyBlocks3LeftDemoUnit {
  margin-top: 173px;
}
.buyBlocks3MiddleDemoUnit {
  margin-top: 182px;
}
.buyBlocks3RightDemoUnit {
  margin-top: 165px;
}

/* ****************************** */
/* FAQ PAGE */
/* ****************************** */

#faqsBody {
  width: 100%;
  padding: 20px 0px 1px 0px;
  font-size: 18px;
  color: #303131;
  margin-bottom: 180px;
}
#faqsTopSection {
  background: url(https://d35408cyocioye.cloudfront.net/legacy/new2/faq-page-header-background.jpg)
    no-repeat;
  background-size: cover;
  width: 100%;
  padding: 120px 0px 80px 0px;
  margin-bottom: 15px;
  -webkit-transition: margin 1s;
  transition: margin 1s;
}
#faqsTitle {
  margin: auto;
  opacity: 0;
  font-size: 35px;
  font-weight: 300;
  color: #555;
  letter-spacing: 0.02em;
  margin-bottom: 20px;
  -webkit-transition: opacity 2s;
  transition: opacity 2s;
}
#faqsText {
  margin: auto;
  font-size: 13px;
  color: #666;
  line-height: 1.6;
}
.faqsTextTop {
  opacity: 0;
  -webkit-transition: opacity 2s;
  transition: opacity 2s;
}
.faqsButton {
  background: url(https://d35408cyocioye.cloudfront.net/legacy/new2/contact/email-button-four-stack.png)
    no-repeat;
  background-position: 0px -40px;
  height: 29px;
  width: 120px;
  font-size: 11px;
  color: #fff;
  font-weight: 800;
  margin: auto;
  margin-top: 40px;
  padding-top: 11px;
  -webkit-transition: opacity 1s;
  transition: opacity 1s;
  opacity: 0;
}
.faqsButton:hover {
  background-position: 0px -80px;
  cursor: pointer;
}
.faqsContent {
  width: 100%;
}
.faqsContentLeft {
  width: calc(50% - 7.5px);
  min-height: 200px;
  height: auto;
  margin-bottom: 30px;
}
.faqsContentRight {
  width: calc(50% - 7.5px);
  margin-left: calc(50% + 7.5px);
  position: absolute;
}
.faqsContentBox {
  width: calc(100% - 50px);
  padding: 25px;
  background-color: #f9f9f9;
  margin-bottom: 15px;
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
}
.faqsContentBox:hover {
  background-color: #6388b9;
}
.faqsContentBoxTitle {
  text-transform: uppercase;
  margin-bottom: 15px;
  font-weight: 800;
  color: #555;
  font-size: 16px;
}
.faqsContentBoxText {
  text-align: left;
  font-size: 14px;
  color: #888;
  line-height: 1.5em;
}
.faqsContentBoxText a {
  font-size: 13px;
  color: #3581e6;
  -webkit-transition: all 0s;
  transition: all 0s;
}
.faqsContentBox:hover > .faqsContentBoxTitle,
.faqsContentBox:hover > .faqsContentBoxText a {
  color: #fff;
}
.faqsContentBox:hover > .faqsContentBoxText {
  color: #c7ddfa;
}
/* ****************************** */
/* CONTACT PAGE */
/* ****************************** */

#contactTopSection {
  background: url(https://d35408cyocioye.cloudfront.net/legacy/new2/contact/contact-page-header-background.jpg)
    no-repeat;
  background-size: cover;
  width: 100%;
  padding: 120px 0px 80px 0px;
  -webkit-transition: margin 1s;
  transition: margin 1s;
}
#contactTitle {
  margin: auto;
  opacity: 0;
  font-size: 35px;
  font-weight: 300;
  color: #555;
  letter-spacing: 0.02em;
  margin-bottom: 20px;
  -webkit-transition: opacity 2s;
  transition: opacity 2s;
}
#contactText {
  margin: auto;
  font-size: 13px;
  color: #666;
  line-height: 1.6;
}
.contactTextTop {
  opacity: 0;
  -webkit-transition: opacity 2s;
  transition: opacity 2s;
}
.contactButton {
  background: url(https://d35408cyocioye.cloudfront.net/legacy/new2/contact/email-button-four-stack.png)
    no-repeat;
  background-position: 0px -40px;
  height: 29px;
  width: 120px;
  font-size: 11px;
  color: #fff;
  font-weight: 800;
  margin: auto;
  margin-top: 40px;
  padding-top: 11px;
  -webkit-transition: opacity 1s;
  transition: opacity 1s;
  opacity: 0;
}
.contactButton:hover {
  background-position: 0px -80px;
  cursor: pointer;
}
.contactTopLeft {
  height: 50%;
  width: 50%;
  background-color: red;
}
.contactSection {
  height: 530px;
  padding: 15px 0px 0px 0px;
  margin-bottom: 180px;
}
.contactTopLeft {
  float: left;
  width: calc(50% - 7.5px);
  background: url(https://d35408cyocioye.cloudfront.net/legacy/new2/contact/contact-page-jobs-section-background.jpg)
    no-repeat;
  background-size: 100% 100%;
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
}
.contactTopLeft:hover {
  background: url(https://d35408cyocioye.cloudfront.net/legacy/new2/contact/contact-page-jobs-section-background.jpg)
    no-repeat;
  background-size: 105% 105%;
}
.contactTopRight {
  float: right;
  width: calc(50% - 7.5px);
  background: url(https://d35408cyocioye.cloudfront.net/legacy/new2/contact/contact-page-press-section-background.jpg)
    no-repeat;
  background-size: 100% 100%;
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
}
.contactTopRight:hover {
  background: url(https://d35408cyocioye.cloudfront.net/legacy/new2/contact/contact-page-press-section-background.jpg)
    no-repeat;
  background-size: 105% 105%;
}
.contactBottomLeft {
  float: left;
  margin-top: 15px;
  width: calc(50% - 7.5px);
  background: url(https://d35408cyocioye.cloudfront.net/legacy/new2/contact/contact-page-support-section-background.jpg)
    no-repeat;
  background-size: 100% 100%;
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
}
.contactBottomLeft:hover {
  background: url(https://d35408cyocioye.cloudfront.net/legacy/new2/contact/contact-page-support-section-background.jpg)
    no-repeat;
  background-size: 105% 105%;
}
.contactBottomRight {
  float: right;
  margin-top: 15px;
  width: calc(50% - 7.5px);
  background: url(https://d35408cyocioye.cloudfront.net/legacy/new2/contact/contact-page-sales-section-background.jpg)
    no-repeat;
  background-size: 100% 100%;
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
}
.contactBottomRight:hover {
  background: url(https://d35408cyocioye.cloudfront.net/legacy/new2/contact/contact-page-sales-section-background.jpg)
    no-repeat;
  background-size: 105% 105%;
}
.contactSquaresText {
  height: 180px;
  padding-top: 70px;
  color: #fff;
  font-size: 40px;
  font-weight: 100;
}
.contactSquaresButton {
  background: url(https://d35408cyocioye.cloudfront.net/legacy/new2/contact/email-button-four-stack.png)
    no-repeat;
  background-position: 0px 0px;
  height: 28px;
  width: 120px;
  font-size: 11px;
  color: #fff;
  font-weight: 800;
  margin: auto;
  margin-top: 10px;
  padding-top: 12px;
}
.contactSquaresButton:hover {
  background-position: 0px -120px;
  cursor: pointer;
  color: #555;
}
.contactSquaresButton a {
  -webkit-transition: opacity 0.01s;
  transition: opacity 0.01s;
  font-size: 11px;
}
.contactSquaresButton:hover a {
  color: #555;
}

/* ****************************** */
/* GUIDE PAGE */
/* ****************************** */

#guideTopSection {
  background: url(https://d35408cyocioye.cloudfront.net/legacy/guide/guide-page-header-background.jpg)
    no-repeat;
  background-size: cover;
  width: 100%;
  padding: 120px 0px 80px 0px;
  -webkit-transition: margin 1s;
  transition: margin 1s;
}
#guideTitle {
  margin: auto;
  opacity: 0;
  font-size: 35px;
  font-weight: 300;
  color: #555;
  letter-spacing: 0.02em;
  margin-bottom: 20px;
  -webkit-transition: opacity 2s;
  transition: opacity 2s;
}
#guideText {
  margin: auto;
  font-size: 13px;
  color: #666;
  line-height: 1.6;
}
.guideTextTop {
  opacity: 0;
  -webkit-transition: opacity 2s;
  transition: opacity 2s;
}
.guideTextTop a,
.guideTextBottom a {
  font-size: 13px;
  color: #008cff;
}
.guideTextTop a:hover,
.guideTextBottom a:hover {
  color: #008cff;
  text-decoration: underline;
  cursor: pointer;
}
.guideListLink a {
  color: #0069bf;
  font-size: 18px;
  font-weight: 500;
}
.guideListLink a:hover {
  color: #008cff;
}
.guideSection {
  padding: 30px 0px 30px 0px;
  margin-bottom: 180px;
  background-color: #fff;
}
.guideListTitle {
  text-align: left;
  margin-left: calc(50% - 90px);
  font-size: 20px;
  line-height: 3.5em;
  color: #0069bf;
  font-weight: 300;
  -webkit-transition: color 1s;
  transition: color 1s;
}
.guideListTitle:hover {
  cursor: pointer;
  color: #008cff;
}
.guideListIcon {
  width: 40px;
  position: absolute;
  margin-left: -50px;
  margin-top: 15px;
}
.guideListTitle:hover > .guideListIcon {
  width: 38px;
  position: absolute;
  margin-left: -48px;
  margin-top: 17px;
}
.guideList1,
.guideList2,
.guideList3,
.guideList4,
.guideList5,
.guideList6,
.guideList7,
.guideList8,
.guideList9 {
  display: none;
  border-top: 1px solid #eee;
  border-bottom: 1px solid #eee;
  padding: 15px;
  max-width: 500px;
  margin: auto;
  text-align: left;
}
.guideListClose {
  font-size: 11px;
  color: #0069bf;
  text-transform: uppercase;
  -webkit-transition: color 1s;
  transition: color 1s;
  text-align: center;
  margin-top: 20px;
}
.guideListClose:hover {
  cursor: pointer;
  color: #008cff;
}
.guideList2Item {
  margin-bottom: 15px;
  margin-top: 30px;
}
.guideList2Note {
  font-size: 14px;
  font-style: italic;
  color: #b33427;
  margin-bottom: 15px;
}
.guideList2Note a {
  font-size: 14px;
  font-style: italic;
  color: #b33427;
}
.guideList2Note a:hover {
  color: #e74c3c;
}
.guideList2ItemImage {
  opacity: 0.9;
  padding-bottom: 15px;
  border-bottom: 1px solid #eee;
  -webkit-transition: all 1s;
  transition: all 1s;
}
.guideList2ItemImage:hover {
  opacity: 1;
  cursor: pointer;
}
.guideCommandsList,
.guideDeviceList,
.guideAdvancedList {
  list-style-type: circle;
}
.guideCommandsList li {
  font-style: italic;
}
.guideList4Icon {
  width: 30px;
  vertical-align: middle;
  margin-right: 10px;
  margin-top: -4px;
}
.guideAdvancedList1,
.guideAdvancedList2,
.guideAdvancedList3,
.guideAdvancedList4,
.guideAdvancedList5,
.guideAdvancedList6 {
  display: none;
  border-top: 1px solid #eee;
  padding-top: 15px;
  margin-top: 15px;
  margin-bottom: 15px;
  padding-bottom: 15px;
  border-bottom: 1px solid #eee;
}
.guideAdvancedList li {
  color: #0069bf;
  font-weight: 300;
  -webkit-transition: color 1s;
  transition: color 1s;
}
.guideAdvancedList li:hover {
  cursor: pointer;
  color: #008cff;
}
#guideLocalizationList li,
#guideLocalizationList2 li {
  color: #000;
  font-weight: 500;
  cursor: auto;
  margin-bottom: 10px;
}
#guideLocalizationList2 {
  list-style-type: lower-latin;
  margin-top: 10px;
  margin-bottom: 10px;
}

/* ****************************** */
/* PACKAGING PAGE */
/* ****************************** */

#packagingTopSection {
  background: url(https://d35408cyocioye.cloudfront.net/legacy/packaging/packaging-page-header-background.jpg)
    no-repeat;
  background-size: cover;
  width: 100%;
  padding: 120px 0px 80px 0px;
  -webkit-transition: margin 1s;
  transition: margin 1s;
}
#packagingTitle {
  margin: auto;
  opacity: 0;
  font-size: 35px;
  font-weight: 300;
  color: #555;
  letter-spacing: 0.02em;
  margin-bottom: 20px;
  -webkit-transition: opacity 2s;
  transition: opacity 2s;
}
#packagingText {
  margin: auto;
  font-size: 13px;
  color: #666;
  line-height: 1.6;
}
.packagingTextTop {
  opacity: 0;
  -webkit-transition: opacity 2s;
  transition: opacity 2s;
}
.packagingTextTop a,
.packagingTextBottom a {
  font-size: 13px;
  color: #88aac4;
  color: #008cff;
}
.packagingTextTop a:hover,
.packagingTextBottom a:hover {
  color: #008cff;
  text-decoration: underline;
  cursor: pointer;
}
.packagingSection {
  height: 530px;
  padding: 15px 0px 0px 0px;
  margin-bottom: 180px;
  background-color: #fff;
}
.packagingTextBottom {
  font-size: 13px;
  margin: auto;
  color: #666;
  margin-bottom: 177px;
  position: static;
  padding-bottom: 25px;
  opacity: 0;
  -webkit-transition: opacity 2s;
  transition: opacity 2s;
}

/* ****************************** */
/* MANIFESTO PAGE */
/* ****************************** */

.docRightCont {
  text-align: left;
  padding: 40px 50px 0px 50px;
  background-color: #f9f9f9;
}
#manifesto-cont {
  margin-bottom: 180px;
}
.sectionHeaders {
  font-style: italic;
  font-size: 1.17em;
  font-weight: bold;
  border-top: 1px solid #ccc;
  padding-top: 50px;
  margin-top: 50px;
  color: #00467f;
}
.decagon {
  width: 40%;
  display: block;
  margin: auto;
}
.sectionHeader {
  padding-top: 40px;
  color: #00467f;
}
.divider {
  height: 50px;
  border-top: 1px solid #aaa;
  width: 100%;
  margin-top: 50px;
}
.manifestoParagraph {
  font-size: 18px;
  margin: auto;
  margin-top: 20px;
  letter-spacing: 0px;
  font-weight: 500;
  width: 100%;
}

/* ****************************** */
/* INSTALLER PAGE */
/* ****************************** */
.integratorPageSmallHeaders {
  font-family: "Lato", sans-serif;
  font-size: 15px;
  font-weight: 600;
  line-height: 1;
  text-align: center;
  color: #575755;
  text-transform: uppercase;
  letter-spacing: 0.02em;
}
#a {
  position: relative;
  width: 100%;
  height: 560px;
  padding-top: 46px;
  background: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)),
    url(https://d35408cyocioye.cloudfront.net/new2/integrators/video.jpg);
  background-size: cover;
  background-repeat: no-repeat;
}
#integratorBlock1 {
  position: relative;
  width: 100%;
  height: 560px;
  padding-top: 46px;
  background: linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)),
    url(https://d35408cyocioye.cloudfront.net/new2/integrators/video.jpg);
  background-size: cover;
  background-repeat: no-repeat;
}
#integratorBlock1:hover {
  cursor: pointer;
}
.integratorTopPlayButton {
  background: url(https://d35408cyocioye.cloudfront.net/new2/integrators/play.png);
  background-position: 0px 0px;
  width: 70px;
  height: 50px;
  background-size: 71px 101px;
  margin: auto;
  margin-top: 255px;
}
.integratorTopPlayButton:hover,
#integratorBlock1:hover > .integratorTopPlayButton {
  cursor: pointer;
  background-position: 0px 51px;
}
.integratorTopText {
  font-family: "Lato", sans-serif;
  font-size: 40px;
  font-weight: 300;
  color: #fff;
  margin-top: 50px;
}
.integratorTopTextSmall {
  font-family: "Lato", sans-serif;
  font-size: 16px;
  font-weight: 500;
  color: #fff;
  margin-top: 20px;
}
#integratorReferrals {
  height: 360px;
  width: 100%;
  margin-top: 30px;
}
#integratorReferralsTop {
  height: 180px;
  width: 100%;
}
#integratorReferralsBottom {
  height: 180px;
  width: 100%;
}
.integratorReferralsHorizontalLine {
  position: absolute;
  margin-top: -17px;
}
.integratorReferralsVerticalLine {
  position: absolute;
  margin-left: 50%;
}
.integratorReferralQuad {
  width: calc(50% - 80px);
  height: calc(100% - 35px);
  float: left;
  font-size: 14px;
  font-style: italic;
  line-height: 1.8em;
  padding-left: 40px;
  padding-right: 40px;
  padding-top: 35px;
  overflow: hidden;
}
.integratorReferralQuadRVC {
  padding-top: 25px;
  height: calc(100% - 25px);
}
.integratorReferralQuadApply {
  font-style: normal;
}
.integratorReferralButton {
  background: url(https://d35408cyocioye.cloudfront.net/new2/integrators/applybutton.png)
    no-repeat;
  width: 201px;
  height: 32px;
  font-weight: 700;
  color: #fff;
  padding-top: 8px;
  text-align: center;
  text-transform: uppercase;
  opacity: 1;
  margin: auto;
}
.integratorReferralButton:hover {
  opacity: 0.9;
  cursor: pointer;
}
.integratorReferralQuadSmallText {
  font-size: 15px;
  margin-top: 5px;
}
#integratorBlock2 {
  margin-top: 20px;
}
.integratorBlock2Title {
  font-size: 40px;
  font-weight: 300;
  color: #575755;
}
.integratorBlock9Title {
  font-size: 40px;
  font-weight: 300;
  color: #575755;
  margin-bottom: 30px;
}
#integratorBlock3 {
  position: relative;
  width: 100%;
  height: 560px;
  border-bottom: 15px solid #fff;
  padding-top: 46px;
  overflow: hidden;
  background: url(https://d35408cyocioye.cloudfront.net/new2/integrators/packaging_micro.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  margin-top: 40px;
}
#integratorBlock4 {
  width: 100%;
  height: 200px;
  margin-top: 20px;
  margin-bottom: 20px;
}
.integratorBlock4LeftRight {
  width: calc(50% - 140px);
  height: 100%;
  float: left;
  overflow: hidden;
  padding-left: 70px;
  padding-right: 70px;
}
.integratorBlock4LeftRight a,
#integratorBlock12 a {
  color: #008cff;
  text-decoration: none;
  font-size: 14px;
}
.integratorBlock4LeftRight a:hover,
#integratorBlock12 a:hover {
  color: #0069bf;
}
.integratorBoxText {
  font-size: 14px;
  line-height: 1.8em;
  margin-top: 20px;
}
.integratorBoxText1 {
  font-size: 15px;
  margin-top: 10px;
  font-weight: 800;
}
.integratorBoxText2 {
  font-size: 15px;
  margin-top: 5px;
  font-weight: 800;
  color: #aaaaa6;
}
#integratorBlock7 {
  width: 100%;
  padding-top: 50px;
}
#integratorBlock11 {
  height: 560px;
  width: 100%;
  margin-bottom: 15px;
}
#integratorBlock11Top {
  height: 280px;
  width: 100%;
  padding: 0px;
  margin: 0px;
}
.integratorBlock11TopIconTitle {
  text-transform: uppercase;
  font-size: 15px;
  font-weight: 800;
  line-height: 1;
  text-align: center;
  color: #575755;
}
.integratorBlock11TopIconText {
  font-size: 15px;
  line-height: 1.8;
  text-align: center;
  color: #575755;
  padding: 20px;
}
.integratorBlock11IconAsset {
  width: 100%;
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
}
.integratorBlock11IconAsset:hover {
  width: 95%;
}
.integratorBlock11IconAsset:active {
  width: 105%;
}
.integratorBlock11Vertical1 {
  position: absolute;
  height: 560px;
  left: 33.3%;
}
.integratorBlock11Vertical2 {
  position: absolute;
  height: 560px;
  left: 66.6%;
}
#integratorBlock11Bottom {
  height: 280px;
  width: 100%;
  padding: 0px;
  margin: 0px;
}

#integratorBlock13 {
  height: 240px;
  width: 100%;
}
.integratorBlock13cont {
  height: 100%;
  width: 50%;
  overflow: hidden;
  float: left;
}
#integratorBlock13left {
  width: 100%;
  height: 100%;
  background: url(https://d35408cyocioye.cloudfront.net/new2/integrators/plugandplay_micro.jpg)
    no-repeat;
  background-size: cover;
  background-position: 0;
}
#integratorBlock13right {
  width: 100%;
  height: 100%;
  background: url(https://d35408cyocioye.cloudfront.net/new2/integrators/noprogramming.jpg)
    no-repeat;
  background-size: cover;
  background-position: 0;
}
.integratorList li {
  width: 300px;
  margin: auto;
  text-align: left;
  margin-bottom: 20px;
}
.integratorDeviceList {
  text-align: left;
  margin: auto;
  margin-top: 30px;
  font-size: 15px;
  color: #575755;
  columns: 4;
  -webkit-columns: 4;
  -moz-columns: 4;
  list-style-type: none;
  line-height: 2.5em;
}
.integratorDeviceList li:before {
  content: "• ";
}
#integratorBlock12 {
  margin-top: 20px;
  margin-bottom: 20px;
}
#integratorBlock12Plans {
  width: 100%;
}
.integratorBlock12LeftRight {
  width: calc(50% - 10px);
  float: left;
  overflow: hidden;
  background-color: #f9f9f9;
  font-size: 15px;
  line-height: 1.3;
  text-align: center;
  color: #575755;
  margin-bottom: 40px;
}
.integratorBlock12Title {
  color: #88aac4;
  margin-top: 20px;
}

/* ****************************** */
/* JOBS PAGE */
/* ****************************** */

#jobsTopSection {
  background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url(https://d35408cyocioye.cloudfront.net/legacy/jobs-background-photo.jpg)
      no-repeat;
  background: url(https://d35408cyocioye.cloudfront.net/legacy/jobs-dark-background.jpg)
    no-repeat;
  background-size: cover;
  color: #fff;
  width: 100%;
  padding: 90px 0px 50px 0px;
  -webkit-transition: margin 1s;
  transition: margin 1s;
}
#jobsBody {
  width: 100%;
  -webkit-transition: margin 1s;
  transition: margin 1s;
}
#jobsTitle {
  margin: auto;
  opacity: 0;
  font-size: 35px;
  font-weight: 300;
  color: #fff;
  letter-spacing: 0.02em;
  margin-bottom: 20px;
  -webkit-transition: opacity 2s;
  transition: opacity 2s;
}
#jobsText {
  margin: auto;
  color: #f9f9f9;
  font-size: 15px;
  line-height: 1.33;
  max-width: 560px;
  padding: 20px;
}
.jobsButtonCont {
  height: 29px;
  width: 120px;
  margin: auto;
  margin-top: 40px;
}
.jobsButton {
  background: url(https://d35408cyocioye.cloudfront.net/legacy/new2/contact/email-button-four-stack.png)
    no-repeat;
  background-position: 0px 0px;
  height: 29px;
  width: 120px;
  font-size: 11px;
  color: #fff;
  font-weight: 800;
  margin: auto;
  padding-top: 11px;
  -webkit-transition: opacity 1s;
  transition: opacity 1s;
  opacity: 0;
}
.jobsButton a {
  font-size: 11px;
  text-transform: uppercase;
}
.jobsButton:hover {
  background-position: 0px -40px;
  cursor: pointer;
}

.jobsH1 {
  font-size: 40px;
  font-weight: 300;
  text-align: center;
  color: #575755;
}
.jobsH2 {
  font-size: 15px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.33;
  letter-spacing: normal;
  text-align: center;
  color: #9a9a95;
  margin-top: 10px;
}

#jobsOpening {
  padding: 40px;
  max-width: 560px;
  margin: auto;
}
#jobsOpening a {
  color: #88aac4;
  font-size: 18;
  text-decoration: none;
}
#jobsOpening a:hover {
  color: #008cff;
}

#jobsLocation {
  height: 600px;
  width: 100%;
  text-align: center;
  color: #9a9a95;
  font-size: 15px;
  background-color: #f7f7f7;
}
.jobsLocTop {
  height: 300px;
  width: 100%;
}
.jobsLocationTitle {
  font-weight: bold;
  color: #575755;
  margin-bottom: 20px;
  text-transform: uppercase;
}
.jobsLocationBody {
  text-align: left;
}
.jobsLocTopLeft {
  float: left;
  width: calc(50% - 120px);
  height: calc(100% - 120px);
  padding: 60px;
}
.jobsLocTopRight {
  width: 50%;
  float: right;
  height: 100%;
  background: url(https://d35408cyocioye.cloudfront.net/legacy/colorado.jpg)
    no-repeat;
  background-size: cover;
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
}
.jobsLocBottom {
  height: 300px;
  width: 100%;
}
.jobsLocBottomLeft {
  width: 50%;
  float: left;
  height: 100%;
  background: url(https://d35408cyocioye.cloudfront.net/legacy/california.jpg)
    no-repeat;
  background-size: cover;
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
}
.jobsLocBottomRight {
  float: right;
  height: calc(100% - 140px);
  width: calc(50% - 140px);
  padding: 70px;
}
.jobsLocTopRight:hover,
.jobsLocBottomLeft:hover {
  filter: brightness(80%);
}

#jobsPhotosInfo {
  height: 600px;
  width: 100%;
}
#jobsPhotosInfoLeft {
  width: 50%;
  height: 100%;
  float: left;
}
.jobsPhotosLeftRow1,
.jobsPhotosLeftRow2,
.jobsPhotosLeftRow3 {
  height: 200px;
  width: 100%;
}
.jobsPhotosLeftRow1Left {
  height: 100%;
  width: calc(50% - 10px);
  margin-right: 10px;
  float: left;
  background: url(https://d35408cyocioye.cloudfront.net/legacy/job-photos-1.jpg)
    no-repeat;
  background-position: 0px 0px;
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
}
.jobsPhotosLeftRow1Right {
  height: 100%;
  width: 50%;
  float: right;
  background: url(https://d35408cyocioye.cloudfront.net/legacy/job-photos-2.jpg)
    no-repeat;
  background-position: 0px 0px;
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
}
.jobsPhotosLeftRow2Left {
  height: 100%;
  width: calc(50% - 10px);
  margin-right: 10px;
  margin-top: 10px;
  margin-bottom: 10px;
  float: left;
  background: url(https://d35408cyocioye.cloudfront.net/legacy/job-photos-3.jpg)
    no-repeat;
  background-position: 0px 0px;
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
  border-bottom-left-radius: 5px;
}
.jobsPhotosLeftRow2Right {
  height: 100%;
  width: 50%;
  float: right;
  margin-top: 10px;
  margin-bottom: 10px;
  background: url(https://d35408cyocioye.cloudfront.net/legacy/job-photos-4.jpg)
    no-repeat;
  background-position: 0px 0px;
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
}
.jobsPhotosLeftRow3Left {
  height: 100%;
  width: calc(50% - 10px);
  margin-right: 10px;
  float: left;
  background: url(https://d35408cyocioye.cloudfront.net/legacy/job-photos-5.jpg)
    no-repeat;
  background-position: 0px 0px;
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
}
.jobsPhotosLeftRow3Right {
  height: 100%;
  width: 50%;
  float: right;
  background: url(https://d35408cyocioye.cloudfront.net/legacy/job-photos-6.jpg)
    no-repeat;
  background-position: 0px 0px;
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
}
.jobsPhotosLeftRow1Left:hover,
.jobsPhotosLeftRow1Right:hover,
.jobsPhotosLeftRow2Right:hover,
.jobsPhotosLeftRow2Left:hover,
.jobsPhotosLeftRow3Right:hover,
.jobsPhotosLeftRow3Left:hover {
  background-position: 0px -200px;
}

#jobsPhotosInfoRight {
  width: calc(50% - 80px);
  height: calc(100% - 80px);
  float: right;
  text-align: left;
  padding: 40px;
  font-size: 15px;
  color: #575755;
}
#jobsBenefits {
  margin: 0px;
  margin-left: 40px;
  padding: 0px;
}
#jobsBenefits li {
  line-height: 2.67em;
}

#jobListings {
  margin: auto;
  width: 100%;
  margin-bottom: 40px;
  padding-bottom: 0px;
}
#jobListingsTopGap {
  height: 150px;
}
.jobsListingTime {
  font-style: italic;
}
#jobsListingList {
  margin: 0px;
  padding: 0px;
  margin-top: 40px;
}
#jobsListingList > li {
  list-style-type: none;
  border: 1px solid #ccc;
  border-radius: 5px;
  margin-bottom: 20px;
  padding: 20px;
  text-align: left;
  font-size: 20px;
  line-height: 1.5;
  color: #575755;
}
#jobsListingList > li:hover {
  cursor: pointer;
  border: 1px solid #008cff;
}
.jobsListingArrow {
  position: absolute;
  height: 25px;
  width: 25px;
  background: url(https://d35408cyocioye.cloudfront.net/legacy/arrow-down-blue1.png)
    no-repeat;
  right: 27px;
  margin-top: 36px;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  pointer-events: none;
}
#jobsListingList li:hover > .jobsListingArrow {
  margin-top: 40px;
}
.jobsListingArrow--flip {
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
}
.jobsListingBody {
  display: none;
  margin-top: 20px;
  font-size: 15px;
}
.jobsListingBody a {
  color: #88aac4;
  font-size: 15px;
}
.jobsListingBody ul li p {
  margin: 0px;
  text-align: left;
}

.jobsListingBody p {
  margin: 0px;
  text-align: left;
  max-width: 100%;
}
.jobsListingBody a:hover {
  color: #008cff;
}
.jobsListingBodyList {
  margin-top: 10px;
  margin-bottom: 10px;
}
.jobsListingBodyList li {
  list-style-type: circle;
}
.jobsListingTitle {
  font-weight: bold;
}
.jobsListingTitle,
.jobsListingText,
.jobsListingTime {
  pointer-events: none;
}

#jobsFooter {
  color: #fff;
  background-color: #3ca4fc;
  width: 100%;
  height: 140px;
  margin-top: 0px;
  padding: 50px 0px 0px 0px;
  font-size: 14px;
  border-bottom: 15px solid #fff;
  max-width: 1200px;
  margin: auto;
  margin-bottom: 180px;
}

/* ****************************** */
/* PORTAL PAGE */
/* ****************************** */

#testTop {
  height: 80px;
  position: fixed;
  background-color: #f9f9f9;
  width: 100%;
  top: 0px;
  border-bottom: 1px solid #000;
}
#testMiddle {
  position: static;
  height: calc(100% - 40px);
  width: 100%;
  background-color: #fff;
  margin-top: 80px;
  color: black;
  font-size: 40px;
}
#testBottom {
  height: 80px;
  position: fixed;
  bottom: 0px;
  width: 100%;
  background-color: #f9f9f9;
  border-top: 1px solid #000;
}
#testMiddleLeft {
  width: calc(30% - 41px);
  height: 100%;
  float: left;
  padding: 20px;
  border-right: 1px solid #000;
}
#testMiddleRight {
  width: calc(70% - 40px) %;
  height: 100%;
  margin-left: 30%;
  padding: 20px;
}

#portal-content {
  height: 100%;
  width: 100%;
  background-color: yellow;
}

#portal-content-left {
  width: 20%;
  float: left;
  margin-top: 100px;
  height: 100%;
}
#portal-content-right {
  width: 80%;
  float: right;
  margin-top: 100px;
  height: 100%;
}

/* OTHER */

#preload {
  display: none;
}
#shitBlock1 {
  position: relative;
  width: 100%;
  height: 560px;
  padding-top: 46px;
  background: linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.5)),
    url(https://d35408cyocioye.cloudfront.net/new2/shit.jpg);
  background-size: cover;
  background-repeat: no-repeat;
}
#shitBlock1:hover {
  cursor: pointer;
}
#shitBlock3 {
  position: relative;
  width: 100%;
  height: 560px;
  border-bottom: 15px solid #fff;
  padding-top: 46px;
  overflow: hidden;
  background: url(https://d35408cyocioye.cloudfront.net/new2/shitproduct.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  margin-top: 40px;
}
#shitBlock4 {
  width: 100%;
  height: 250px;
  margin-top: 20px;
  margin-bottom: 20px;
}

/* ****************************** */
/* MEDIA QUERIES */
/* ****************************** */
@media screen and (max-width: 1150px) {
  #navmenureveal,
  #navmenujoshdoghead {
    position: absolute;
    left: 15px;
  }
  .dividerVideo1,
  .dividerVideo2,
  .dividerVideo3 {
    width: calc(33% - 6px);
  }
  #jobsListingList {
    margin-left: 20px;
    margin-right: 20px;
  }
  #navlist1 li {
    padding-right: 30px;
  }
  .nav1home {
    margin-right: 15px;
  }
}
@media screen and (max-width: 960px) {
  #reliableDiamond {
    height: 250px;
    width: 250px;
    margin-top: 225px;
    margin-left: -125px;
  }
  .reliableTextLarge {
    margin-bottom: 0px;
  }
  .reliableLine,
  .newblock6Button {
    display: none;
  }
  .newblock6Cont {
    width: 400px;
    margin-right: 0px;
  }
  #registerTerms {
    width: 50%;
  }
}
@media screen and (max-width: 820px) {
  #navlist1 li {
    padding-right: 25px;
  }
  .pressGridObject {
    width: calc(33% - 38px);
  }
  .pressGrid3 {
    margin-right: 0px;
    display: none;
  }
  #aboutFooter {
    margin-bottom: 0px;
  }
  .pressGrid4 {
    margin-right: 10px;
  }
  #buyBlock11TopRight,
  #buyBlock11BottomRight,
  .buyBlock11Vertical2 {
    display: none;
  }
  #buyBlock11TopLeft,
  #buyBlock11TopMiddle,
  #buyBlock11BottomLeft,
  #buyBlock11BottomMiddle {
    width: 50%;
  }
  .buyBlock11Vertical1 {
    left: 50%;
  }
  .frameLeft,
  .frameRight,
  .frameBottom,
  #buyBlock6 ul {
    display: none;
  }
  #buySaleBlockContPro,
  #buySaleBlockPro {
    display: none;
  }
  #buyBlock8Title {
    text-align: center;
    padding-left: 0px;
  }
  #buyBlock8 ul {
    position: absolute;
    left: 50%;
    margin-left: -125px;
  }
  #newblock1,
  #newBlock1Vid,
  .newtext1Container {
    height: 460px;
    object-fit: fill;
    background-color: transparent;
  }
  .newsection1text {
    padding-top: 110px;
    font-size: 38px;
  }
  .newsection1line5,
  .newsection1line6 {
    top: 250px;
  }
  #newblock5,
  #newblock6,
  #newblock8 {
    height: 400px;
  }
  .newblock5TextLarge {
    padding-top: 150px;
  }
  #newblock2 {
    height: 500px;
  }
  .newblock2Button,
  .featuresCornerTextTitle,
  .newblock6Button,
  .newblock8logo {
    display: none;
  }
  .newblock6Cont {
    width: 400px;
    margin-right: 0px;
  }
  .newblock8cont {
    margin-top: 70px;
  }
  .section1ripple {
    margin-top: 50px;
  }
}
@media screen and (max-width: 740px) {
  #navlist1 li {
    padding-right: 20px;
  }
  #jobsPhotosInfo,
  #jobsGreatBenefits,
  #jobsLocation,
  #jobsOpenPositions,
  #jobsOpening {
    display: none;
  }
  #jobListingsTopGap {
    height: 70px;
  }
  #jobsListingList {
    margin-left: 10px;
    margin-right: 10px;
  }
  .jobsListingArrow1,
  .jobsListingArrow2,
  .jobsListingArrow3,
  .jobsListingArrow4,
  .jobsListingArrow5,
  .jobsListingArrow6,
  .jobsListingArrow7,
  .jobsListingArrow8,
  .jobsListingArrow9,
  .jobsListingArrow10 {
    right: 20px;
  }
}
@media screen and (max-width: 720px) {
  #section1Cont,
  .disappear,
  .sectionNaderText,
  .tile2,
  .tile3,
  .tile4,
  .tile5,
  .tile6,
  .section1textSmall,
  .section1textSmall2.footer,
  .impressiveTextWorksWith,
  .impressiveWorksImage,
  .section4quote,
  .meetTextSmall,
  .liberatingTextArea2,
  #impressiveWorksWith,
  .section5quote,
  #buyBlock8,
  #buyBlock9,
  #buyBlock12,
  #buyBlock13,
  #buyBlock6,
  #buyBlock14,
  #buyBlock7,
  #block10 {
    display: none;
  }
  #block3,
  .divider0,
  .divider2,
  #block4,
  .divider3,
  #block5,
  #block6,
  #block7,
  .bottomLogo,
  .buyBlock2Arrows,
  #buyBlock3,
  #buyBlock4,
  #buyBlock1Vid,
  #buyBlock5 {
    display: none;
  }
  #block8 {
    margin-top: 0px;
    height: 275px;
  }
  .bottomText {
    margin-top: 50px;
    padding: 20px;
  }
  .bottomButton {
    margin-top: 160px;
  }
  .bottomNowShippingText {
    text-transform: uppercase;
    font-size: 12px;
    margin-top: 20px;
    position: absolute;
    left: 0;
    right: 0;
    margin: auto auto;
    margin-top: 350px;
  }
  .tile1 {
    opacity: 1;
    top: 60px;
  }
  .section1text {
    margin-top: -100px;
    font-size: 30px;
    padding-left: 20px;
    padding-right: 20px;
    opacity: 1;
    padding-bottom: 10px;
  }
  .text1Container {
    height: auto;
  }
  #block1 {
    background: linear-gradient(
        rgba(255, 255, 255, 0.8),
        rgba(255, 255, 255, 0.8)
      ),
      url(https://d35408cyocioye.cloudfront.net/legacy/new2/cover-slide-bg-photo.jpg)
        no-repeat;
    background-size: cover;
  }
  .sectionNader {
    height: 250px;
    margin-top: 60px;
  }
  .section1GetJoshButton {
    opacity: 1;
    margin-top: 18px;
  }
  #impressiveLeftcont {
    height: 233px;
  }
  #impressiveRightcont {
    height: 233px;
    margin-top: -233px;
  }
  #impressiveRight3 {
    margin-top: 0px;
  }
  #impressiveTriangle {
    position: static;
    margin-top: 0px;
    background: none;
    height: 250px;
  }
  .impressiveIcon {
    margin-top: 100px;
  }
  #impressiveText {
    margin-top: -212px;
  }
  #block8 {
    margin-bottom: 0px;
  }
  #buyBlock1 {
    height: 440px;
    margin-top: -70px;
    background: url(https://d35408cyocioye.cloudfront.net/legacy/new2/getjosh/puppy.jpg)
      no-repeat;
  }
  #buyBlock1demo {
    height: 440px;
    margin-top: -70px;
    background: url(https://d35408cyocioye.cloudfront.net/legacy/new2/demounit/sunset.jpg)
      no-repeat;
  }
  #buyBlock1Left {
    display: none;
    width: 100%;
    margin-top: 440px;
  }
  .buySaleBlockCont,
  .buySaleBlock {
    margin-left: calc(50% - 115px);
    opacity: 1;
  }
  .buyBlock2Photos {
    height: 100px;
    width: 33%;
  }
  #buyBlockPhoto1 {
    margin-left: 0px;
  }
  #buyBlockPhoto2 {
    margin-left: 34%;
  }
  #buyBlockPhoto3 {
    margin-left: 68%;
  }
  .buyBlock2PhotoText {
    margin-top: 108px;
  }
  #buyBlock2Cont {
    height: 85px;
  }
  #buyCont {
    margin-bottom: 0px;
  }
  #buyBlockHidden {
    display: block;
    margin-bottom: 20px;
  }
  #buyBlockHiddenTitle {
    font-size: 40px;
    font-weight: 100;
    color: #222;
    padding-top: 20px;
  }
  .buySaleBlockLogo {
    opacity: 1;
  }
  .recsContent {
    margin-bottom: 0px;
  }
  .demoUnithide,
  .newblock6TextLarge {
    display: none;
  }
  .newdivider0 {
    padding: 30px;
  }
  #newblock2,
  #newblock3,
  #newblock4,
  #newblock5,
  #newblock6,
  #newblock8 {
    border-bottom: 5px solid #fff;
  }
  .aboutLeftPhoto,
  .aboutRightPhoto {
    display: none;
  }
  .aboutRightText,
  .aboutLeftText {
    max-width: 100%;
    margin: 30px;
    padding: 0px;
  }
  .teamSection {
    height: auto;
    padding-bottom: 15px;
    padding-top: 1px;
    border-top: 1px solid #999;
    margin-bottom: 0px;
  }
  #teamBody {
    margin-top: -5px;
  }
}
@media screen and (max-width: 600px) {
  .pressGridObject {
    width: calc(50% - 40px);
  }
  .pressGrid2 {
    margin-right: 10px;
  }
  .pressGrid3 {
    margin-right: 10px;
    display: block;
  }
  #aboutFooter {
    margin-bottom: 0px;
  }
  #pressText {
    display: none;
  }
  .recsGrid li {
    width: calc(50% - 12px);
  }
  .recsGridLiRightSmall {
    margin-right: 0px !important;
  }
  .newsection1text {
    padding-top: 110px;
    padding-left: 50px;
    padding-right: 50px;
    font-size: 32px;
  }
  .newsection1line5,
  .newsection1line6 {
    border-top: 1px solid #fff;
    margin-top: 35px;
  }
  #newblock2,
  #newblock3,
  #newblock4,
  #newblock5,
  #newblock6,
  #newblock8 {
    border-bottom: 3px solid #fff;
  }
  .newblock2TextSmall,
  .newblock6TextLarge,
  #featuresCorner1,
  #featuresCorner3 {
    display: none;
  }
  #newblock2 {
    height: 385px;
  }
  #newblock6 {
    height: 300px;
  }
  .newblock6TextSmall {
    width: 90%;
  }
  .newblock6Cont {
    width: 300px;
  }
  .newblock8cont {
    width: 400px;
    float: none;
    margin: auto;
    padding-top: 80px;
  }
  #featuresCorner2,
  #featuresCorner4 {
    width: 100%;
  }
  #registerTerms {
    width: 75%;
  }
}
@media screen and (max-width: 540px) {
  .newblock6TextSmall {
    display: none;
  }
}
@media screen and (max-width: 460px) {
  #reliableDiamond {
    height: 200px;
    width: 200px;
    margin-top: 250px;
    margin-left: -100px;
  }
  .reliableTextLarge {
    margin-top: 25px;
  }
  .hide460,
  .footer,
  .frameBorder,
  .frameTop,
  .divider0Cedia,
  .newblock5TextSmall,
  .newblock3Top,
  .newblock3BottomLeft,
  .divider0Text,
  .reliableTextSmall,
  #guideText,
  #buyBlock11TopLeft,
  #buyBlock11BottomLeft,
  .buyBlock11Vertical1 {
    display: none;
  }
  #guideTopSection {
    background: none;
    padding: 50px 20px 0px 20px;
    width: calc(100% - 40px);
  }
  .guideSection {
    padding-top: 0px;
    margin-bottom: 0px;
    padding-bottom: 0px;
  }
  .guideListTitle {
    text-align: left;
    margin-left: 75px;
  }
  #buyBlock11TopMiddle,
  #buyBlock11BottomMiddle {
    width: 100%;
  }
  #newblock5,
  #newblock3,
  #newblock8,
  #newblock2 {
    height: 300px;
  }
  .newsection1text {
    margin-top: -30px;
    padding-top: 95px;
  }
  .newdivider0 {
    padding: 2px;
  }
  .newblock5TextLarge {
    padding-top: 100px;
  }
  .newblock3BottomRight,
  .newblock3Bottom {
    height: 100%;
    width: 100%;
  }
  .newblock8cont {
    padding-top: 25px;
  }
  #newblock8 {
    margin-bottom: 0px;
  }
  #newblockmobile {
    display: block;
    height: 300px;
    width: 100%;
    z-index: 100;
    border-bottom: 2px solid #fff;
    background: linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)),
      url(https://d35408cyocioye.cloudfront.net/new2/modern4.jpg) no-repeat;
    background-size: cover;
    background-position: bottom;
  }
  .section1PlayButtonMobile {
    position: absolute;
    left: 50%;
    margin-left: -34px;
  }
  .newsection1text {
    padding-left: 0px;
    padding-right: 0px;
  }
}

/* FORM STUFF */
.blueLink {
  color: #88aac4;
  font-size: 12px;
}
.blueLink--big {
  font-size: 18px;
}
.blueLink:hover,
.blueLink:active {
  color: #008cff;
}
.formErrorList {
  padding: 0;
}
.noAccess__heading {
  letter-spacing: 1px;
  margin: 45px 0px 10px;
}
.noAccess__copy {
  padding: 0px 5px;
  letter-spacing: 1px;
  margin: 45px 0px 10px;
}

/**********************
	FULLSCREEN NAV
**********************/
.fullNav {
  background-color: #272a2d;
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
  z-index: 0;
  position: fixed;
  height: 100vh;
  top: 0;
  overflow: scroll;
  -webkit-overflow-scrolling: touch;
  left: 0;
  width: 100vw;
  opacity: 0;
  display: none;
  -webkit-backface-visibility: hidden;
}
.fullNavList {
  margin-top: 6vh;
  padding-left: 0;
  height: 110vh;
}
#fullNavListLeft {
  width: 70%;
  padding-left: 30vw;
}
#fullNavListMid {
  position: absolute;
  margin-top: calc(6vh + 78px);
  left: 45vw;
  transform: translateX(-40%);
}
#fullNavListRight {
  position: absolute;
  left: 30vw;
  margin-top: calc(6vh + 760px);
  height: auto;
}
.fullNavText {
  margin-bottom: 15px;
  list-style: none;
  text-align: left;
  transform: translate3d(-30px, 0px, 0);
  opacity: 0;
}
.fullNavText.view,
.horizontalAboutLine.view {
  transition: all 0.15s ease-out;
  transform: translate3d(0px, 0, 0);
  opacity: 1;
}
.horizontalAboutLine.view {
  opacity: 0.25;
  transition: all 0.2s ease-out;
}
.horizontalAboutLine.view::before {
  width: 7vw;
  transition: all 0.3s ease-out;
}
.fullNavText.leave,
.horizontalAboutLine.leave {
  transition: all 0.05s ease-in;
  transform: translate3d(-50px, 0, 0);
  opacity: 0;
}
.fullNavText.active {
  border-left: 2px #008cff solid;
  padding-left: 20px;
  margin-left: -20px;
}
.fullNavText.active a {
  opacity: 0.9;
}
.fullNavText a {
  opacity: 0.7;
  position: relative;
  font-family: Lato;
  font-size: 30px;
  font-weight: 300;
  color: #ffffff;
}
.aboutLine {
  width: 100%;
  height: 1px;
  display: block;
  position: relative;
  top: -1rem;
  left: 6rem;
  background: white;
}
.horizontalAboutLine {
  width: 0px;
  height: 11rem;
  position: absolute;
  top: 0.25rem;
  margin-left: -20px;
  opacity: 0.25;
  border: solid 1px #ffffff;
}
.horizontalAboutLine::before {
  content: "";
  width: 0;
  height: 0;
  position: absolute;
  top: 4rem;
  margin-left: -7.2vw;
  border: none;
}

/**********************
	HEADER
**********************/
#fixedHeader.pinned {
  position: absolute !important;
}

#fixedHeader {
  height: 40px;
  left: 0;
  display: flex;
  justify-content: space-between;
  position: fixed;
  z-index: 12;
  overflow: hidden;
  opacity: 0;
  -webkit-transition: all 1s;
  transition: all 1s;
  -webkit-backface-visibility: hidden;
}
.fixedHeaderPosition {
  width: calc(100% - 100px);
  padding-left: 20px;
  padding-right: 50px;
  margin-top: 10px;
}
.fixedHeaderSplash {
  width: calc(100% - 100px);
  padding-left: 20px;
  padding-right: 50px;
  margin-top: 10px;
}
.hamburger {
  width: 30px;
  opacity: 0.8;
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
  cursor: pointer;
  z-index: 3;
}
.hamburger:hover {
  opacity: 1 !important;
}
.hamburger .burgerLine {
  height: 1px;
  opacity: 1;
  width: 25px;
  background-color: #fff;
  position: absolute;
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
}
.hamburger.dark .burgerLine {
  background-color: #303c45;
}
.hamburger.light .burgerLine {
  background-color: #fff;
}
.burgerLine1 {
  margin-top: 11px;
}
.burgerLine2 {
  margin-top: 19px;
}
.burgerLine3 {
  margin-top: 27px;
}
#joshdoghead {
  height: auto;
  width: 115px;
  background: url(../img/josh-horiz-logo-white.svg) no-repeat;
  background-size: cover;
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
  z-index: 1;
  opacity: 0;
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
}
.headerLogin {
  color: #fff;
  line-height: 40px;
  opacity: 0;
  z-index: 1;
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
}
.headerLogin:hover {
  opacity: 1 !important;
}

/* media queries */
/*TABLET*/
@media (min-width: 768px) {
  /*HEADER*/
  .fixedHeaderSplash {
    width: calc(100% - 140px);
    margin-top: 30px;
    padding-left: 40px;
    padding-right: 70px;
  }
}

/*DESKTOP*/
@media (min-width: 1024px) {
  .not-tablet {
    display: block !important;
  }

  /*NAV*/
  .fixedHeaderPosition {
    width: calc(100% - 100px);
    padding-left: 50px;
    padding-right: 50px;
    margin-top: 50px;
    position: fixed !important;
  }
  .fixedHeaderSplash {
    width: calc(100% - 140px) !important;
    margin-top: 60px !important;
    padding-left: 70px !important;
    padding-right: 70px !important;
  }
  #fullNavListLeft {
    margin-left: 12vw !important;
    padding-left: 0 !important;
  }
  #fullNavListRight {
    left: 75.5vw !important;
  }
  .fullNavList {
    margin-top: 22.5vh !important;
  }
  .fullNavText a {
    font-size: 30px !important;
  }
  .fullNavText {
    margin-bottom: 1rem !important;
  }
  .horizontalAboutLine::before {
    border: solid 1px #ffffff !important;
  }
  #fullNavListLeft li:nth-child(3) {
    margin-top: 0 !important;
  }
  .horizontalAboutLine {
    height: 14.5rem !important;
    top: 0.5rem !important;
  }
  #fullNavListMid {
    margin-top: 22.5vh !important;
    left: 38vw !important;
  }
  .fullNavText a:hover {
    opacity: 1 !important;
  }
  .fullNavText a:active {
    opacity: 0.9 !important;
  }
  .hoverLink::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 1px;
    bottom: -1px;
    left: 0;
    background-color: #fff;
    visibility: hidden;
    -webkit-transform: scaleX(0);
    transform: scaleX(0);
    -webkit-transition: all 0.2s ease-in-out 0s;
    transition: all 0.2s ease-in-out 0s;
  }
  .hoverLink:hover::before {
    visibility: visible;
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
  }
}
